import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';

import {
  DAILY_ORDER_PRODUCT_VIEW,
  DAILY_ORDER_TIME_VIEW,
  DAILY_ORDER_AGENT_VIEW
} from '../../assets/Constants';

export default function DOViewTypeSelector(props) {
  const [viewType, setViewType] = React.useState(DAILY_ORDER_TIME_VIEW);

  const handleChange = (event, newViewType) => {
    setViewType(newViewType);
    props.onChange(newViewType);
  };

  return (
    <ToggleButtonGroup
      sx={{width:'100%'}}
      color="primary"
      value={viewType}
      orientation="vertical"
      size="small"
      exclusive
      onChange={handleChange}
      aria-label="ViewType"
    >
      <ToggleButton value={DAILY_ORDER_TIME_VIEW}sx={{textAlign:'left', justifyContent:'flex-start', paddingLeft:'16px'}}>
        <DateRangeTwoToneIcon sx={{marginRight:"12px"}}/>
        시간 순으로 확인
      </ToggleButton>
      <ToggleButton value={DAILY_ORDER_AGENT_VIEW} sx={{textAlign:'left', justifyContent:'flex-start', paddingLeft:'16px'}}>
        <AssistantPhotoTwoToneIcon size="small" sx={{marginRight:"12px"}}/>
        브랜드 별로 확인
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
