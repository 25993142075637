import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

// heartit controller
import UserController from '../../apis/users/UserController';

import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function UserNameDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading]  = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [error, setError] = useState({
    firstname : false,
    lastname : false
  })

  const handleFirstNameInput = (event) => {
    setError({ ...error, firstname : false });
    setFirstName(event.target.value);
  }

  const handleLastNameInput = (event) => {
    setError({ ...error, lastname : false });
    setLastName(event.target.value);
  }

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleSubmit = (event) => {
    setIsLoading(true);
    let isValid = true;
    let _error = { firstname : false, lastname : false };

    if( firstname.trim().length  === 0){
      _error.firstname = true;
      isValid = false;
    }

    if( lastname.trim().length  === 0){
      _error.lastname = true;
      isValid = false;
    }

    if(!isValid){
      setError(_error);
      setIsLoading(false);
      return;
    }
    editName();
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const createSuccess = (success) => {
    if(success.data.code === 200){
      handleSnackBar(`이름이 성공적으로 수정되었습니다`,'success');
      setFirstName("");
      setLastName("");
      setIsLoading(false);
      props.onClose();
    }else{
      setIsLoading(false);
      handleSnackBar(`이름이 수정되었습니다`,'error');
    }
  }

  const createFailed = (error) => {
    setIsLoading(false);
    handleSnackBar(`이름이 수정되었습니다`,'error');
  }


  const editName = () => {
    UserController.editUserName(
      {firstname : firstname.trim(), lastname : lastname.trim()},
      createSuccess, createFailed);
  }

  useEffect(() => {
    setFirstName(props.initialData.first_name);
    setLastName(props.initialData.last_name);
  }, [props.initialData]);

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         이름 수정
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
            <Box sx={{width:'100%', height:'48px', mb:'8px'}}>
             <TextField
               id="user-add-dialog-email-input"
               disabled={isLoading}
               error={error.lastname}
               sx={{minWidth:'80%'}}
               value={lastname}
               onKeyDown={handleKeyPress}
               onChange={handleLastNameInput}
               label="성"
               placeholder="ex. 하"
               helperText={error.lastname ? "성은 최소 한 글자 이상이어야 합니다" : "" }
               size="small"
               type="text"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
            <Box sx={{width:'100%', height:'48px'}}>
             <TextField
               id="user-add-dialog-email-input"
               disabled={isLoading}
               error={error.firstname}
               sx={{minWidth:'80%'}}
               value={firstname}
               onKeyDown={handleKeyPress}
               onChange={handleFirstNameInput}
               label="이름"
               placeholder="ex. 트잇"
               helperText={error.firstname ? "이름은 최소 한 글자 이상이어야 합니다" : "" }
               size="small"
               type="text"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>

         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>수정하기</Button>
       </DialogActions>
     </Dialog>
  )
};
