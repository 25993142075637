import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';

import ShoppingCartCheckoutTwoToneIcon from '@mui/icons-material/ShoppingCartCheckoutTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';

import ApprovalTwoToneIcon from '@mui/icons-material/ApprovalTwoTone';

import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';

import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import WhatshotTwoToneIcon from '@mui/icons-material/WhatshotTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import BalanceTwoToneIcon from '@mui/icons-material/BalanceTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import PolylineTwoToneIcon from '@mui/icons-material/PolylineTwoTone';
import WarehouseTwoToneIcon from '@mui/icons-material/WarehouseTwoTone';

import CrisisAlertTwoToneIcon from '@mui/icons-material/CrisisAlertTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import BuildTwoToneIcon from '@mui/icons-material/BuildTwoTone';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';
import FitbitTwoToneIcon from '@mui/icons-material/FitbitTwoTone';

import StackedBarChartTwoToneIcon from '@mui/icons-material/StackedBarChartTwoTone';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';
//import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';

import MiscellaneousServicesTwoToneIcon from '@mui/icons-material/MiscellaneousServicesTwoTone';

const MainMenuItems = [
  {
    key : 'dashboard',
    title : '대시보드',
    type : 'link',
    to : '/dashboard',
    icon : <DashboardTwoToneIcon />
  },
  {
    key : 'daily_order',
    title : '데일리 오더',
    type : 'accordion',
    icon : <LocalFireDepartmentTwoToneIcon />,
    children : [
      {
        key : 'new_buy_process',
        title : '상품 가격 정보',
        type : 'link',
        to : '/do/product/recommend',
        icon : <CrisisAlertTwoToneIcon />
      },
      {
        key : 'manage_daily_order_product',
        title : '주력 상품 관리',
        type : 'link',
        to : '/do/product/list',
        icon : <WhatshotTwoToneIcon />
      },
      {
        key : 'match_daily_order_product',
        title : '상품 매칭 목록',
        type : 'link',
        to : '/do/product/match',
        icon : <BalanceTwoToneIcon />
      },
      {
        key : 'manage_daily_order_request',
        title : '에이전트 관리',
        type : 'link',
        to : '/do/agent/list',
        icon : <FindInPageTwoToneIcon />
      },
      {
        key : 'daily_order_statistics',
        title : '데일리 오더 통계',
        type : 'link',
        to : '/do/statistics',
        icon : <AssessmentTwoToneIcon />
      },
      /*{
        key : 'daily_order_kream_statistics',
        title : '크림 오더 통계',
        type : 'link',
        to : '/do/kream_statistics',
        icon : <AssessmentTwoToneIcon />
      },*/
      {
        key : 'manage_daily_order_accounts',
        title : '푸시 계정 관리',
        type : 'link',
        to : '/do/accounts',
        icon : <PeopleTwoToneIcon />
      },
      {
        key : 'daily_order_mail_list',
        title : '전체 메일 관리',
        type : 'link',
        to : '/do/product/mails',
        icon : <MarkEmailReadTwoToneIcon />
      },
    ]
  },
  {
    key : 'manage_product',
    title : '상품관리',
    type : 'accordion',
    icon : <ShoppingCartTwoToneIcon />,
    children : [
      {
        key : 'my_product',
        title : '나의 상품',
        type : 'link',
        to : '/product/my',
        icon : <LocalMallTwoToneIcon />
      },
      {
        key : 'list_product',
        title : '상품 목록',
        type : 'link',
        to : '/product/list',
        icon : <ShoppingCartCheckoutTwoToneIcon />
      },
      {
        key : 'storage_in_storage',
        title : '입고 관리',
        type : 'link',
        to : '/storage/instock',
        icon : <IntegrationInstructionsTwoToneIcon />
      },
      {
        key : 'storage_main',
        title : '재고 관리',
        type : 'link',
        to : '/storage',
        icon : <WarehouseTwoToneIcon />
      },
      {
        key : 'storage_cancel_return',
        title : '반품/특이 상품',
        type : 'link',
        to : '/storage',
        icon : <IosShareTwoToneIcon />
      }
    ]
  },
  {
    key : 'adjust_price',
    title : '정산관리',
    type : 'accordion',
    icon : <AutoAwesomeTwoToneIcon />,
    children : [
      {
        key : 'adjust_instock',
        title : '입고 상품정산',
        type : 'link',
        to : '/adjustment/product',
        icon : <LocalMallTwoToneIcon />
      },
      {
        key : 'adjust_sell',
        title : '판매 정산',
        type : 'link',
        to : '/adjustment/list',
        icon : <MiscellaneousServicesTwoToneIcon />
      }
    ]
  },
  {
    key : 'alice_tools',
    title : '도구 목록',
    type : 'accordion',
    icon : <BuildTwoToneIcon />,
    children : [
      {
        key : 'alice_tool_image_generator',
        title : '한스타일용 이미지',
        type : 'link',
        to : '/tools/image_generator',
        icon : <AutoAwesomeTwoToneIcon />
      },
      {
        key : 'alice_logger',
        title : '유튜브 로그 확인',
        type : 'link',
        to : '/tools/logs',
        icon : <FitbitTwoToneIcon />
      }
    ]
  },
  {
    key : 'manage_crawler',
    title : '크롤러 관리',
    type : 'accordion',
    icon : <ApprovalTwoToneIcon />,
    children : [
      {
        key : 'list_host',
        title : '호스트 관리',
        type : 'link',
        to : '/host/list',
        icon : <CorporateFareTwoToneIcon />
      },
      {
        key : 'list_crawler',
        title : '크롤러 목록',
        type : 'link',
        to : '/crawler/list',
        icon : <ShoppingCartCheckoutTwoToneIcon />
      },
      {
        key : 'my_crawler',
        title : '나의 크롤러',
        type : 'link',
        to : '/crawler/my',
        icon : <LocalMallTwoToneIcon />
      },
    ]
  },
  {
    key : 'setting_page_list',
    title : '설정',
    type : 'accordion',
    icon : <SettingsTwoToneIcon />,
    children : [
      {
        key : 'setting_brands',
        title : '브랜드 관리',
        type : 'link',
        to : '/setting/brands',
        icon : <PolylineTwoToneIcon />
      },
      {
        key : 'setting_variables',
        title : '환경 변수 설정',
        tyle : 'link',
        to : '/setting/variables',
        icon : <MiscellaneousServicesTwoToneIcon />
      }
    ]
  }
];

export default MainMenuItems;
