import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

import moment from 'moment';
import 'moment/locale/ko';

export default function DOFileElementView(props){

  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(props.file.handled);
  const [filtered, setFiltered] = useState(0);
  const [filtered_over, setFilteredOver] = useState(0);

  const [filteredValue, setFilteredValue] = useState(0);
  const [filtered_overValue, setFilteredOverValue] = useState(0);

  const [matchStorage, setMatchStorage] = useState(new Array(props.file.matched.length).fill(0))
  /*const [priceObject ,setPriceObject] = React.useState(props.offer.price);
  const [priceReference ,setPriceReference] = React.useState(props.price);
  const [percent, setPercent] = useState(0);


  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }=

  useEffect(() => {
    getDiscount();
  }, []);*/

  const handleShowProductInfo = (key) => (event) => {
    navigate(`/do/product/info/${key}`);
  }

  const parseData = (matched) => {
      let filter_number = 0;
      let filter_number_over_10 = 0;
      let isFilteredValue = 0;
      let isFiltered_over_10_value = 0;

      matched.map((match) => {
        let isFiltered = false;
        let isFiltered_over_10 = false;

        match.reference.map((ref) => {
          if(match.price.value < ref.price.value){
            isFiltered = true;
          }else if(match.price.value < ref.price.value * 1.1){
            isFiltered_over_10 = true;
          }
        })
        if(isFiltered){
          filter_number++;
          if(!isNaN(match.price.value) && !isNaN(match.storage.total)){
              isFilteredValue += match.price.value * match.storage.total;
          }
        }
        if(isFiltered_over_10){
          filter_number_over_10++;
          if(!isNaN(match.price.value) && !isNaN(match.storage.total)){
              isFiltered_over_10_value += match.price.value * match.storage.total;
          }
        }
      })
      setFiltered(filter_number);
      setFilteredOver(filter_number_over_10);

      setFilteredValue(isFilteredValue);
      setFilteredOverValue(isFiltered_over_10_value);
      props.onAddTotal(1, isFilteredValue);
      props.onAddTotal(2, isFiltered_over_10_value);
  }

  const getDiscount = (offset, refer) => {
    if(refer.value === null){
      return '';
    }
    if(offset.value === 0){
      return '';
    }
    const percent = (offset.value - refer.value) / refer.value * 100;
    const valid = (percent < 0);
    return {
      valid : valid,
      percent : percent.toFixed(1)
    };
  }

  useEffect(() => {
    if(props.file.matched.length > 0){
      console.log(props.file.matched);
      parseData(props.file.matched);
    }
  }, [props.file.matched])

  return (
    <Accordion>
      <AccordionSummary
         expandIcon={<ExpandMoreIcon />}
         aria-controls="panel1a-content"
         id="panel1a-header">
        {/*<Paper
          elevation={0}
          sx={{ ...props.sx,
            boxSizing:'border-box',
            width:'100%',
            padding:'8px 8px 8px 16px',
            position:'relative',
            borderRadius:'6px',
            backgroundColor:(isValid ? '#ffffff' : '#eeeeee'),
            border:'1px solid #dddddd'
          }}>*/}
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{width:'100%'}}
            spacing={1}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{mr:'auto'}}
              spacing={1}>
              <Typography variant="p" component="div" sx={{fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
                총 상품 수 : {props.file.number} | 매칭 수 : {props.file.matched.length} | 가격 필터 수 : {filtered} | 10% over : {filtered_over}
              </Typography>
              <Typography variant="p" component="div" sx={{fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
                under 총액 : {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(filteredValue))}
              </Typography>
              <Typography variant="p" component="div" sx={{fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
                over 10% 총액 : {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(filtered_overValue))}
              </Typography>
              <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444'}}>
                {props.file.name}
              </Typography>
            </Stack>
          </Stack>
        {/*</Paper>*/}
      </AccordionSummary>
      <AccordionDetails sx={{textAlign:'left'}}>
        <Stack
          sx={{width:'100%'}}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}>
          <Button variant="outlined" size="small">자세히 보기</Button>
        </Stack>
         {props.file.matched.map((file_product, ind) => (
            (file_product.reference.length > 0) && <Box key={`matched-file-${file_product.code}`}  sx={{width:'100%', borderBottom:'1px solid #dddddd', padding:'12px 0px'}}>
             <Grid container sx={{width:'100%'}}>
                <Grid item md={6}>
                  <Typography variant="p" component="div" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                   <Chip label={file_product.brand.brand} size="small" sx={{mb:'2px', mr:'6px', fontSize:'10px', height:'16px', padding:'0px 4px'}} />
                  </Typography>
                 <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                  {file_product.row+1}번째 줄 | {file_product.code}
                 </Typography>
                 <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                  {(new Intl.NumberFormat(file_product.price['country'], { style: 'currency', currency: file_product.price['currency'] }).format(file_product.price.value))}
                 </Typography>
                 <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                  총액 : {(new Intl.NumberFormat(file_product.price['country'], { style: 'currency', currency: file_product.price['currency'] }).format(file_product.price.value * file_product.storage.total))}
                  ({file_product.storage.total} 개)
                 </Typography>
                 {file_product.images.length > 0 && (
                   <img
                      style={{width:'120px', height:'120px', objectFit:'contain'}}
                      src={`/api/daily/image/${file_product.images[0]}`}/>
                 )}
               </Grid>
               <Grid item md={6}>
                {file_product.reference.map((ref) => {
                  const cri = getDiscount(file_product.price, ref.price);
                  return (
                  <Box key={`matched-reference-${ref.code}`} sx={{width:'100%', borderBottom:'1px solid #eeeeee', padding:'6px 0px 6px 8px', borderLeft: (cri.valid ? "4px solid #1e88e5" : "0px") }}>
                    <Typography onClick={handleShowProductInfo(ref.key)} variant="p" component="p" sx={{fontSize:'13px', fontWeight:'700', mt:'0px !important', color:'#444444', mb:'6px'}}>
                     {ref.name}
                    </Typography>
                    <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                     {ref.code}
                    </Typography>
                    <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                     {(new Intl.NumberFormat(ref.price['country'], { style: 'currency', currency: ref.price['currency'] }).format(ref.price.value))}
                     ({cri.percent} %)
                    </Typography>
                    <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                     일치율 : {ref.criteria.indicator.toFixed(3)}
                    </Typography>
                  </Box>
                  );})}
               </Grid>
             </Grid>
           </Box>
         ))}
       </AccordionDetails>
    </Accordion>
  );
}
