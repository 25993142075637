import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CardMedia from '@mui/material/CardMedia';
// icon list
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
// heartit controller
import LikeController from '../../apis/products/LikeController';

import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

export default function ListView(props){

  const { enqueueSnackbar } = useSnackbar();

  const [priceObject ,setPriceObject] = React.useState(Object.values(props.info.prices)[0]);

  const navigate = useNavigate();
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{width:'100%', borderBottom:'1px solid #eeeeee', padding:'12px 0px'}}>
      <Grid item xs>
        <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
          <Tooltip title={props.info.brand}>
            <Chip label={props.info.brand} size="small" sx={{marginBottom:'4px', fontSize:'12px'}} />
          </Tooltip>
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '11px',
            color:'#666666',
            marginBottom:'4px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            }}>
            {props.info.host}
          </Typography>
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '14px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            }}>
            <a style={{textDecoration:'none', color:'#222222'}} target="_blank" href={props.info.url}>{props.info.name}</a>
          </Typography>
          <>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#999"}}>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"12px", marginRight:'6px', color:"#999", textDecoration:'line-through'}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.retail.$numberDecimal))}
            </Typography>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.sale.$numberDecimal))}
            </Typography>
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"12px", color:"#444444"}}>
            {props.info.sku}
          </Typography>
          </>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{position:'relative', width:'100%', height:'194px'}} onClick={props.onImage}>
          <CardMedia
            component="img"
            height="194"
            image={props.info.images.length > 0 ? props.info.images[0] : "" }
            sx={{objectFit:"contain"}}
            alt={`product-images-${props.info.name}`}
          />
          <Box sx={{position:'absolute', bottom:'12px', right:'12px'}}>
            <Chip icon={<ImageTwoToneIcon size="small" />} size="small" label={props.info.images.length} />
          </Box>
        </Box>
        <Typography variant="body2" color="text.secondary" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          }}>
          {props.info.description}
        </Typography>
      </Grid>
      <Grid item xs>
        <Box sx={{position:'relative', width:'100%'}}>
          <Typography variant="p" component="p" sx={{fontSize:"12px", color:"#444", textAlign:"left", mb:"2px"}}>
            등록일 : {getTimestamp(props.info._id.$oid)}
          </Typography>
          <Typography variant="p" component="p" sx={{fontSize:"12px", color:"#444", textAlign:"left", mb:"8px"}}>
            마지막 업데이트 : {lastTimestamp(props.info.crawled_at.$date)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
