import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import DateInput from '../../components/inputs/DateInput';
import AdjustListTable from '../../components/tables/AdjustListTable';
import DOParameterInput from '../../components/inputs/DOParameterInput';

// controller
import DOStatisticsController from '../../apis/dailyorder/DOStatisticsController';

import moment from 'moment';
import 'moment/locale/ko';

const ADJUSTMENT_LIST = [
  {
    key : "A-AC34048417",
    type : "normal",
    model : "1BZ811-RV44-F0002",
    brand : "Prada",
    image : "https://kream-phinf.pstatic.net/MjAyMjA3MDFfMTgg/MDAxNjU2NjYyMDE2OTA5.a9U1CGOHtX4Xp5PXiDjrwPEEA17TB4txYR_ydAG0SQEg.CvLPtnZeGd3Zl7yNV3P8XtfLnFLxXGmCOR_lmyObS4Qg.JPEG/a_eebc04a3a09c40a89b4b31daff1336e3.jpg?type=m",
    date : "24/02/19 17:35",
    adjust_date : "-",
    size : "U",
    original_price : 2037910,
    sell_price : 2499000,
    adjust_price : 2361600
  },
  {
    key : "A-SW33951065",
    type : "normal",
    model : "GU00154KJ0010 P199",
    brand : "Maison Kitsune",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    date : "24/02/19 23:12",
    adjust_date : "2024/02/23",
    size : "L",
    original_price : 78086,
    sell_price : 90000,
    adjust_price : 85100
  },
  {
    key : "A-SW33950060",
    type : "normal",
    model : "GU00154KJ0010 P199",
    brand : "Maison Kitsune",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    date : "24/02/20 11:32",
    adjust_date : "2024/02/23",
    size : "XXL",
    original_price : 78086,
    sell_price : 100000,
    adjust_price : 94500
  },
  {
    key : "A-SN30054006",
    type : "normal",
    model : "A09FW732-WHITE",
    brand : "Maison Mihara Yashiro",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    date : "24/02/19 08:51",
    adjust_date : "2024/02/21",
    size : "EU 41",
    original_price : 328185,
    sell_price : 395000,
    adjust_price : 373300
  },
  {
    key : "I-SN3848837-1",
    type : "inven",
    model : "A01FW702-WHITE",
    brand : "Maison Mihara Yashiro",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MDhfMjA1/MDAxNjU5OTQ4MjkyNTU2.Z5ALHf6kR4BxNthWacHgpSJ0iKRgHU8ToCC1KzRVUYwg.KkeO0SCfny5nOCQRfW1leZd0A4ZfGKoRI2itkBN6Q2Ug.PNG/a_bfd6450b185f4b438d94847627230509.png?type=l_webp",
    date : "24/02/26 10:12",
    adjust_date : "-",
    size : "EU 37",
    original_price : 279174,
    sell_price : 359000,
    adjust_price : 339300
  },
  {
    key : "I-AC3793187-1",
    type : "inven-95",
    model : "X08396PR818-T8013",
    brand : "Diesel",
    image : "https://kream-phinf.pstatic.net/MjAyMjExMDFfMTQz/MDAxNjY3Mjc0MzE2MTkz.h5rmhhvqvvNupiSy6c25q23lMIL-EYbRPl45u3B7clUg.PjJtaLrcyP0k4JQMPTr0BpFM22tihZMbLBhRumnz7hQg.JPEG/a_bfa0574f934d49cf95fdd65b7d7c238d.jpg?type=l_webp",
    date : "24/02/14 08:16",
    adjust_date : "2024/02/15",
    size : "U",
    original_price : 350790,
    sell_price : 430000,
    adjust_price : 406400
  },
  {
    key : "I-AC3786615-1",
    type : "inven-95",
    model : "X08396PR818-T8013",
    brand : "Diesel",
    image : "https://kream-phinf.pstatic.net/MjAyMjExMDFfMTQz/MDAxNjY3Mjc0MzE2MTkz.h5rmhhvqvvNupiSy6c25q23lMIL-EYbRPl45u3B7clUg.PjJtaLrcyP0k4JQMPTr0BpFM22tihZMbLBhRumnz7hQg.JPEG/a_bfa0574f934d49cf95fdd65b7d7c238d.jpg?type=l_webp",
    date : "24/02/18 13:50",
    adjust_date : "2024/02/19",
    size : "U",
    original_price : 350790,
    sell_price : 425000,
    adjust_price : 401700
  },
  {
    key : "I-AC3751820-1",
    type : "inven-95",
    model : "223AC001-5001-990",
    image : "https://kream-phinf.pstatic.net/MjAyMjA2MDlfMjI4/MDAxNjU0NzYyMjQ4MjE0.QqOVmYgK_jTPrfwZIdQO6A2tDWFoA9OcYy7wUY8EUTIg.nd5chP9D-I3TTdATeJy2uboOx15tm4jFQBLqV2PFDe4g.JPEG/a_c9ae0ff5a5a14df697f331a62ab25aab.jpg?type=l_webp",
    date : "24/02/20 15:08",
    brand : "Jacquemus",
    adjust_date : "2024/02/21",
    size : "60",
    original_price : 90250,
    sell_price : 112000,
    adjust_price : 105900
  },
  {
    key : "I-SN3719242-1",
    type : "inven",
    model : "A01FW702-WHITE",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MDhfMjA1/MDAxNjU5OTQ4MjkyNTU2.Z5ALHf6kR4BxNthWacHgpSJ0iKRgHU8ToCC1KzRVUYwg.KkeO0SCfny5nOCQRfW1leZd0A4ZfGKoRI2itkBN6Q2Ug.PNG/a_bfd6450b185f4b438d94847627230509.png?type=l_webp",
    date : "24/02/19 23:39",
    brand : "Maison Mihara Yashiro",
    adjust_date : "2024/02/20",
    size : "EU 38",
    original_price : 279174,
    sell_price : 340000,
    adjust_price : 321300
  }
];
function AdjustmentListPage(props){

  const {
    __statistics,
    __statisticsChange
  } = props;

  const [startDate, setStartDate] = useState(moment(__statistics.start_date));
  const [endDate, setEndDate] = useState(moment(__statistics.end_date));
  const [isWorking, setIsWorking] = React.useState(false);
  const [parameter, setParameter] = React.useState([__statistics.parameter, 100]);
  const [rows, setRows] = React.useState(ADJUSTMENT_LIST);
  const [totalValue, setTotalValue] = React.useState({
    normal : {
      number : 0,
      original : 0,
      sell : 0,
      adjust : 0,
    },
    inven : {
      number : 0,
      original : 0,
      sell : 0,
      adjust : 0,
    }
  });

  const [adjustList, setAdjustList] = React.useState([]);

  const [viewType, setViewType] = React.useState('date');

  const handleViewTypeChange = (event, type) => {
    setViewType(type);
  };

  const handleStartDateInputChange = (value) => {
    setStartDate(value);
  }

  const handleEndDateInputChange = (value) => {
    setEndDate(value);
  }

  const handleParameterChange = (event, newValue) => {
    setParameter(newValue);
  };

  const handleSearchClick = (event) => {
    setTotalValue({
      normal : {
        number : 0,
        original : 0,
        sell : 0,
        adjust : 0,
      },
      inven : {
        number : 0,
        original : 0,
        sell : 0,
        adjust : 0,
      }
    });

    getTotalStatistics();
  }

  const getTotalStatistics = () => {
    let _number=0, _original=0, _sell=0, _adjust=0;
    let _i_number=0, _i_original=0, _i_sell=0, _i_adjust=0;
    let r = ADJUSTMENT_LIST.map((el) => {
      console.log(el);
      const _date = moment(el.date, "YYYY/MM/DD")
      const _startDate = moment(startDate).subtract(1, 'days');
      if(_date >= moment(_startDate) && _date <= moment(endDate)){
        //console.log(_date, moment(startDate));
        if(el.type === "normal"){
          _number++;
          _original += el.original_price;
          _sell += el.sell_price;
          _adjust += (el.adjust_price - 3000);

        }else if(el.type === "inven" || el.type === "inven-95"){
          _i_number++;
          _i_original += el.original_price;
          _i_sell += el.sell_price;
          _i_adjust += (el.adjust_price - 3000);
        }
        return ({
          image : el.image,
          brand : el.brand,
          key : el.key,
          model : el.model,
          size : el.size,
          date : el.date,
          adjust_date : el.adjust_date,
          original_price : el.original_price,
          sell_price : el.sell_price,
          adjust_price : el.adjust_price - 3000,
          profit : el.adjust_price - el.original_price - 3000,
          profit_percent : (el.adjust_price - el.original_price - 3000) / (el.adjust_price - 3000) * 100
        });
      }
    });
    r = r.filter(item => item);
    console.log(r);
    setAdjustList(r);

    setTotalValue((prev) => ({
      normal : {
        number : _number,
        original : _original,
        sell : _sell,
        adjust : _adjust,
      },
      inven : {
        number : _i_number,
        original : _i_original,
        sell : _i_sell,
        adjust : _i_adjust,
      }
    }));
  }

  useEffect(() => {

    /*DOStatisticsController.getKreamStatistics({
      parameter : parameter[0],
      range : {
        start : startDate.format('YYYY-MM-DD'),
        end : endDate.format('YYYY-MM-DD')
      }
    }, getStatisticsDataSuccess, getStatisticsDataError);*/
    getTotalStatistics();
  }, []);

  useEffect(() => {
    __statisticsChange({
      start_date : startDate,
      end_date : endDate,
      parameter : parameter[0]
    });
  }, [startDate, endDate, parameter]);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{ position:'relative', left:{xs : '0px', md:'240px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}>

        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"16px",
              textAlign:"left",
              mb:'12px',
              fontweight:900,
              width:'100%'}}>
              일반판매 (2월 4주차)
          </Typography>
          <Stack
            direction='row'
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{mb:"12px", border:'1px solid #dddddd', borderRadius:'4px'}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                  수량
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                  {totalValue.normal.number}
                {/*(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.total.toFixed(0)))*/}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                매입가
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.normal.original.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                정산가
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.normal.adjust.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                이익
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format((totalValue.normal.adjust - totalValue.normal.original).toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                수익률 (%)
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {((totalValue.normal.adjust - totalValue.normal.original) / totalValue.normal.adjust * 100).toFixed(1)}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="p" component="p"
            sx={{
              fontSize:"20px",
              textAlign:"left",
              mb : '12px',
              fontweight:900,
              width:'100%'}}>
              보관판매 (2월 4주차)
          </Typography>
          <Stack
            direction='row'
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{mb:"12px", border:'1px solid #dddddd', borderRadius:'4px'}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                  수량
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                  {totalValue.inven.number}
                {/*(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.total.toFixed(0)))*/}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                매입가
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.inven.original.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                정산가
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.inven.adjust.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                이익
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format((totalValue.inven.adjust - totalValue.inven.original).toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{p:2}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                수익률 (%)
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {((totalValue.inven.adjust - totalValue.inven.original) / totalValue.inven.adjust * 100).toFixed(1)}
              </Typography>
            </Stack>
          </Stack>

          {/*<Typography variant="p" component="p" sx={{textAlign:'left', fontSize:'12px', color:'#777777'}}>
          최종 업데이트 : 2분전
          </Typography>
          */}
        </Box>

        <AdjustListTable
          rows={adjustList}/>
      </Box>
      <Box sx={{position:'fixed', width:'240px', left:'0px', height:'100%', backgroundColor:'#ffffff', top:'0px', display:{xs:'none', md:'block'}}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', padding:'24px', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 시작일
          </Typography>
          <DateInput
            onChange={handleStartDateInputChange}
            value={startDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 종료일
          </Typography>
          <DateInput
            onChange={handleEndDateInputChange}
            value={endDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            상품 리스트
          </Typography>
          <Stack
            sx={{mt:'12px', width:'100%'}}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <ToggleButtonGroup
              color="primary"
              value={viewType}
              exclusive
              sx={{width:'100%'}}
              onChange={handleViewTypeChange}
              aria-label="Platform">
              <ToggleButton value="date">일자별</ToggleButton>
              <ToggleButton value="product">상품별</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack
            sx={{mt:"12px", width:'100%'}}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            <Button disabled={isWorking} onClick={handleSearchClick} variant="outlined">
              검색하기
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default AdjustmentListPage;
