import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

// controller
import ProductController from '../../apis/products/ProductController';
import DOProductController from '../../apis/dailyorder/DOProductController';

// components
import DOFilteringDialog from '../../components/dialogs/DOFilteringDialog';

import DoRecommendTable from '../../components/tables/DORecommendTable';
import DORecommandOrderView from '../../components/daily/DORecommandOrderView';

import css_table from '../../css/table.module.css';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const drawerWidth = 360;

const transaction_fee = 4000;
const transaction_inter = 5;
const transaction_inter_ratio = (100 - transaction_inter) / 100;

const Main = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

function DOProductRecommendPage(props){

  const params = useParams();
  const navigate = useNavigate();

  const [isFilterOPen, setIsFilterOpen] = useState(false);
  const [productList, setProductList] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState({
    brands : [],
    files:[]
  });
  const [queue, setQueue] = useState({
    number : 200,
    page : 0,
    brands: [],
    profit : -20,
    files:[]
  })
  const [totalValue, setTotalValue] = useState({
    buy : 0,
    sell : 0,
    profit : 0
  })
  const [selectedProduct, setSelectedProduct] = useState({
    name : {
      name : "-",
      subname : "-"
    },
    transaction : [],
    image : [],
    matched : [],
    price:{
      target_size:{}
    }
  });
  const [open, setOpen] = useState(false);

  const handleAddAgent = (event) => {
    navigate("/do/agent/add");
  }

  const handleFilterDialogOpen = () => {
    setIsFilterOpen(true);
  };

  const handleKeyword = (event) => {
    setKeyword(event.target.value);
  }

  const handleFilterDialogClose = () => {
    setIsFilterOpen(false);
  };

  const handleFilterOption = (options) => {
    setQueue((prev) => ({ ...prev, brands: options.brands, page : 0, profit : parseInt(options.profit), files: options.files}));

    //DOProductController.getPriceList(queue, getPriceList, errorPriceList);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (id) => {
    setOpen(true);
    const found = products.find(el => el.id === id);
    if(found){
      setSelectedProduct((prev) => ({...found, target_price : found.price.target }));
    }
  };

  const updateQueue = () => {
    setQueue((prev) => ({...prev, page : prev.page + 1}));
  };

  const getPriceList = (result) => {
    setIsLoadingProduct(false);
    const data = result.data;
    if(data.code === 200){
      setCount(data.count);
      if(queue.page === 0){
        setProducts(data.result);
        setProductList(data.result.map((el) => {

          el.matched.sort(function(a, b) {
            return b.indicator.color - a.indicator.color;
          });
          let per = 0;
          let matched = -1;
          if(el.matched.length > 0){
            matched = 0;
            per = (el.price.target*transaction_inter_ratio - el.matched[0].price.value - transaction_fee)/el.matched[0].price.value*100;
            if(el.matched[0].indicator.indicator > 0.99){
              matched = 1;
            }
          }
          //
          return ({
          id : el.id,
          image : el.image,
          name: { ...el.name, queue: el.queue},
          code : el.code,
          brand : el.brand.name.searched,
          platform : {
            name : "KREAM",
            populate : el.description.like
          },
          sell_price : el.price,
          in_stock : matched,
          volume_power : el.transaction_value,
          expected_ror : el.matched.length === 0 ? 0 : per
        })}));
      }else{
        setProducts(prev => [...prev, ...data.result]);
        setProductList(prev => [...prev, ...data.result.map((el) => {

          el.matched.sort(function(a, b) {
            return b.indicator.color - a.indicator.color;
          });
          let per = 0;
          let matched = -1;
          if(el.matched.length > 0){
            matched = 0;
            //per = (el.price.target - el.matched[0].price.value - transaction_fee)/el.matched[0].price.value*100 - transaction_inter;
            per = (el.price.target*transaction_inter_ratio - el.matched[0].price.value - transaction_fee)/el.matched[0].price.value*100;
            if(el.matched[0].indicator.indicator > 0.99){
              matched = 1;
            }
          }
          //
          return ({
          id : el.id,
          image : el.image,
          name: { ...el.name, queue: el.queue},
          code : el.code,
          brand : el.brand.name.searched,
          platform : {
            name : "KREAM",
            populate : el.description.like
          },
          sell_price : el.price,
          in_stock : matched,
          volume_power : el.transaction_value,
          expected_ror : el.matched.length === 0 ? 0 : per
        })})]);
      }
    }
  }

  const errorPriceList = (data) => {
    setIsLoadingProduct(false);
  }

  const successGetPriceStatistics = (response) => {
    setIsLoading(false);
    if(response.status === 200){
      const data = response.data;
      if(data.code === 200){
        setTotalValue((prev) => ({
          buy : data.buy,
          sell : data.sell,
          profit : (data.sell - data.buy),
        }));
      }
    }
  }

  const errorGetPriceStatistics = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsLoadingProduct(true);
    DOProductController.getPriceList(queue, getPriceList, errorPriceList);
  }, [queue.page, queue.brands, queue.profit, queue.files]);

  useEffect(() => {
    DOProductController.getPriceStatisticsList(queue, successGetPriceStatistics, errorGetPriceStatistics);
  }, [queue.profit]);



  return (
    <>
    <Main open={open} sx={{width:{md : 'calc(100% - 340px)', sm : '100%'}, boxSizing:'border-box', overflow:'scroll', height:'100%',  backgroundColor:"#ffffff",
      padding:'16px 36px', boxSizing:'border-box'}}>
      <Typography variant="h6" component="p" sx={{textAlign:"left", mb:'12px', fontSize:'24px', fontWeight:900}}>
        오더 예측
      </Typography>
      <Box sx={{width:'100%'}}>
        {/*<Typography variant="p" component="p" sx={{textAlign:"left", mb:'16px', fontSize:'14px', fontWeight:300, color:'#777777'}}>
          {moment().fromNow()}
        </Typography>*/}
        <Stack
          direction="row"
          justifyContent="flex-start"
          divider={<Divider orientation="vertical" flexItem />}
          alignItems="center"
          sx={{mb:'14px'}}
          spacing={4}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}>
            <Typography variant="p" component="p" sx={{textAlign:"left", mb:'0px', fontSize:'14px', fontWeight:300, color:'#777777'}}>
              구입가
            </Typography>
            {isLoading ?
              <Skeleton variant="rounded" width={100} height={19} />
              :<Typography variant="p" component="p" sx={{textAlign:"left", mb:'0px', fontSize:'14px', fontWeight:900, color:'#222222'}}>
              {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(totalValue.buy))}
            </Typography>}
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}>
            <Typography variant="p" component="p" sx={{textAlign:"left", mb:'0px', fontSize:'14px', fontWeight:300, color:'#777777'}}>
              판매가
            </Typography>
            {isLoading ?
              <Skeleton variant="rounded" width={100} height={19} />
              :<Typography variant="p" component="p" sx={{textAlign:"left", mb:'0px', fontSize:'14px', fontWeight:900, color:'#222222'}}>
              {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(totalValue.sell))}
            </Typography>}
          </Stack>
          <TextField
            label="키워드 검색어"
            id="search-keyword_query"
            value={keyword}
            onChange={handleKeyword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Stack>
        <DoRecommendTable
          handleClick={handleClick}
          count={count}
          handleUpdate={updateQueue}
          hiData={productList}/>
        <Stack
          sx={{position:'absolute', top:'36px', right:'368px'}}
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          spacing={0.5}>
          <IconButton
            onClick={handleFilterDialogOpen}
            aria-label="filtering">
            <FilterListIcon />
          </IconButton>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            {isLoadingProduct && <CircularProgress size={24} />}
            {queue.brands.length > 0 && (
              queue.brands.length > 1 ?
              <Chip label={`${queue.brands[0].name} 외 ${queue.brands.length-1}건`} />
              :<Chip label={`${queue.brands[0].name}`} />
            )}
            {queue.files.length > 0 && (
              queue.files.length > 1 ?
              <Chip label={`${queue.files[0].name} 외 ${queue.files.length-1}건`} />
              :<Chip label={`${queue.files[0].name}`} />
            )}
            <Chip label={`최소 수익률 : ${queue.profit}%`} />
          </Stack>
        </Stack>
      </Box>
    </Main>
    <Drawer
        sx={{
          width: drawerWidth,
          top:'64px',
          height:'calc(100% - 64px)',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            top:'64px',
            height:'calc(100% - 64px)',
            width: drawerWidth,
          },
        }}
        className={css_table.hi_table__container}
        variant="persistent"
        anchor="right"
        open={open}
      >
      <Box sx={{width:'100%', padding:'24px', position:'relative', boxSizing:'border-box'}} className={css_table.hi_table__container}>
        <Box sx={{width:'100%', paddingBottom:'100%', position:'relative', marginBottom:'12px'}}>
          <img
            referrerPolicy="no-referrer"
            style={{width:'100%', height:'100%', objectFit:'covered', position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', borderRadius:'6px'}}
            src={selectedProduct.image.length > 0 ? selectedProduct.image[0] : ""}/>
        </Box>
        <>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textAlign: 'left',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                marginBottom:'8px',
                fontSize:'12px',
                fontWeight:'500',
                color:'#555555'
            }}
            variant="p"
            component="p">
            {selectedProduct.code}
          </Typography>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textAlign: 'left',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                marginBottom:'2px',
                fontSize:'14px',
                fontWeight:'900',
                color:'#222222'
            }}
            variant="p"
            component="p">
            {selectedProduct.name.name}
          </Typography>
          <Typography
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textAlign: 'left',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                marginBottom:'18px',
                fontSize:'12px',
                color:'#555555'
            }}
            variant="p"
            component="p">
            {selectedProduct.name.subname}
          </Typography>
        </>
        <Box
          sx={{width:'100%', position:'relative'}}>
          <Typography
            sx={{
                textAlign: 'left',
                fontSize:'14px',
                fontWeight:'900',
                color:'#222222',
                marginBottom:'12px',
                position:'relative'
            }}
            variant="p"
            component="p">
            사이즈 별 통계
          </Typography>
        </Box>
        <Box
          sx={{width:'100%', position:'relative', mb:'12px', whiteSpace:'no-wrap', overflow:'scroll'}} className={css_table.hi_table__container}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            divider={<Divider orientation="vertical" flexItem />}
            alignItems="center"
            spacing={0.5}>
            {selectedProduct.price.target_size && Object.keys(selectedProduct.price.target_size).length > 0 && Object.keys(selectedProduct.price.target_size).map((el) => (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}>
                <Typography
                  sx={{
                      textAlign: 'center',
                      fontSize:'12px',
                      fontWeight:'900',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {el}
                </Typography>
                <Typography
                  sx={{
                      textAlign: 'center',
                      fontSize:'12px',
                      fontWeight:'500',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {selectedProduct.price.target_size[el].number}
                </Typography>
                <Typography
                  sx={{
                      textAlign: 'center',
                      fontSize:'12px',
                      fontWeight:'500',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {(Math.round(selectedProduct.price.target_size[el].price/1000)*1000).toLocaleString()}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
        {selectedProduct.transaction.length > 0 && <>
          <Typography
            sx={{
                textAlign: 'left',
                fontSize:'14px',
                fontWeight:'900',
                color:'#222222',
                marginBottom:'12px',
                position:'relative'
            }}
            variant="p"
            component="p">
            거래 기록 (최근 {selectedProduct.transaction.length}건)
            <Button variant="text" size="small" sx={{position:'absolute', right:'0px', height:'20px', top:'0px'}}>더보기 ❭</Button>
          </Typography>
          <Box
            sx={{width:'100%', position:'relative'}}>
            {selectedProduct.transaction.slice(0, Math.min(selectedProduct.transaction.length, 5)).map((el, index) => (<Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              key={`${index}-transaction-list`}
              sx={{mb:'12px', width:'100%'}}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}>
              <Typography
                sx={{
                    textAlign: 'left',
                    fontSize:'12px',
                    fontWeight:'500',
                    color:'#222222',
                }}
                variant="p"
                component="p">
                {el.date}
              </Typography>
              <Typography
                sx={{
                    textAlign: 'left',
                    fontSize:'12px',
                    fontWeight:'500',
                    color:'#222222',
                }}
                variant="p"
                component="p">
                {el.price}
              </Typography>
              <Typography
                sx={{
                    textAlign: 'left',
                    fontSize:'12px',
                    fontWeight:'500',
                    color:'#222222',
                }}
                variant="p"
                component="p">
                {el.size}
              </Typography>
            </Stack>))}
          </Box>
        </>}
        <Typography
          sx={{
              textAlign: 'left',
              fontSize:'14px',
              fontWeight:'900',
              color:'#222222',
              marginBottom:'12px',
          }}
          variant="p"
          component="p">
          오더 가능 리스트
        </Typography>
        {selectedProduct.matched.map((el, index) => (
          <DORecommandOrderView
            key={`${el.code}-${index}`}
            product={{ ...el, target_price:selectedProduct.target_price}}/>
        ))}
      </Box>
    </Drawer>
    <DOFilteringDialog
      open={isFilterOPen}
      onUpdate={handleFilterOption}
      onClose={handleFilterDialogClose} />
    </>
  );
}

export default DOProductRecommendPage;
