import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import LensIcon from '@mui/icons-material/Lens';
import SaveAltTwoToneIcon from '@mui/icons-material/SaveAltTwoTone';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import ToolController from '../../apis/tools/ToolController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import BrandInput from '../../components/inputs/BrandInput';
import ImageStaticUploadDialog from '../../components/dialogs/ImageStaticUploadDialog';

import { NumericFormat } from 'react-number-format';
import '../../css/font/pretendardvariable.css';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

function ImageGeneratorOnlyPage(props){

  const params = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [result, setResult] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleIntialize = (event) => {

  }

  const uploadImageSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      const data = response.data;
      handleSnackBar("성공적으로 이미지가 추가되었습니다", "success");
      let res = "";
      data.images.forEach((el, index) => {
        res += `${index+1} : ${el}\n`;
      });
      setResult(res);
    }
    setIsUploading(false);
  }

  const uploadImageError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  const handleRegister = (event) => {
    setIsUploading(true);
    if(previews.length === 0){
      setIsUploading(false);
      return;
    }
    /*if(memo.length === 0){
      setIsUploading(false);
      return;
    }*/
    console.log(previews);
    ToolController.generateThumbnail({
      images : previews.map((preview) => ({
        name : preview.title,
        file : preview.file
      }))
    }, uploadImageSuccess, uploadImageError);
  }

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
    <Grid container spacing={2}>
      <Grid item xs={8} md={6}>

        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          썸네일 이미지
        </Typography>
        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <ImageUploader
            value={images}
            onChange={handleImageChange}
            sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
            {previews.length > 0 && (
              <>
                <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                  업로드 이미지 수 : {previews.length}
                </Typography>
                <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                  {previews.map((item) => (
                    <ImageListItem key={item.key}>
                      <img
                        src={item.url}
                        srcSet={item.url}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </>
            )}
        </Box>
        {/*<Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
          <Typography
            variant="h6"
            component="p"
            sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
            <Badge color="error" variant="dot">
              동일 브랜드 명
            </Badge>
          </Typography>
          <IconButton
            onClick={handleOpenBrandAliasDialog}
            disabled={brandAliasDialogOpen}
            sx={{position:'absolute', right:'8px', top:'0px'}}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        {alias.length === 0 ? (
          <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
            등록된 동일 브랜드 명이 없습니다
          </Typography>
        ):(
          alias.map((ali) => (
            <Box key={`alias-list-${ali}`} sx={{position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
                {ali}
              </Typography>
              <IconButton
                onClick={handleDeleteBrand(ali)}
                sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
                <DeleteForeverTwoToneIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        )}
        </Box>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          추가 사항
        </Typography>
        <TextField
            id="standard-multiline-static"
            hiddenLabel
            disabled={isUploading}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            placeholder="기타 기입할 사항을 입력해주세요"
            multiline
            value={memo}
            onChange={handleMemoInput}
            InputLabelProps={{
              shrink: true,
            }}
            rows={4}
            variant="outlined"
          />*/}
        </Grid>
        <Grid item xs={8} md={6}>
          <Box sx={{position:'relative', width:'100%', mb:'18px'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
              이미지 소스
            </Typography>
          </Box>

          <Box sx={{position:'relative', width:'100%', overflow:'scroll'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', whiteSpace: 'pre-wrap', mb:'12px', color:'#222222', fontWeight:300}}>
              {result}
            </Typography>
          </Box>

        </Grid>
      </Grid>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{width:'100%'}}
          spacing={2}>
          <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">등록 취소</Button>
          <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
          <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">생성하기</Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default ImageGeneratorOnlyPage;
