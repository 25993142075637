import React, {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DOBrandElementView from './DOBrandElementView';

import { pink, blue, red } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

export default function DOBrandView(props){

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalOver10Price, setTotalOver10Price] = useState(0);

  const [totalNumber, setTotalNumber] = useState(0);
  const [totalOver10Number, setTotalOver10Number] = useState(0);

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }

  const handleOnAddPrice = (type, value) => {
    props.onAddTotal(type, value);
  }

  const getDiscount = (offset, refer) => {
    if(refer.value === null){
      return '';
    }
    if(offset.value === 0){
      return '';
    }
    const percent = (offset.value - refer.value) / refer.value * 100;
    const valid = (percent < 0);
    return {
      valid : valid,
      percent : percent.toFixed(1)
    };
  }

  const parseData = (matched) => {
      let filter_number = 0;
      let filter_number_over_10 = 0;
      let isFilteredValue = 0;
      let isFiltered_over_10_value = 0;

      matched.map((match) => {
        let isFiltered = false;
        let isFiltered_over_10 = false;

        match.reference.map((ref) => {
          if(match.price.value < ref.price.value){
            isFiltered = true;
          }else if(match.price.value < ref.price.value * 1.1){
            isFiltered_over_10 = true;
          }
        })
        if(isFiltered){
          filter_number++;
          if(!isNaN(match.price.value) && !isNaN(match.storage.total)){
              isFilteredValue += match.price.value * match.storage.total;
          }
        }
        if(isFiltered_over_10){
          filter_number_over_10++;
          if(!isNaN(match.price.value) && !isNaN(match.storage.total)){
              isFiltered_over_10_value += match.price.value * match.storage.total;
          }
        }
      })
      setTotalNumber(filter_number);
      setTotalOver10Number(filter_number_over_10);

      setTotalPrice(isFilteredValue);
      setTotalOver10Price(isFiltered_over_10_value);
      props.onAddTotal(1, isFilteredValue);
      props.onAddTotal(2, isFiltered_over_10_value);
  }

  useEffect(() => {
    if(props.products.length > 0){
      parseData(props.products);
    }
  }, [props.products])

  return (
    <Card
      elevation={0}
      sx={{ position:'relative', width:"100%" }}>

      <Accordion>
        <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header">

          <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{width:'100%'}}
              spacing={1}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                sx={{mr:'auto'}}
                spacing={1}>

                <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
                  <Tooltip title={props.brand.name}>
                    <Chip label={props.brand.name} size="small" sx={{mb:'8px', fontSize:'12px', padding:'0px 8px'}} />
                  </Tooltip>
                </Box>
                <Typography variant="p" component="p" sx={{
                  textAlign:"left",
                  display: '-webkit-box',
                  overflow: 'hidden',
                  fontSize: '14px',
                  mb:'4px',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  }}>

                </Typography>
                <Typography variant="p" component="div" sx={{textAlign:'left', fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
                  under 총액 : {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalPrice))}
                </Typography>
                <Typography variant="p" component="div" sx={{textAlign:'left', fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
                  over 10% 총액 : {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalOver10Price))}
                </Typography>

              </Stack>
            </Stack>

        </AccordionSummary>
        <AccordionDetails sx={{textAlign:'left'}}>
        {props.products.map((file_product, ind) => (
           (file_product.reference.length > 0) && <Box key={`matched-file-${file_product.code}`}  sx={{width:'100%', borderBottom:'1px solid #dddddd', padding:'12px 0px'}}>
            <Grid container sx={{width:'100%'}}>
               <Grid item md={6}>
               <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                 <Tooltip title={file_product.email.vendor.email}>
                   <Chip label={file_product.email.vendor.name} size="small" sx={{mb:'8px', fontSize:'12px', padding:'0px 8px'}} />
                 </Tooltip>
               </Typography>
               <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                {file_product.file.name}
               </Typography>
               <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                {lastTimestamp(file_product.email.mail_date)}
               </Typography>
                <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                 {file_product.row+1}번째 줄 | {file_product.code}
                </Typography>
                <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                 {(new Intl.NumberFormat(file_product.price['country'], { style: 'currency', currency: file_product.price['currency'] }).format(file_product.price.value))}
                </Typography>
                <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                 총액 : {(new Intl.NumberFormat(file_product.price['country'], { style: 'currency', currency: file_product.price['currency'] }).format(file_product.price.value * file_product.storage.total))} ({file_product.storage.total} 개)
                </Typography>
              </Grid>
              <Grid item md={6}>
               {file_product.reference.map((ref) => {
                 const cri = getDiscount(file_product.price, ref.price);
                 return (
                 <Box key={`matched-reference-${ref.code}`} sx={{width:'100%', borderBottom:'1px solid #eeeeee', padding:'6px 0px 6px 8px', borderLeft: (cri.valid ? "4px solid #1e88e5" : "0px") }}>
                   <Typography variant="p" component="p" sx={{fontSize:'13px', fontWeight:'700', mt:'0px !important', color:'#444444', mb:'6px'}}>
                    {ref.name}
                   </Typography>
                   <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                    {ref.code}
                   </Typography>
                   <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                    {(new Intl.NumberFormat(ref.price['country'], { style: 'currency', currency: ref.price['currency'] }).format(ref.price.value))}
                    ({cri.percent} %)
                   </Typography>
                   <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444', mb:'6px'}}>
                    일치율 : {ref.criteria.indicator.toFixed(3)}
                   </Typography>
                 </Box>
                 );})}
              </Grid>
            </Grid>
          </Box>
        ))}
         </AccordionDetails>
      </Accordion>
    </Card>
  );
}
