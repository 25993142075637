import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

import Box from '@mui/material/Box';
const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

function ImageUploader(props) {

  const handleChange = (file) => {
    props.onChange(file);
  };
  return (
    <Box
      sx={props.sx}>
      <FileUploader
        handleChange={handleChange}
        name="file"
        multiple={true}
        minSize={0}
        maxSize={2}
        label="이미지를 여기에 업로드해주세요"
        hoverTitle="여기에 드랍해주세요"
        types={fileTypes} />
    </Box>
  );
}

export default ImageUploader;
