import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOMailController{

  static getMails(data, _success, _error){
    const token = getToken();
    console.log(data);
    axios({
      method: "POST",
      url : "/api/daily/mails_test",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }

  static getMailRaws(data, _success, _error){
    const token = getToken();
    console.log(data);
    axios({
      method: "POST",
      url : "/api/daily/raw_mails",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }

  static getFileData(key, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url : `/api/daily/file/${key}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }

  static getTodayFileList(_success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url : `/api/daily/file/today`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
