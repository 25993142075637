import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOUserController{
  static getUsers(query, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url:"/api/daily/users",
      data:query,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getProduct(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/products",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
