import {React, useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import css_table from '../../css/table.module.css';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

const columns = [
  { id: 'index', label: 'No.', minWidth: 32 },
  { id: 'image',
    label: '이미지',
    minWidth: 150,
    type:"image",
    format : (value) => `/api/daily/image/${value}`
  },
  { id: 'name', label: '상품명', minWidth: 100 },
  {
    id: 'brand',
    label: '브랜드',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'code',
    label: '상품코드',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'color_code',
    label: '색상 코드 (색상)',
    minWidth: 170,
    align: 'center',
    format : (value) => value.join(" ")
  },
  {
    id: 'size_code',
    label: '사이즈',
    minWidth: 150,
    align: 'center',
    format : (value) => value.join(" ")
  },
  {
    id: 'product_key',
    label: '하트잇 고유코드',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'storage_number',
    label: '수량',
    minWidth: 170,
    align: 'center'
  },
  {
    id: 'target_price',
    label: '목표 가격',
    minWidth: 170,
    align: 'center',
    format: (value) => (new Intl.NumberFormat("kr", { style: 'currency', currency: "KRW" }).format(value))
  },
  {
    id: 'weight',
    label: '상품 구분',
    minWidth: 170,
    align: 'center',
    format: (value) => (
      (value === DAILY_PRODUCT_TYPE_MUST) ? "머스트" :
      (value === DAILY_PRODUCT_TYPE_OK) ? "OK" :
      "테스트"
    )
  },
  {
    id: 'platform_1_name',
    label: '플랫폼',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'platform_1_url',
    label: '플랫폼 링크',
    minWidth: 180,
    align: 'center'
  },
  {
    id: 'platform_2_name',
    label: '플랫폼',
    minWidth: 100,
    align: 'center'
  },
  {
    id: 'platform_2_url',
    label: '플랫폼 링크',
    minWidth: 180,
    align: 'center'
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}


export default function DOProductTable(props) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
    setRows(props.products);
  }, [props.products]);

  return (
    <Paper className={css_table["hi_table__container"]} sx={{ position:'relative', width: '100%' }}>
      <TableContainer className={css_table["hi_table__container"]} sx={{ maxHeight: 'calc(100vh - 102px)', minHeight: 300 }}>
        <Table className={css_table["hi_table__container"]} stickyHeader aria-label="sticky table">
          <TableHead sx={{backgroundColor:"#ffffff"}}>
            <TableRow sx={{height:'57px'}}>
              <TableCell align="center" colSpan={11} sx={{borderBottom:'0px', lineHeight:1, padding:'0px'}}>

              </TableCell>
              <TableCell align="center" colSpan={2} sx={{borderBottom:'0px', lineHeight:1, padding:'0px'}}>
                외부 링크 1
              </TableCell>
              <TableCell align="center" colSpan={2} sx={{borderBottom:'0px', lineHeight:1, padding:'0px'}}>
                외부 링크 2
              </TableCell>
            </TableRow>
            <TableRow sx={{height:'57px'}}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ top: 57, minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, _index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`${row.code}-${_index}`}>
                    {columns.map((column, index) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={`${column.id}-${column.index}`} align={column.align}>
                          {
                            (column.type && column.type === 'image')
                            ? (
                              <img
                                style={{width:'150px', height:'84px', objectFit:'contain'}}
                                src={column.format(value)}>
                              </img>
                            ) :
                            (column.format && typeof value === 'number')
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Backdrop
        sx={{
            position:'absolute',
            top:'0px',
            left:'0px',
            right:'0px',
            bottom:'0px',
            width:'100%',
            height:'100%',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
}
