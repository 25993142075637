import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import PivotTableChartTwoToneIcon from '@mui/icons-material/PivotTableChartTwoTone';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// heartit view
import DOProductView from '../../components/daily/DOProductView';
import DOMailRawView from '../../components/daily/DOMailRawView';
import DOBrandView from '../../components/daily/DOBrandView';
import DateInput from '../../components/inputs/DateInput';
import DOParameterInput from '../../components/inputs/DOParameterInput';
import DOViewTypeSelector from '../../components/inputs/DOViewTypeSelector';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW,
  DAILY_ORDER_PRODUCT_VIEW,
  DAILY_ORDER_TIME_VIEW,
  DAILY_ORDER_AGENT_VIEW
} from '../../assets/Constants';
// controller
import ProductController from '../../apis/products/ProductController';
import DOProductController from '../../apis/dailyorder/DOProductController';
import DOMailController from '../../apis/dailyorder/DOMailController';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

function DOMailPage(props){

  const {
    __dailyOrderFileList,
    __dailyOrderFileListChange
  } = props;


  const params = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState({});
  const [brands, setBrands] = useState([]);
  const [mails, setMails] = useState([]);
  const [isWorking, setIsWorking] = React.useState(false);
  const [parameter, setParameter] = React.useState([80, 100]);
  const [targetDate, setTargetDate] = useState({
    start : moment(__dailyOrderFileList.fl_start_date),
    end : moment(__dailyOrderFileList.fl_end_date)
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalOver10Price, setTotalOver10Price] = useState(0);
  const [orderType, setOrderType] = useState(DAILY_ORDER_TIME_VIEW);
  const [viewOrderType, setViewOrderType] = useState(DAILY_ORDER_TIME_VIEW);
  const [viewType, setViewType] = useState(PRODUCT_CARD_VIEW);

  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });
  const refList = {
    actionBar : useRef(null),
    filterTop : useRef(null),
    product_list: useRef(null)
  };

  const handleAddTotal = (type, value) => {
    if(type === 1){
      if(!isNaN(value)){
        setTotalPrice(prev => prev + value);
      }
    }else if(type === 2){
      if(!isNaN(value)){
        setTotalOver10Price(prev => prev + value);
      }
    }
  }

  const handleDateInputChange = (type) => (value) => {
    if(type === "start"){
      setTargetDate((prev) => ({ ...prev, start: value }));
    }else{
      setTargetDate((prev) => ({ ...prev, end: value }));
    }

  }
  const handleAddProduct = (event) => {
    navigate("/do/product/add");
  };

  const handleFileProduct = (event) => {
    navigate("/do/product/add/file");
  };

  const getMailSuccess = (response) => {
    setTotalPrice(0);
    setTotalOver10Price(0);
    setIsWorking(false);
    if(response.data.code === 200){
      console.log(response.data);
      if(response.data.type === 200){
        setViewOrderType(DAILY_ORDER_AGENT_VIEW)
        console.log(response.data.mails.products);
        setProducts(response.data.mails.products);
        setBrands(response.data.mails.brands);
      }else{
        setViewOrderType(DAILY_ORDER_TIME_VIEW);
        setMails(response.data.mails);

      }
    }
  };

  const getMailError = (error) => {
    setIsWorking(false);
  };

  const handleViewTypeChange = (type) => {
    setOrderType(type);
  };

  const handleParameterChange = (event, newValue) => {
    setParameter(newValue);
  };

  const handleSearchClick = (event) => {
    if(targetDate){
      setIsWorking(true);

      const start_date = targetDate.start.format('YYYY-MM-DD');
      const end_date = targetDate.end.format('YYYY-MM-DD');
      const param = [parameter[0] / 100.0, parameter[1] / 100.0]
      setMails([]);
      setProducts({});
      setBrands([]);
      let body = {
        start_date : start_date,
        end_date : end_date,
        parameter : param
      }
      if(orderType === DAILY_ORDER_AGENT_VIEW){
        body.type = 200;
      }else{
        body.type = 100;
      }
      DOMailController.getMailRaws(body, getMailSuccess, getMailError);
    }
  };

  useEffect(() => {
    __dailyOrderFileListChange({
      start_date : targetDate.start,
      end_date : targetDate.end
    });
  }, [targetDate.start, targetDate.end]);

  useEffect(() => {
    //DOProductController.getProduct({}, getProductSuccess, getProductError);

    const start_date = targetDate.start.format('YYYY-MM-DD');
    const end_date = targetDate.end.format('YYYY-MM-DD');
    setIsWorking(true);
    const _type = 100;
    DOMailController.getMailRaws({start_date : start_date, end_date : end_date, type:_type}, getMailSuccess, getMailError);
  }, []);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{position:{md : 'absolute', sm:'relative'}, zIndex:1, top:'24px', right:'32px',mb:'12px'}}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}>

        </Stack>
      </Box>

      <Box sx={{ position:'relative', left:{xs : '0px', md:'240px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}>
        <Backdrop
          sx={{
              position:'absolute',
              top:'0px',
              left:'16px',
              right:'0px',
              bottom:'0px',
              width:'100%',
              height:'100%',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isWorking}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <Stack
            direction='row'
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{mb:"8px"}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                  총 메일수
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {mails.length}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                총 구입 가능
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalPrice.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                추가 금액 (10%)
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalOver10Price.toFixed(0)))}
              </Typography>
            </Stack>
          </Stack>
          {/*<Typography variant="p" component="p" sx={{textAlign:'left', fontSize:'12px', color:'#777777'}}>
          최종 업데이트 : 2분전
          </Typography>
          */}
        </Box>
        <Grid container ref={refList.product_list} item sx={{width:"100%", overflow:"scroll"}} spacing={2}>
        { (viewOrderType === DAILY_ORDER_AGENT_VIEW) ?
          (
            Object.keys(products).length > 0 ? (
              Object.entries(products).map((brand, data) => (
                <Grid item key={`brand-${brand}`} xs={12} md={10} xl={8} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
                <DOBrandView
                  parameter={parameter}
                  onAddTotal={handleAddTotal}
                  brand={brands.find(obj => (obj.id) === parseInt(brand) )}
                  products={products[parseInt(brand)]}/>
                </Grid>
              ))
            ) : (<></>)
          )
          : (mails.length > 0 ? (
          mails.map((el) => (
            ( viewType === PRODUCT_CARD_VIEW ) ?
            <Grid item key={`product-${el.key}`} xs={12} md={10} xl={8} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
              <DOMailRawView
                parameter={parameter}
                onAddTotal={handleAddTotal}
                info={el}/>
            </Grid> :
            <Grid item key={`product-list-${el.key}`} xs={12} sx={{ paddingBottom:"4px"}}>

            </Grid>
          ))
        ) : (<></>)) }
        </Grid>
      </Box>
      <Box sx={{position:'fixed', width:'240px', left:'0px', height:'100%', backgroundColor:'#ffffff', top:'0px', display:{xs:'none', md:'block'}}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', padding:'24px', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"18px",
              fontWeight:"900",
              mb:"8px",
              textAlign:"left",
              width:'100%'}}>
            분석 일자
          </Typography>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"13px",
              color:'#555555',
              fontWeight:"300",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 시작일
          </Typography>
          <DateInput
            onChange={handleDateInputChange("start")}
            value={targetDate.start}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"13px",
              color:'#555555',
              fontWeight:"300",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 종료일
          </Typography>
          <DateInput
            onChange={handleDateInputChange("end")}
            value={targetDate.end}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            상품 뷰 기준
          </Typography>
          <DOViewTypeSelector
            onChange={handleViewTypeChange}/>
          <Stack
            sx={{mt:"12px", width:'100%'}}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            <Button disabled={isWorking} onClick={handleSearchClick} variant="outlined">
              검색하기
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default DOMailPage;
