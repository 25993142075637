import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';


import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Skeleton from '@mui/material/Skeleton';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ProductImageDialog(props) {

  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setProduct(props.images);
    // carousel initializing
    setActiveStep(0);
    setMaxSteps(props.images.length);

  }, [props.images]);

  useEffect(() => {
    if(!props.open){
      setIsLoading(true);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'sm'}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isLoading ?
          <Skeleton
              animation="wave"
              height={28}
              width="80%"
              style={{ marginBottom: 6 }}/>
          : props.name}
      </DialogTitle>
      <DialogContent>
      <Box sx={{ position:'relative', width: '100%', flexGrow: 1 }}>

        <Box
            component="img"
            sx={{
              height: 255,
              display: 'block',
              overflow: 'hidden',
              width: '100%',
              objectFit:'contain'
            }}
            onLoad={handleImageLoad}
            src={product.length == 0 ? "" : product[activeStep]}
          />
        {isLoading && <Box sx={{width:'100%', height:255, position:'absolute', top:'0px', backgroundColor:'#ffffff'}}>
           <Skeleton
            animation="wave"
            height={255}
            variant='round'
            width="100%"
            style={{ margin:'auto' }}/>
        </Box>}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>
      </DialogContent>
      {/*<DialogActions>
        <Button autoFocus onClick={props.onClose}>
          Cancel
        </Button>
        <Button onClick={props.onClose}>Subscribe</Button>
      </DialogActions>*/}
    </Dialog>
  );
}
