import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Typography from '@mui/material/Typography';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

import ImageUploader from '../inputs/ImageUploader';

// heartit controller
import ToolController from '../../apis/tools/ToolController';

import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ImageStaticUploadDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading]  = useState(false);
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);

  const [error, setError] = useState({});

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleSubmit = (event) => {
    setIsLoading(true);
    let isValid = true;
    let _error = { };

    if(!isValid){
      setError(_error);
      setIsLoading(false);
      return;
    }
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ]);

            ToolController.uploadStaticImage({
              images : tempImage.map((preview) => ({
                name : preview.title,
                file : preview.file
              })),
            }, (ss) => {
              console.log(ss);
            }, (e) => {
              console.log(e);
            });

        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ]);

            ToolController.uploadStaticImage({
              images : tempImage.map((preview) => ({
                name : preview.title,
                file : preview.file
              })),
            }, (ss) => {
              console.log(ss);
            }, (e) => {
              console.log(e);
            });
        }
      };
      reader.readAsDataURL(file);
    })
  };



  const editImage = () => {

  }

  useEffect(() => {
    console.log(previews);
  }, [previews]);

  useEffect(() => {

  }, [props.initialData]);

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         이미지 리소스 삽입
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
            <Box sx={{width:'100%', height:'48px', mb:'8px'}}>
              <ImageUploader
                value={images}
                onChange={handleImageChange}
                sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
              {previews.length > 0 && (
                <>
                  <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                    업로드 이미지 수 : {previews.length}
                  </Typography>
                  <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                    {previews.map((item) => (
                      <ImageListItem key={item.key}>
                        <img
                          src={item.url}
                          srcSet={item.url}
                          alt={item.title}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
            </Box>
         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>수정하기</Button>
       </DialogActions>
     </Dialog>
  )
};
