import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// components
import DOStorageTempTable from '../../components/tables/DOStorageTempTable';

// heartit controller
import StorageController from '../../apis/storage/StorageController';

// heartit input
import ExcelInput from '../../components/inputs/ExcelInput';
import BrandInput from '../../components/inputs/BrandInput';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

function StorageFileAddPage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [memo, setMemo] = useState("");
  const [files, setFiles] = useState([]);
  const [products, setProducts] = useState([]);
  const [price, setPrice] = React.useState('');
  const [brandList, setBrandList] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const uploadProductSuccess = (response) => {
    if(response.data.code === 200){
      handleSnackBar("성공적으로 상품이 등록되었습니다", "success");
      navigate("/storage");
    }
    setIsUploading(false);
  };

  const uploadProductError = (error) => {
    setIsUploading(false);
  }

  const handleRegister = (event) => {
    setIsUploading(true);
    StorageController.createProductExcel(
      {products : products.map((el) => ({
        code : el.code,
        brand : el.brand_id,
        name : el.name,
        images : (el.image === null) ? [] : [el.image],
        price : el.target_price,
        description : "",
        size : el.size_code,
        color : el.color_code,
        weight : el.weight,
        link : el.platform.map((plat, index) => {
          if (el.platform_link.length > index){
            return {
              "host" : plat,
              "link" : el.platform_link[index]
            }
          }else{
            return {};
          }
        })
      }))},
      uploadProductSuccess,
      uploadProductError);
  }

  const handleCancel = (event) => {
    navigate(-1);
  }

  const parseFileSuccess = (response) => {
    setIsUploading(false);
    if(response.data.code === 200){
      const len = products.length;
      setProducts([...products, ...response.data.product.map((el, index) => ({
        no : (len+index+1) ,
        image : el.kream.images[0],
        name : el.name,
        agent : el.agent,
        date : el.date,
        brand : el.brand,
        brand_id : el.brand_id,
        product_code : el.product_code,
        manage_code : el.storage_code,
        size : el.size,
        total_number : el.storage,
        buy_price : el.price,
        keepable : el.is_stackable,
        policy : el.type,
      }))]);
    }
  };

  const parseFileError = (error) => {
    setIsUploading(false);
  };

  const handleFileChange = (files) => {
    console.log(files);
    if(files.length == 0){
      return;
    }
    setIsUploading(true);
    StorageController.parseFile({
      files : Array.from(files).map((file) => ({
        file : file
      }))
    }, parseFileSuccess, parseFileError);
  }

  const handleExcelDownload = () => {
    const url = "/api/storage/download/reference_excel";

    fetch(url, { method: 'GET' })
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = "heartit_storage_management.xlsx";
            document.body.appendChild(a);
            a.click();
            setTimeout((_) => {
                window.URL.revokeObjectURL(url);
            }, 60000);
            a.remove();
        })
        .catch((err) => {
            console.error('err: ', err);
        });
  }

  const handleIntialize = (event) => {
    setMemo("");
    setName("");
    setBrandList(null);
    setCode("");
    setPrice('');
  }

  return (
    <Box sx={{width:{md : 'calc(100%)', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" component="p" sx={{fontSize:'14px', maxWidth:'400px', mb:'12px', color:'#777777', fontWeight:300}}>
        엑셀 파일을 통해서 동시에 여러 파일을 업로드 할 수 있습니다. 파일을 다운로드 하시고 해당 파일을 업로드 해주시면, 자동으로 상품들이 등록됩니다.
      </Typography>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        엑셀 파일 업로드
      </Typography>
      <Box sx={{width:'100%', mb:'12px'}}>
        <Button disableElevation onClick={handleExcelDownload} size="small" variant="contained">기준 파일 다운로드</Button>
      </Box>
      <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
        <ExcelInput
          value={files}
          onChange={handleFileChange}
          sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
      </Box>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        업로드된 재고
      </Typography>
      <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
        <DOStorageTempTable
          loading={isUploading}
          rows={products} />
      </Box>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{width:'100%'}}
          spacing={2}>
          <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">등록 취소</Button>
          <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
          <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">등록하기</Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default StorageFileAddPage;
