import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// heartit controller
import HostController from '../../apis/hosts/HostController';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function HostInput(props){

  const [open, setOpen] = React.useState(false);
  const [hostList, setHostList] = React.useState([]);
  const loading = open && hostList.length === 0;

  const getHostSuccess = (response) => {
    if(response.data.code === 200){
      setHostList(response.data.hosts);
    }
  }

  const getHostError = (error) => {

  }

  React.useEffect(() => {
     let active = true;

     if (!loading) {
       return undefined;
     }


     (async () => {

       if (active) {
         HostController.getHosts({}, getHostSuccess, getHostError);
       }
     })();

     return () => {
       active = false;
     };
   }, [loading]);

   React.useEffect(() => {
     if (!open) {
       setHostList([]);
     }
   }, [open]);
  return (
    <Autocomplete
        multiple
        id="product-find-autocomplete-host"
        open={open}
        size="small"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={hostList}
        sx={props.sx}
        loading={loading}
        value={props.value}
        onChange={props.onChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
             {...params}
             label="호스트"
             InputProps={{
               ...params.InputProps,
               endAdornment: (
                 <React.Fragment>
                   {loading ? <CircularProgress color="inherit" size={20} /> : null}
                   {params.InputProps.endAdornment}
                 </React.Fragment>
               ),
             }}
           />
        )}
      />
  );
}
