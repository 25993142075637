import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

import { useSnackbar } from 'notistack';

import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// heartit components
import HostSelector from '../inputs/HostSelector';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export default function CrawlerChangeDialog(props) {

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'sm'}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-crawler-change"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title-crawler-change">
        크롤러 수정하기
      </DialogTitle>
      <DialogContent>

      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} sx={{marginRight:'auto'}}>
          삭제하기
        </Button>
        <Button onClick={props.onClose}>
          취소하기
        </Button>
        <Button autoFocus onClick={props.onClose}>수정하기</Button>
      </DialogActions>
    </Dialog>
  );
}
