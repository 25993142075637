import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';

import { visuallyHidden } from '@mui/utils';

import ReactToPrint, {
  PrintContextConsumer,
  useReactToPrint
} from "react-to-print";

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import LabelPrintComponent from '../../components/tools/LabelPrintComponent';

// alert
import { SnackbarProvider, useSnackbar } from 'notistack';

import moment from 'moment';
import 'moment/locale/ko';

const pageStyle = `
  @page {
    // size: auto
  }

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    @page {
      size: A4;
      width: 210mm;
      height: 297mm;
      margin: 16mm 7.1mm 13.1mm 7.1mm !important;
      padding:0px !important;
    }
    .page-break {
      page-break-after: always;
      /*display: block;
      position:absolute;
      width: 210mm;
      height: 297mm;
      margin:0px !important;
      padding:0px !important;*/
      //padding: 15.1mm 7.1mm 13.1mm 7.1mm
    }
  }
  `;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'agent',
    numeric: false,
    disablePadding: false,
    label: '입고사',
    minWidth: 72,
  },
  {
    id: 'initial_date',
    numeric: false,
    disablePadding: false,
    label: '입고일',
    minWidth: 84,
  },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: '브랜드',
    minWidth: 100,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: '상품명',
    minWidth: 150,
  },
  {
    id: 'product_code',
    numeric: false,
    disablePadding: false,
    label: '상품 코드',
    minWidth: 150,
  },
  {
    id: 'storage_code',
    numeric: false,
    disablePadding: false,
    label: '물류 코드',
    minWidth: 150,
  },
  {
    id: 'size',
    numeric: false,
    disablePadding: false,
    label: '사이즈',
    minWidth: 72,
  },
  {
    id: 'total_number',
    numeric: false,
    disablePadding: false,
    label: '총량',
    minWidth: 72,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: '입고가',
    minWidth: 72,
  },
  {
    id: 'now_status',
    numeric: false,
    disablePadding: false,
    label: '현재 상태',
    minWidth: 72,
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleDelete,
    handlePrint } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{
            flex: '1 1 100%',
            textAlign: 'left',
            fontSize:'14px',
            color:'#222222',
            fontWeight:'900'
          }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}개의 SKU 선택
        </Typography>
      ) : (
        <Box
          sx={{
            flex: '1 1 100%',
            textAlign:'left'
          }}
          component="div">
          <TextField
            id="input-with-icon-textfield"
            label="검색어"
            sx={{ml:'-12px'}}
            value={props.keyword}
            onChange={props.handleKeyword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Box>
      )}

      {numSelected > 0 ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}>
          <Tooltip title="Print">
            <IconButton
              onClick={() => {
                handlePrint()
              }}>
              <LocalPrintshopTwoToneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                handleDelete()
              }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete : PropTypes.func.isRequired
};

export default function DOStorageInTable(props) {
  const [rows, setRows] = React.useState(props.rows);
  const [keyword, setKeyword] = React.useState("");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [allRows, setAllRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [isPrint, setIsPrint] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const printComponent = useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
    setLoading(true);

    setIsPrint(true);
    console.log(selected);
    /*if(selected.length === 0){
      handleSnackBar("선텍된 개체가 없습니다", "error");
      return;
    }*/

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 500);
    });
  }, [setLoading, setSelected]);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      //setAllRows((prev) => (rows));
      setSelected(newSelected);
      return;
    }
    //setAllRows((prev) => ([]));
    setSelected([]);
  };

  const handleGetProductInfo = (id) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    //console.log(id);
    props.onNavigate(id);
  }

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected((prev) => (newSelected));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = (event) => {
    setIsPrint(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (event)  => {
    props.onDelete(selected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const reactToPrintContent = useCallback(() => {
    console.log(printComponent.current);
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    pageStyle:pageStyle
  });

  useEffect(() => {
    setRows(props.rows);
    setSelected((prev) => ([]))
  }, [props.rows]);

  useEffect(() => {
    const selectedRow = selected.map((el) => {
      return rows.find((_el) => _el.id == el);
    });
    setAllRows((prev) => selectedRow);
  }, [selected]);

  useEffect(() => {
    const new_rows = [...props.rows].filter(el => (el.name.indexOf(keyword) >= 0) );
    setRows(prev => new_rows);
  }, [keyword]);

  return (
    <Box sx={{ position:'relative', width: '100%', height:'100%' }}>
      <Paper elevation={0} sx={{ position:'relative', width: '100%', mb: 2, height:'100%' }}>
        <EnhancedTableToolbar
          keyword={keyword}
          handleKeyword={handleKeywordChange}
          handleDelete={handleDelete}
          handlePrint={handlePrint}
          numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`brand-row-${row.id}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {`${row.vendor.vendor}`}
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="p" component="p"
                          sx={{
                            fontSize:"14px",
                            fontWeight:"500",
                            mb:"2px",
                            textAlign:"left",
                            width:'100%'}}>
                          {moment(row.initial_date).format("YY MMM Do")}
                        </Typography>
                        <Typography variant="p" component="p"
                          sx={{
                            fontSize:"10px",
                            fontWeight:"300",
                            mb:"0px",
                            color:"#777777",
                            textAlign:"center",
                            width:'100%'}}>
                          {moment(row.initial_date).fromNow()}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                      {
                        row.brand.name
                      }
                      </TableCell>
                      <TableCell
                        onClick={handleGetProductInfo(row.id)}
                        align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.product_code}</TableCell>
                      <TableCell align="center">{row.storage_code}</TableCell>
                      <TableCell align="center">{row.size}</TableCell>
                      <TableCell align="center">{row.storage}</TableCell>
                      <TableCell align="center">
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.price))}
                      </TableCell>
                      <TableCell align="center">{row.role}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={'한 번에 보여지는 SKU'}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {isPrint && <LabelPrintComponent rows={allRows} className="page-break" ref={printComponent} />}
      {isPrint && (
        <IconButton
          sx={{
            position:'fixed',
            top:'84px',
            left:'84px',
            zIndex:3,
            color:'#ffffff'
          }}
          onClick={() => {
            handleClose()
          }}>
          <CloseIcon />
        </IconButton>
      )}

    </Box>
  );
}
