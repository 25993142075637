import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

// controller
import DOUserController from '../../apis/dailyorder/DOUserController';

// heartit component
import DOAccountTable from '../../components/tables/DOAccountTable';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';



function DOUserAccountPage(props){

  const params = useParams();
  const [keyword, setKeyword] = useState("");
  const [users, setUsers] = useState([]);

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleSearchClick = (event) => {

  };

  const getUsersSuccess = (response) => {
    if(response.data.code === 200){
      setUsers(response.data.users);
    }
  };

  const getUsersError = (error) => {

  };

  useEffect(() => {
    DOUserController.getUsers({}, getUsersSuccess, getUsersError);
  }, []);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px', maxWidth:'400px', fontSize:'14px', color:'#888888'}}>
        * Alice가 돌면서 보내는 데일리 오더 확인 메일을 구독하는 계정을 관리할 수 있습니다.
      </Typography>
      <Box sx={{width:'100%', textAlign:'left', mb:'8px'}}>
        <Stack
          direction='row'
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>
          <TextField
            id="outlined-search"
            value={keyword}
            onChange={handleKeywordChange}
            label="키워드"
            size="small"
            type="search"
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon size="small" />
              </InputAdornment>
            ),
            }} />
          <Button
            variant="outlined"
            onClick={handleSearchClick}>검색하기</Button>
        </Stack>
      </Box>
      <Box sx={{width:'100%', position:'relative'}}>
        <DOAccountTable
          rows={users}/>
      </Box>
    </Box>
  );
}

export default DOUserAccountPage;
