import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';

const Version = ( props ) => {

   const params = useParams();

   useEffect(() => {
      if(params.__version.toLowerCase() === "v2"){
        props.__versionChange("V2");
      }
   }, []);

   return <h5>Redirecting...</h5>;
};

export default Version;
