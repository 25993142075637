import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';

// controller
import ProductController from '../../apis/products/ProductController';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';



function ProductPage(props){

  const params = useParams();
  const [product, setProduct] = useState({});
  const [productImage, setProductImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);

  const [priceObject ,setPriceObject] = React.useState({});

  const getProductSuccess = (response) => {
    if(response.data.code === 200){
      setProduct(response.data.product);
      setProductImage(response.data.product.images);
      setMaxSteps(response.data.product.images.length);
      setPriceObject(Object.values(response.data.product.prices)[0]);
    }
    setIsLoading(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImageLoad = () => {
    //setIsLoading(false);
  };

  const getProductError = (error) => {
    setIsLoading(false);
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }

  const storageChip = (status) => {
    switch(status){
      case 0 : return (
        <Chip
          color='error'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
      );
      case 1 : return (
        <Chip
          color='success'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
      );
      case 2 : return (
        <Chip
          color='warning'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'부분재고'} />
      );
      default : return (<></>);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setActiveStep(0);
    ProductController.getProduct({id : params.id}, getProductSuccess, getProductError);
  }, []);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Box sx={{width:'100%', textAlign:'left', mb:'8px'}}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{mb:'8px'}}
          spacing={1}>
          <Tooltip title={product.brand}>
            <Chip label={product.brand} size="small" sx={{marginBottom:'4px', fontSize:'14px'}} />
          </Tooltip>
          <Chip label={product.host} size="small" sx={{marginBottom:'4px', fontSize:'14px', backgroundColor:'#222222', color:"#ffffff"}} />
        </Stack>
        <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"12px", color:"#444444"}}>
          {product.sku}
        </Typography>
      </Box>
      <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'4px'}}>
        <a style={{textDecoration:'none', color:'#222222'}} target="_blank" href={product.url}>
        {product.name}
        </a>
      </Typography>
      {(product.godo_goods_no && product.godo_goods_no.length >= 6) &&
        <Box sx={{width:'100%', textAlign:'left'}}>
          <Chip
            label="등록된 상품"
            color="success"
            size="small"
            icon={<CheckIcon size="small"/>}
            />
        </Box>}
      <Box sx={{position:'relative', width:'100%', textAlign:'left', mt:'12px', mb:'8px', maxWidth:'500px'}}>
        <Box
            component="img"
            sx={{
              height: 450,
              display: 'block',
              overflow: 'hidden',
              width: '100%',
              objectFit:'contain'
            }}
            onLoad={handleImageLoad}
            src={productImage.length == 0 ? "" : productImage[activeStep]}
          />
          <Button
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{position:'absolute', width:'32px', height:'100%', top:'0px', left:'0px', bottom:'0px'}}>
            <KeyboardArrowLeft />
          </Button>
          <Button
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{position:'absolute', width:'32px', height:'100%', top:'0px', right:'0px', bottom:'0px'}}>
            <KeyboardArrowRight />
          </Button>
          <Box sx={{position:'absolute', bottom:'12px', left:'50%', transform:'translateX(-50%)'}}>
            <Chip sx={{backgroundColor:'#dddddd'}} icon={<ImageTwoToneIcon size="small" />} size="small" label={`${activeStep+1} / ${maxSteps}`} />
          </Box>
      </Box>
      {isLoading ? <></> : <Box sx={{position:'relative', width:'100%', textAlign:'left', mb:'8px'}}>

        <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#999", mb:'12px'}}>
          <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"16px", marginRight:'6px', color:"#999", textDecoration:'line-through'}}>
          {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.retail.$numberDecimal))}
          </Typography>
          <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"20px", color:"#444", fontWeight:900}}>
          {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.sale.$numberDecimal))}
          </Typography>
        </Typography>

        <Typography variant="p" component="p" sx={{fontSize:"14px", color:"#444", textAlign:"left", mb:"4px"}}>
          등록일 : {getTimestamp(product._id.$oid)}
        </Typography>
        <Typography variant="p" component="p" sx={{fontSize:"14px", color:"#444", textAlign:"left", mb:"8px"}}>
          마지막 업데이트 : {lastTimestamp(product.crawled_at.$date)}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          }}>
          {product.description}
        </Typography>
        <Box sx={{width:'100%', mt:'8px'}}>
          {storageChip(product.is_soldout ? 0 :
          Object.values(product.options).filter((item) => item.is_soldout).length == 0 ?
          1 : 2)
          }
        </Box>
        <Box sx={{width:'100%', position:'relative', maxWidth:'500px', mt:'24px'}}>

          <Typography variant="p" component="p"
            sx={{
              fontSize:"16px",
              color:"#222222",
              fontWeight:900,
              textAlign:"left",
              borderRadius:'24px',
              padding:'8px 16px',
              maxWidth:'500px',
              border:'1px solid #888888',
              mb:"4px"}}>
            옵션 재고 ( 옵션 : {Object.keys(product.options).length} 종 )
          </Typography>
          <List sx={{width:'100%'}}>
            {Object.keys(product.options).map((key) => (
              <ListItem
                key={`option-key-${key}`}
                sx={{paddingLeft:'0px', borderBottom:'1px solid #eeeeee'}}
                secondaryAction={
                  product.options[key].is_soldout ?
                  <Chip
                    color='error'
                    sx={{paddingLeft:"4px", paddingRight:'4px'}}
                    icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
                    :
                  <Chip
                    color='success'
                    sx={{paddingLeft:"4px", paddingRight:'4px'}}
                    icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
                }>
                <ListItemText
                  primary={
                    product.options[key].type.map((value, index) => (
                      <Typography component='p' key={`option-text-${key}-${index}`} sx={{fontSize:'12px', color:'#222222'}}>
                      {value} : {product.options[key].name[index]}
                      </Typography>
                    ))
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>}
    </Box>
  );
}

export default ProductPage;
