import axios from 'axios';
import getToken from '../../utils/getToken'

export default class MemoController{
  static removeMemo(id, _success, _error){
    const token = getToken();
    axios({
      method: "DELETE",
      url:`/api/crawler/${id}/memo`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    }).then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static createMemo(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:`/api/crawler/${data.id}/memo`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data : {
        text : data.text.trim()
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static updateMemo(data, _success, _error){
    const token = getToken();
    axios({
      method: "PATCH",
      url : `/api/crawler/${data.id}/memo`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data : {
        name : data.name.trim(),
        hostname : data.hostname.trim()
      }
    }).then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getMemo(id, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url:`/api/crawler/${id}/memo`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getMemos(data, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url:`/api/crawler/${data.id}/memo`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
}
