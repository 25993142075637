const TopMenuItems = [
  {
    name : '프로필 관리',
    key : "my_setting_profile"
  },
  {
    name : '대시보드',
    key : "my_setting_dashboard"
  },
  {
    name : '로그아웃',
    key : "my_setting_logout"
  }
];

export default TopMenuItems;
