import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// controller
import ProductController from '../../apis/products/ProductController';
import DOProductController from '../../apis/dailyorder/DOProductController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import BrandInput from '../../components/inputs/BrandInput';

// heartit dialog
import ProductColorDialog from '../../components/dialogs/ProductColorDialog';
import ProductSizeDialog from '../../components/dialogs/ProductSizeDialog';

import { NumericFormat } from 'react-number-format';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator=','
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function DOProductAddPage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [memo, setMemo] = useState("");
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [price, setPrice] = React.useState('');
  const [brandList, setBrandList] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [sizes , setSizes] = useState([]);
  const [colors , setColors] = useState([]);

  const [productColorDialogOpen, setProductColorDialogOpen] = useState(false);
  const [productSizeDialogOpen, setProductSizeDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleNameInput = (event) => {
    setName(event.target.value);
  }

  const handleCodeInput = (event) => {
    setCode(event.target.value);
  }

  const handlePriceInput = (event) => {
    setPrice(event.target.value);
  }

  const handleMemoInput = (event) => {
    setMemo(event.target.value);
  }

  const handleBrandChange = (event, newValue) => {
    setBrandList(newValue);
  };

  const handleOpenColorDialog = (event) =>  {
    setProductColorDialogOpen(true);
  }

  const handleProductColorDialogClose = () => {
    setProductColorDialogOpen(false);
  }

  const handleOpenSizeDialog = (event) =>  {
    setProductSizeDialogOpen(true);
  }

  const handleProductSizeDialogClose = () => {
    setProductSizeDialogOpen(false);
  }

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleRegister = (event) => {
    setIsUploading(true);
    if(name.length === 0){
      setIsUploading(false);
      return;
    }
    if(code.length === 0){
      setIsUploading(false);
      return;
    }
    if(memo.length === 0){
      setIsUploading(false);
      return;
    }
    if(isNaN(parseFloat(price))){
      setIsUploading(false);
      return;
    }
    if(brandList === null){
      setIsUploading(false);
      return;
    }

    DOProductController.createProduct({
      name : name,
      code : code,
      description : memo,
      images : previews.map((preview) => ({
        name : preview.title,
        file : preview.file
      })),
      price : price,
      brand : brandList,
      colors : colors,
      sizes : sizes
    }, uploadProductSuccess, uploadProductError);
  }

  const handleProductColorDialogData = (dataString) => {
    let new_color = [...colors];
    const _colors = dataString.split(",");
    _colors.forEach((color) => {
      if (new_color.indexOf(color.trim()) === -1){
        handleSnackBar(`[${color}]가 추가되었습니다`,'success');
        new_color.push(color.trim());
        //setColors([...colors, color.trim()]);
      }else{
        handleSnackBar(`[${color}]가 이미 존재합니다.`,'error');
      }
    });
    setColors(new_color);
    setProductColorDialogOpen(false);
  }

  const handleProductSizeDialogData = (dataString) => {
    let new_size = [...sizes];
    const _sizes = dataString.split(",");
    _sizes.forEach((size) => {
      if (new_size.indexOf(size.trim()) === -1){
        handleSnackBar(`[${size}]가 추가되었습니다`,'success');
        new_size.push(size.trim());
      }else{
        handleSnackBar(`[${size}]가 이미 존재합니다.`,'error');
      }
    });
    setSizes(new_size);
    setProductSizeDialogOpen(false);
  }

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleIntialize = (event) => {
    setMemo("");
    setColors([]);
    setSizes([]);
    setName("");
    setBrandList(null);
    setCode("");
    setPrice('');
  }

  const handleDeleteColor = (color) => (event) => {
    let _colors = [...colors];
    _colors.splice(_colors.indexOf(color.trim()), 1);
    setColors(_colors);
  };

  const handleDeleteSize = (size) => (event) => {
    let _sizes = [...sizes];
    _sizes.splice(_sizes.indexOf(size.trim()), 1);
    setSizes(_sizes);
  };

  const uploadProductSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      handleSnackBar("성공적으로 상품이 등록되었습니다", "success");
      navigate("/do/product/list");
    }
    setIsUploading(false);
  }

  const uploadProductError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  useEffect(() => {
    console.log(previews);
  }, [previews]);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        <Badge color="error" variant="dot">
          상품 명
        </Badge>
      </Typography>
      <TextField
        id="do-product-name"
        hiddenLabel
        value={name}
        onChange={handleNameInput}
        placeholder="상품명"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
        size="small"
        variant="outlined"/>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        대표 이미지
      </Typography>
      <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
        <ImageUploader
          value={images}
          onChange={handleImageChange}
          sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
          {previews.length > 0 && (
            <>
              <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                업로드 이미지 수 : {previews.length}
              </Typography>
              <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                {previews.map((item) => (
                  <ImageListItem key={item.key}>
                    <img
                      src={item.url}
                      srcSet={item.url}
                      alt={item.title}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </>
          )}
      </Box>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        <Badge color="error" variant="dot">
          상품 브랜드
        </Badge>
      </Typography>
      <BrandInput
        sx={{width:'100%', maxWidth:'400px',mb:'24px'}}
        value={brandList} onChange={handleBrandChange}
        multiple={false}/>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        <Badge color="error" variant="dot">
          상품 코드
        </Badge>
      </Typography>
      <TextField
        id="do-product-code"
        hiddenLabel
        value={code}
        disabled={isUploading}
        onChange={handleCodeInput}
        placeholder="상품코드"
        InputLabelProps={{
          shrink: true,
        }}
        sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
        size="small"
        variant="outlined"/>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        <Badge color="error" variant="dot">
          목표 가격
        </Badge>
      </Typography>
      <TextField
        id="do-product-code"
        hiddenLabel
        placeholder="가격"
        disabled={isUploading}
        name="numberformat"
        value={price}
        onChange={handlePriceInput}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">원</InputAdornment>,
          inputComponent: NumberFormatCustom,
        }}
        sx={{width:'100%', maxWidth:'400px', mb:'12px'}}
        size="small"
        variant="outlined"/>
      <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
        <Typography
          variant="h6"
          component="p"
          sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
          <Badge color="error" variant="dot">
            컬러 옵션
          </Badge>
        </Typography>
        <IconButton
          onClick={handleOpenColorDialog}
          disabled={productColorDialogOpen}
          sx={{position:'absolute', right:'8px', top:'0px'}}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
      {colors.length === 0 ? (
        <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
          단일 색상
        </Typography>
      ):(
        colors.map((color) => (
          <Box key={`color-list-${color}`} sx={{position:'relative', width:'100%'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
              {color}
            </Typography>
            <IconButton
              onClick={handleDeleteColor(color)}
              sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
              <DeleteForeverTwoToneIcon fontSize="small" />
            </IconButton>
          </Box>
        ))
      )}
      </Box>
      <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
        <Typography
          variant="h6"
          component="p"
          sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
          <Badge color="error" variant="dot">
            사이즈 옵션
          </Badge>
        </Typography>
        <IconButton
          onClick={handleOpenSizeDialog}
          disabled={productSizeDialogOpen}
          sx={{position:'absolute', right:'8px', top:'0px'}}>
          <AddIcon />
        </IconButton>
      </Box>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
      {sizes.length === 0 ? (
        <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
          단일 사이즈
        </Typography>
      ):(
        sizes.map((size) => (
          <Box key={`size-list-${size}`} sx={{position:'relative', width:'100%'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
              {size}
            </Typography>
            <IconButton
              onClick={handleDeleteSize(size)}
              sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
              <DeleteForeverTwoToneIcon fontSize="small" />
            </IconButton>
          </Box>
        ))
      )}
      </Box>
      <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
        추가 사항
      </Typography>
      <TextField
          id="standard-multiline-static"
          hiddenLabel
          disabled={isUploading}
          sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
          placeholder="기타 기입할 사항을 입력해주세요"
          multiline
          value={memo}
          onChange={handleMemoInput}
          InputLabelProps={{
            shrink: true,
          }}
          rows={4}
          variant="outlined"
        />
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{width:'100%'}}
          spacing={2}>
          <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">등록 취소</Button>
          <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
          <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">등록하기</Button>
        </Stack>
      </Box>
      <ProductColorDialog
        open={productColorDialogOpen}
        onReceive={handleProductColorDialogData}
        onClose={handleProductColorDialogClose}/>
      <ProductSizeDialog
        open={productSizeDialogOpen}
        onReceive={handleProductSizeDialogData}
        onClose={handleProductSizeDialogClose}/>
    </Box>
  );
}

export default DOProductAddPage;
