import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOProductController{
  static createVendor(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/vendor",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static getProductInfo(data, _success, _error){
    const token = getToken();

    axios({
      method : "GET",
      url : `/api/daily/product/item/${data.key}`,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    })
  }
  static getProductMatch(data, _success, _error){
    const token = getToken();

    axios({
      method : "GET",
      url : `/api/daily/product/match/${data.key}?min=${data.min}`,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    })
  }
  static parseFile(data, _success, _error){
    const token = getToken();
    let formData = new FormData();
    data.files.forEach((file) => {
        formData.append('files', file.file);
    });
    axios({
      method : "POST",
      url : '/api/daily/product/excel',
      data : formData,
      headers : {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static createProductExcel(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/products/add/excel",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static createProduct(data, _success, _error){
    const token = getToken();
    let formData = new FormData();
    formData.append('name', data.name);
    formData.append('brand', data.brand.id);
    formData.append('code', data.code);
    formData.append('description', data.description);
    formData.append('price', data.price);
    formData.append('color', data.colors.join(",") );
    formData.append('size', data.sizes.join(",") );
    data.images.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:"/api/daily/product/add",
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static updateProduct(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    const platform = data.platform.map((item) => ({
      id : item.id,
      added : item.added,
      host : item.name.trim(),
      link : item.url.trim()
    }));
    formData.append('key', data.key);
    formData.append('name', data.name);
    formData.append('brand', data.brand.id);
    formData.append('code', data.code);
    formData.append('price', data.price);
    formData.append('description', data.description);
    formData.append('options', JSON.stringify(data.options));
    formData.append('type', data.type);
    formData.append('platforms', JSON.stringify(platform));
    formData.append('prev_images', JSON.stringify(data.images.origin));
    console.log(data);
    data.images.update.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:"/api/daily/product/update",
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static deleteProduct(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/daily/product/delete",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getProduct(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/products",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getPriceList(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/price",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getPriceStatisticsList(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/price/statistics",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
