import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOAgentController{
  static createVendor(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/vendor",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static getVendors(data, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url:"/api/vendor",
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static updateVendor(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/vendor",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static deleteVendor(data, _success, _error){
    const token = getToken();
    axios({
      method: "DELETE",
      url:"/api/vendor",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
}
