import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

import BrandInput from '../inputs/BrandInput';
import FileSelectorInput from '../inputs/FileSelectorInput';
// heartit controller


import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DOFilteringDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading]  = useState(false);
  const [brands, setBrands] = useState([]);
  const [files, setFiles] = useState([]);
  const [profit, setProfit] = useState(-20);
  const [error, setError] = useState({
    brands : false,
    files : false,
  })

  const handleBrandChange = (event, newValue) => {
    setError({ ...error, brands : false });
    setBrands(newValue);
  }

  const handleFileChange = (event, newValue) => {
    setError({ ...error, files : false });
    setFiles(newValue);
  }

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleProfit = (event, newValue) => {
    console.log(newValue);
    setProfit(newValue);
  }

  const handleProfitInput = (event) => {
    setProfit(event.target.value);
  }

  const handleSubmit = (event) => {
    setIsLoading(true);
    filterApply();
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const createSuccess = (success) => {
    setIsLoading(false);
    props.onClose();
  }

  const createFailed = (error) => {
    setIsLoading(false);

  }


  const filterApply = () => {
    props.onUpdate({
      brands : brands,
      profit : profit,
      files : files
    });
    createSuccess("");
  }

  useEffect(() => {

  });

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         필터링 옵션
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
          <Typography
            sx={{
                textAlign: 'left',
                marginBottom:'2px',
                fontSize:'14px',
                fontWeight:'900',
                color:'#222222'
            }}
            variant="p"
            component="p">
            브랜드 선택
          </Typography>
          <Box sx={{width:'100%', height:'48px'}}>
            <BrandInput
              multiple={true}
              value={brands}
              onChange={handleBrandChange}/>
          </Box>
          <Typography
            sx={{
                textAlign: 'left',
                marginBottom:'2px',
                fontSize:'14px',
                fontWeight:'900',
                color:'#222222'
            }}
            variant="p"
            component="p">
            최소 수익률
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width:'100%'}}
            spacing={2}>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              value={profit}
              onChange={handleProfitInput}
              sx={{width:'108px'}}
              type="number"
              size="small"
            />
            <Slider
              aria-label="profit-input"
              value={profit}
              onChange={handleProfit}
              valueLabelDisplay="auto"
              min={-20}
              max={50}/>
            </Stack>
            <Typography
              sx={{
                  textAlign: 'left',
                  marginBottom:'2px',
                  fontSize:'14px',
                  fontWeight:'900',
                  color:'#222222'
              }}
              variant="p"
              component="p">
              파일 선택
            </Typography>
            <Box sx={{width:'100%', height:'48px'}}>
              <FileSelectorInput
                value={files}
                onChange={handleFileChange}/>
            </Box>
         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>적용하기</Button>
       </DialogActions>
     </Dialog>
  )
};
