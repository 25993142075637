import React, { useEffect, useState, Component } from 'react';

import '../../../node_modules/react-vis/dist/style.css';
import Box from '@mui/material/Box';

import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  AreaSeries,
  GradientDefs,
  Borders,
  Crosshair
} from 'react-vis';

export default function MatchPriceChart(props){

  const [data, setData] = useState([]);
  const [reference, setReference] = useState([]);
  const [myValues, setMyValues] = useState([]);

  useEffect(() => {

    if(Object.keys(props.data).length > 0){

      const refer = [];
      const newData = Object.keys(props.data).map((date, idx) => {

        refer.push({
          x : idx,
          y : props.reference / 1000
        });
        let max = (props.data[date].max).toFixed(0) / 1000;
        let min = (props.data[date].min).toFixed(0) / 1000;
        if(max === min){
          max += 1;
        }
        return {
          x : idx,
          y : max,
          y0 : min,
        }
      });

      if(newData.length === 1){
        newData.push({
          x : refer.length,
          y : newData[0].y,
          y0 : newData[0].y0
        });
        refer.push({
          x : refer.length,
          y : props.reference / 1000
        })
      }

      setReference(refer);
      setData(newData);

    }
  }, [props.data]);

  const _onNearestX = (value, {index}) => {
    setMyValues([data[index]])
  };

  return (
    <Box sx={{
      width:'100%',
      position:'relative',
      ...props.sx
    }}>
      <XYPlot height={300} width={400}>

        <Crosshair values={myValues}>
          <div style={{background: 'rgba(0,0,0,.7)', borderRadius:'8px', padding:'8px 12px'}}>
            <h3>{myValues.length > 0 ? props.label[myValues[0].x] : ""}</h3>
            <p>최고가 : {myValues.length > 0 ? (myValues[0].y * 1000).toFixed(0) : 0} 원</p>
            <p>최저가 : {myValues.length > 0 ? (myValues[0].y0 * 1000).toFixed(0) : 0} 원</p>
          </div>
        </Crosshair>
        <GradientDefs>
          <linearGradient id="CoolGradient" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="red" stopOpacity={0.4} />
            <stop offset="100%" stopColor="blue" stopOpacity={0.3} />
          </linearGradient>
        </GradientDefs>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickFormat={v => `${props.label[v]}`} tickTotal={props.label.length} tickLabelAngle={-90} title="일자" />
        <YAxis title="가격(천원)"/>
        <AreaSeries
          onNearestX={_onNearestX}
          color={'url(#CoolGradient)'}
          data={data}
        />
        <LineSeries data={reference} />
      </XYPlot>
    </Box>
  );
}
