import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

export default function DOParameterInput(props) {

  const [value, setValue] = React.useState([80, 100]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', padding:'0px 12px' }}>
      <Slider
        getAriaLabel={() => 'Parameter range'}
        value={props.value}
        step={1}
        min={80}
        max={100}
        size="small"
        onChange={props.onChange}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}
