import axios from 'axios';
import getToken from '../../utils/getToken'

export default class StorageController{
  static parseFile(data, _success, _error){
    const token = getToken();
    let formData = new FormData();
    data.files.forEach((file) => {
        formData.append('files', file.file);
    });
    axios({
      method : "POST",
      url : '/api/storage/product/excel',
      data : formData,
      headers : {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getInStockProduct(data, _success, _error){
    const token = getToken();

    axios({
      method : "POST",
      url : '/api/storage/product/stock',
      data : data,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static createProductStockExcel(data, _success, _error){
    const token = getToken();

    axios({
      method : "POST",
      url : '/api/storage/product/stock/file',
      data : data,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static deleteProductStock(data, _success, _error){
    const token = getToken();

    axios({
      method : "POST",
      url : '/api/storage/product/stock/delete',
      data : data,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
}
