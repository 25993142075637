import React, {useEffect, useState, useRef} from 'react';

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import IconButton from '@mui/material/IconButton';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';

// icon list
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import axios from "axios";

// color set
import { blue } from '@mui/material/colors';

// heartit components
import BrandInput from '../../components/inputs/BrandInput';
import HostInput from '../../components/inputs/HostInput';
import SoldoutCheckBox from '../../components/inputs/SoldoutCheckBox';
import ViewNumberSelector from '../../components/inputs/ViewNumberSelector';
import ViewTypeSelector from '../../components/inputs/ViewTypeSelector';

import CardBasketView from '../../components/products/CardBasketView';
import CardLoadingView from '../../components/products/CardLoadingView';
import CardView from '../../components/products/CardView';
import ListView from '../../components/products/ListView';

// dialog
import ProductUploadDialog from '../../components/dialogs/ProductUploadDialog';
import ProductImageDialog from '../../components/dialogs/ProductImageDialog';
import ProductStorageDialog from '../../components/dialogs/ProductStorageDialog';

// heartit controller
import ProductController from '../../apis/products/ProductController';

// alert
import { SnackbarProvider, useSnackbar } from 'notistack';

import debounce from 'lodash/debounce';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// delivery = 0 : 국내배송 ; 1 : 해외배송
// storage.status = 0 : 품절, 1 : 재고 있음, 2 : 부분 재고

function StorageListPage(props){

  const { enqueueSnackbar } = useSnackbar();

  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });

  

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative' }}>
      <Grid container spacing={3} sx={{height:"100%", position:'relative'}}>

      </Grid>
    </Box>
  );
}

export default StorageListPage;
