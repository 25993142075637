import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import 'moment/locale/ko';

export default function DateInput(props){

  const handleChange = (newValue) => {
    props.onChange(newValue);
  };

  const setToday = (event) => {
    props.onChange(moment());
  }

  return (
    <Stack
      sx={{width:'100%'}}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobileDatePicker
            label="일자 선택"
            inputFormat="YYYY-MM-DD"
            value={props.value}
            onChange={handleChange}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
      </LocalizationProvider>
      <Button onClick={setToday} variant="outlined">오늘</Button>
    </Stack>
  );
}
