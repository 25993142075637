import React, {useEffect, useState, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import IconButton from '@mui/material/IconButton';

import Drawer from '@mui/material/Drawer';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';

// icon list
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import axios from "axios";

// color set
import { blue } from '@mui/material/colors';

// heartit components
import BrandInput from '../../components/inputs/BrandInput';
import HostInput from '../../components/inputs/HostInput';
import SoldoutCheckBox from '../../components/inputs/SoldoutCheckBox';
import ViewNumberSelector from '../../components/inputs/ViewNumberSelector';
import ViewTypeSelector from '../../components/inputs/ViewTypeSelector';

import CardBasketView from '../../components/products/CardBasketView';
import CardLoadingView from '../../components/products/CardLoadingView';
import CardView from '../../components/products/CardView';
import ListView from '../../components/products/ListView';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// dialog
import ProductUploadDialog from '../../components/dialogs/ProductUploadDialog';
import ProductImageDialog from '../../components/dialogs/ProductImageDialog';
import ProductStorageDialog from '../../components/dialogs/ProductStorageDialog';

import DOStorageInTable from '../../components/tables/DOStorageInTable';

// heartit controller
import StorageController from '../../apis/storage/StorageController';

// alert
import { SnackbarProvider, useSnackbar } from 'notistack';

import debounce from 'lodash/debounce';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

const drawerWidth = 252;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// delivery = 0 : 국내배송 ; 1 : 해외배송
// storage.status = 0 : 품절, 1 : 재고 있음, 2 : 부분 재고

function StorageInPage(props){

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isWorking, setIsWorking] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const openExcelHandlePage = (event) => {
    navigate('/storage/instock/file');
  }

  const openStorageHandlePage = (event) => {
    navigate('/storage/instock/product');
  }

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getStockProductsSuccess = (response) => {
    if(response.status === 200){
      if(response.data.code === 200){
          const data = response.data.products;
          setProducts((prev) => ( data.map(el => ({
            id : el.key,
            name : el.name,
            product_code : el.product_code,
            storage_code : el.storage_code,
            price : el.price,
            initial_date : el.initial_date,
            size: el.size,
            storage : el.storage,
            role : el.sell_role,
            brand : el.brand,
            vendor : el.vendor
          })) ));
      }
    }
  }

  const deleteProductsSuccess = (response) => {
    console.log(response);
    handleSnackBar("성공적으로 삭제되었습니다", "success");

    StorageController.getInStockProduct({}, getStockProductsSuccess, getStockProductsError);
  }

  const deleteProductsError = (error) => {
    console.log(error);
    handleSnackBar("문제가 발생해서 삭제되지 않았습니다", "error");

    StorageController.getInStockProduct({}, getStockProductsSuccess, getStockProductsError);
  }

  const getStockProductsError = (error) => {
    console.log(error);
    handleSnackBar("목록을 불러오는데에 문제가 발생했습니다 :(", "error");
  }

  const handleDeleteProducts = (keys) => {
    console.log(keys);
    StorageController.deleteProductStock( {products : keys},
      deleteProductsSuccess,
      deleteProductsError);
  }

  const handleGetProductInfo = (id) => {
    console.log(id);
    setOpen(true);
  }

  useEffect(() => {
    StorageController.getInStockProduct({}, getStockProductsSuccess, getStockProductsError);
  }, []);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{position:{md : 'absolute', sm:'relative'}, zIndex:1, top:'24px', right:'32px',mb:'12px'}}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Button onClick={openStorageHandlePage} variant="outlined">직접추가</Button>
          <Button onClick={openExcelHandlePage} variant="outlined">파일추가</Button>
        </Stack>
      </Box>
      <Box sx={{ position:'relative', left:{xs : '0px', md:'0px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}>
        <Backdrop
          sx={{
              position:'absolute',
              top:'0px',
              left:'16px',
              right:'0px',
              bottom:'0px',
              width:'100%',
              height:'100%',
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isWorking}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box sx={{width:'100%', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"24px",
              fontWeight:"900",
              mb:"24px",
              textAlign:"left",
              width:'100%'}}>
            입고 상품 관리
          </Typography>
          <DOStorageInTable
            onNavigate={handleGetProductInfo}
            onDelete={handleDeleteProducts}
            rows={products}/>
        </Box>
      </Box>


      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{width:'100%', backgroundColor:'#eeeeee', padding:'16px'}}>

        </Box>
      </Drawer>
    </Box>
  );
}

export default StorageInPage;
