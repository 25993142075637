import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PercentTwoToneIcon from '@mui/icons-material/PercentTwoTone';

// controller
import ProductController from '../../apis/products/ProductController';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';



function VariableEditPage(props){

  const params = useParams();
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);

  const getBrandsSuccess = (response) => {
    if(response.data.code === 200){
      setBrands(response.data.brands);
    }
  };

  const getBrandsError = (error) => {

  };

  useEffect(() => {
    ProductController.getBrandsDetail({}, getBrandsSuccess, getBrandsError);
  }, []);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, boxSizing:'border-box', overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" component="p" sx={{textAlign:"left", mb:'16px', fontWeight:900}}>
        환경 변수 관리
      </Typography>
      <Box
        sx={{width:'100%', boxSizing:'border-box', maxWidth:'480px'}}>

        <Typography variant="p" component="p" sx={{textAlign:"left", mb:'16px', fontWeight:600}}>
          플랫폼 변수
        </Typography>

        <Accordion
          sx={{width:'100%'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>KREAM</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="수수료율" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수수료율"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Soldout</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="soldout-commision" primary="수수료율" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수수료율"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{width:'100%', mb:'24px'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>StockX</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="stockX-commision" primary="수수료율" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수수료율"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Typography variant="p" component="p" sx={{textAlign:"left", mb:'16px', fontWeight:600}}>
          기타 변수 설정
        </Typography>

        <Accordion
          sx={{width:'100%'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>수익률 설정</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="최저 수익률 1단계" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수익률"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="최저 수익률 2단계" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수익률"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="최저 수익률 3단계" />
                <Stack>
                  <TextField
                    id="outlined-number"
                    size="small"
                    label="수익률"
                    type="number"
                    sx={{width:'102px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{width:'100%'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Crawler 사용 노드</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List
              sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="HEARTIT-SUPER-SERVER node1 k1X34a" />
                <Stack>
                  <Switch
                    inputProps= {{ 'aria-label': 'Switch demo' }}
                    defaultChecked />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="HEARTIT-SUPER-SERVER node2 7QVSiw" />
                <Stack>
                  <Switch
                    inputProps= {{ 'aria-label': 'Switch demo' }}
                    defaultChecked />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PercentTwoToneIcon />
                </ListItemIcon>
                <ListItemText id="switch-list-label-wifi" primary="HEARTIT-AWS-SERVER node 9ackS2" />
                <Stack>
                  <Switch
                    inputProps= {{ 'aria-label': 'Switch demo' }}
                    defaultChecked />
                </Stack>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

      </Box>
    </Box>
  );
}

export default VariableEditPage;
