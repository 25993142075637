import React, { useRef, useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";


const styles = {
  back : {
    position : 'fixed',
    top:'0px',
    left:'0px',
    right:'0px',
    bottom:'0px',
    backgroundColor:'#777777',
    width:'100%',
    height:'100%',
    paddingTop:'84px',
    paddingBottom:'84px',
    overflow:'scroll',
    boxSizing:'border-box',
    zIndex:2
  },
  page : {
    width:'50%',
    minWidth:'480px',
    margin : '0 auto',
    position:'relative',
    boxSizing:'border-box'
  },
  page_in : {
    backgroundColor:'#ffffff',
    width:'100%',
    paddingBottom:'141.4%',
    position:'absolute',
    boxSizing:'border-box',
    marginBottom:'24px'
  },
  page_box : {
    width : '100%',
    height: '100%',
    position : 'absolute',
    boxSizing:'border-box',
  },
  container : {
    display : 'grid',
    boxSizing:'border-box',
    marginBottom:'12px',
    backgroundColor:'#ffffff',
    top:0,
    left:0,
    right:0,
    bottom:0,
    width : '100%',
    height: '100%',
    position : 'relative',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(7, 1fr)',
  },
  box : {
    padding:'8px 10px',
    position:'relative',
    display:'block',
    minWidth: 0,
    boxSizing:'border-box'
  },
  name_title:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight:1,
    fontSize:'16px',
    fontWeight:'900',
    maxWidth: '100%',
    display:'block',
    marginBottom:'10px'
  },
  name_code:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize:'14px',
    lineHeight:1,
    marginBottom:'2px',
    marginTop:'2px',
    maxWidth: '100%',
    color:'#444444'
  },
  pr_code:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin:'0px',
    lineHeight:1,
    marginTop:'0px',
    fontSize:'14px',
    maxWidth: '100%',
    color:'#444444'
  },
  content : {
    width:'100%',
    position:'relative',
    display:'inline-block',
    boxSizing:'border-box'
  },
  qr:{
    width:'30%',
    height:'30%',
    display:'inline-block',
    boxSizing:'border-box'
  },
  content_text :{
    width:'calc(70% - 2px)',
    display:'inline-block',
    paddingLeft:'6px',
    maxWidth: '100%',
    boxSizing:'border-box'
  },
  content_size :{
    position:'absolute',
    bottom:'12px',
    right:'12px',
    fontSize:'16px',
    fontWeight:'900'
  }
};

const Col = styled.div`
  display:none;

  @page {
    // size: auto
  }

  @media all {
    @page {
      display: none;
    }
  }

  @media print {
    @page {
      size: A4
    }
    .page-break {
      page-break-after: always;
      //display: block;
    }
  }
`;

const division = (arr, n) => {
  const length = arr.length;
  const divide = Math.floor(length / n) + (Math.floor( length % n ) > 0 ? 1 : 0);
  const newArray = [];

  for (let i = 0; i <= divide; i++) {
    // 배열 0부터 n개씩 잘라 새 배열에 넣기
    newArray.push(arr.splice(0, n));
  }

  return newArray;
}

function LabelPrintComponent(props, ref) {
  const { products, className } = props;
  const [ allLabels, setallLabels ] = useState([props.rows]);


  useEffect(() => {
      let arr = [];
      //console.log(props.rows);
      props.rows.map((el) => {
        //console.log(el);
        const storage = parseInt(el.storage);
        for( let i = 0 ; i < storage ; i++){
          arr.push(el);
        }
      });
      console.log(arr);
      const n_arr = division(arr, 21);

      setallLabels((prev) => (n_arr));
  }, [props.rows]);

  return (
    <div style={styles.back}>
      <div style={styles.page}>
        <div style={styles.page_in}>
          <div style={styles.page_box} ref={ref}>
            {allLabels.map(labels => (
              <Col className={'page-break'} style={styles.container}>
                {labels.map((el, index) => (
                  <div style={styles.box}>
                    <p style={styles.name_title}>{el.name}</p>
                    <div style={styles.content}>
                      <div style={styles.qr}>
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          viewBox={`0 0 256 256`}
                          value={el.id} />
                      </div>
                      <div style={styles.content_text}>
                        <p style={styles.name_code}>{el.brand.name}</p>
                        <p style={styles.name_code}>{el.product_code}</p>
                        <p style={styles.pr_code}>{el.storage_code}</p>
                      </div>
                    </div>
                    <span style={styles.content_size}>{el.size}</span>
                  </div>
                ))}
              </Col>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(LabelPrintComponent);
