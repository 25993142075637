import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';


import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ProductOptionDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading]  = useState(false);
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [error, setError] = useState({
    color : false,
    size : false
  })

  const handleColorInput = (event) => {
    setError({ ...error, color : false });
    setColor(event.target.value);
  }

  const handleSizeInput = (event) => {
    setError({ ...error, size : false });
    setSize(event.target.value);
  }

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleSubmit = (event) => {
    setIsLoading(true);
    let isValid = true;
    let _error = { color : false, size : false };

    if(color.length <= 0){
      _error.color = true;
      isValid = false;
    }

    if(size.length <= 0){
      _error.size = true;
      isValid = false;
    }

    if(!isValid){
      setError(_error);
      setIsLoading(false);
      return;
    }
    createColor();
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };


  const createColor = () => {
    props.onReceive(color, size);

    setError({ ...error, color : false, size : false });
    props.onClose();
    setColor("");
    setSize("");
    setIsLoading(false);
  }

  useEffect(() => {
    setError({ ...error, color : false, size : false });
  }, [props.open]);

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         상품 옵션 추가
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
            <Box sx={{width:'100%', height:'48px'}}>
             <TextField
               id="user-add-dialog-email-input"
               disabled={isLoading}
               error={error.color}
               sx={{minWidth:'80%'}}
               value={color}
               onKeyDown={handleKeyPress}
               onChange={handleColorInput}
               label="컬러 코드"
               placeholder="컬러 코드는 ,로 구분됩니다"
               helperText={error.color ? "컬러 코드 형식이 잘못되었습니다" : "" }
               size="small"
               type="text"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
            <Box sx={{width:'100%', height:'48px'}}>
             <TextField
               id="user-add-dialog-email-input"
               disabled={isLoading}
               error={error.size}
               sx={{minWidth:'80%'}}
               value={size}
               onKeyDown={handleKeyPress}
               onChange={handleSizeInput}
               label="사이즈"
               placeholder="사이즈는 ,로 구분됩니다"
               helperText={error.color ? "사이즈 형식이 잘못되었습니다" : "" }
               size="small"
               type="text"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>추가하기</Button>
       </DialogActions>
     </Dialog>
  )
};
