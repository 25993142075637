import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';


// view element
import DOBrandTable from '../../components/tables/DOBrandTable';
// controller
import ProductController from '../../apis/products/ProductController';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';



function BrandManagePage(props){

  const params = useParams();
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);

  const handleAddBrand = (event) => {
    navigate("/setting/brands/add");
  }

  const getBrandsSuccess = (response) => {
    if(response.data.code === 200){
      setBrands(response.data.brands);
    }
  };

  const getBrandsError = (error) => {

  };

  useEffect(() => {
    ProductController.getBrandsDetail({}, getBrandsSuccess, getBrandsError);
  }, []);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, boxSizing:'border-box', overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Box sx={{position:{md : 'absolute'}, zIndex:1, top:'24px', right:'32px',mb:'12px'}}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Button variant="outlined" color="primary" onClick={handleAddBrand}>
            브랜드 추가하기
          </Button>
        </Stack>
      </Box>
      <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'16px', ml:'12px', fontWeight:900}}>
        브랜드 관리
      </Typography>
      <DOBrandTable
        rows={brands}/>
    </Box>
  );
}

export default BrandManagePage;
