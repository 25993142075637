import React, {useEffect, useState} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Typography from '@mui/material/Typography';

import moment from 'moment';
import 'moment/locale/ko'
moment.locale('ko');

export default function MemoView(props) {

  const [memos, setMemos] = useState([]);

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
    {(props.memo.length > 0) && props.memo.map((memo, index) => (
      <TimelineItem key={`memo-view-list-${memo.id}`}>
        <TimelineSeparator>
          <TimelineDot />
          {index < (props.memo.length - 1) && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="h6" component="span" sx={{ color:'#555555', fontSize:'12px'}}>
            {moment(memo.updated_time).fromNow()}
          </Typography>
          <Typography sx={{whiteSpace: 'pre-line', fontSize:'16px'}}>{memo.memo}</Typography>
        </TimelineContent>
      </TimelineItem>
    ))}
    </Timeline>
  );
}
