import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import LensIcon from '@mui/icons-material/Lens';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// controller
import DOBrandController from '../../apis/dailyorder/DOBrandController';
import ToolController from '../../apis/tools/ToolController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import BrandInput from '../../components/inputs/BrandInput';
import BrandAliasDialog from '../../components/dialogs/BrandAliasDialog';
import BrandColorDialog from '../../components/dialogs/BrandColorDialog';

import { NumericFormat } from 'react-number-format';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator=','
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function BrandNewpage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [memo, setMemo] = useState("");
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [alias, setAlias] = useState([]);
  const [colors, setColors] = useState([]);

  const [brandAliasDialogOpen, setBrandAliasDialogOpen] = useState(false);
  const [brandColorDialogOpen, setBrandColorDialogOpen] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleNameInput = (event) => {
    setName(event.target.value);
  }

  const handleMemoInput = (event) => {
    setMemo(event.target.value);
  }

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleRegister = (event) => {
    setIsUploading(true);
    if(name.length === 0){
      setIsUploading(false);
      return;
    }
    /*if(memo.length === 0){
      setIsUploading(false);
      return;
    }*/

    DOBrandController.createBrand({
      name : name,
      description : memo,
      images : previews.map((preview) => ({
        name : preview.title,
        file : preview.file
      })),
      alias : alias.map((ali) => ({
        value : ali,
        description : ""
      })),
      colors : colors.map((color) => ({
        code : color.code,
        description : color.tags,
        hex : color.hex
      }))
    }, uploadBrandSuccess, uploadBrandError);
  }

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleIntialize = (event) => {
    setMemo("");
    setName("");
    setAlias([]);
    setColors([]);
  }

  const handleOpenBrandAliasDialog = (event) =>  {
    setBrandAliasDialogOpen(true);
  }

  const handleBrandAliasDialogClose = () => {
    setBrandAliasDialogOpen(false);
  }

  const handleOpenBrandColorDialog = (event) =>  {
    setBrandColorDialogOpen(true);
  }

  const handleBrandColorDialogClose = () => {
    setBrandColorDialogOpen(false);
  }

  const handleDeleteBrand = (brand) => (event) => {
    let _brands = [...alias];
    _brands.splice(_brands.indexOf(brand.trim()), 1);
    setAlias(_brands);
  };

  const handleDeleteBrandColor = (color) => (event) => {
    let _colors = [...colors];
    const searched = colors.findIndex((el) => el.code === color.code);
    if(searched >= 0){
      _colors.splice(searched, 1);
      setColors(_colors);
    }
  }

  const handleBrandAliasDialogData = (brand) => {
    if (alias.indexOf(brand.trim()) === -1){
      handleSnackBar(`[${brand}]가 추가되었습니다`,'success');
      setAlias([...alias, brand.trim()]);
    }else{
      handleSnackBar(`[${brand}]가 이미 존재합니다.`,'error');
    }

    setBrandAliasDialogOpen(false);
  }

  const handleBrandColorDialogData = (colorData) => {
    if(colors.find((el) => el.code === colorData.code)){
      handleSnackBar(`[${colorData.code}]가 이미 존재합니다.`,'error');
    }else{
      handleSnackBar(`[${colorData.code}]가 추가되었습니다`,'success');
      setColors([...colors, colorData]);
    }
    setBrandColorDialogOpen(false);
  }

  const uploadBrandSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      handleSnackBar("성공적으로 브랜드가 추가되었습니다", "success");
      navigate("/setting/brands");
    }
    setIsUploading(false);
  }

  const uploadBrandError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  useEffect(() => {
    console.log(previews);


    /*ToolController.uploadStaticImage({
      images : previews.map((preview) => ({
        name : preview.title,
        file : preview.file
      })),
    }, (ss) => {
      console.log(ss);
    }, (e) => {
      console.log(e);
    });*/

  }, [previews]);

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
    <Grid container spacing={2}>
      <Grid item xs={8} md={6}>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          <Badge color="error" variant="dot">
            브랜드 명
          </Badge>
        </Typography>
        <TextField
          id="do-brand-name"
          hiddenLabel
          value={name}
          onChange={handleNameInput}
          placeholder="브랜드 명"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
          size="small"
          variant="outlined"/>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          로고 이미지
        </Typography>
        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <ImageUploader
            value={images}
            onChange={handleImageChange}
            sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
            {previews.length > 0 && (
              <>
                <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                  업로드 이미지 수 : {previews.length}
                </Typography>
                <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                  {previews.map((item) => (
                    <ImageListItem key={item.key}>
                      <img
                        src={item.url}
                        srcSet={item.url}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </>
            )}
        </Box>
        <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
          <Typography
            variant="h6"
            component="p"
            sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
            <Badge color="error" variant="dot">
              동일 브랜드 명
            </Badge>
          </Typography>
          <IconButton
            onClick={handleOpenBrandAliasDialog}
            disabled={brandAliasDialogOpen}
            sx={{position:'absolute', right:'8px', top:'0px'}}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        {alias.length === 0 ? (
          <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
            등록된 동일 브랜드 명이 없습니다
          </Typography>
        ):(
          alias.map((ali) => (
            <Box key={`alias-list-${ali}`} sx={{position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
                {ali}
              </Typography>
              <IconButton
                onClick={handleDeleteBrand(ali)}
                sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
                <DeleteForeverTwoToneIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        )}
        </Box>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          추가 사항
        </Typography>
        <TextField
            id="standard-multiline-static"
            hiddenLabel
            disabled={isUploading}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            placeholder="기타 기입할 사항을 입력해주세요"
            multiline
            value={memo}
            onChange={handleMemoInput}
            InputLabelProps={{
              shrink: true,
            }}
            rows={4}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={8} md={6}>

        <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
          <Typography
            variant="h6"
            component="p"
            sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, paddingTop:'8px'}}>
            <Badge color="error" variant="dot">
              컬러 코드
            </Badge>
          </Typography>
          <IconButton
            onClick={handleOpenBrandColorDialog}
            disabled={brandColorDialogOpen}
            sx={{position:'absolute', right:'8px', top:'0px'}}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        {colors.length === 0 ? (
          <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
            등록된 컬러 코드가 없습니다.
          </Typography>
        ):(
          colors.map((color) => (
            <Box key={`color-list-${color.code}`} sx={{position:'relative', width:'100%', mb:'12px'}}>
              <Stack
                sx={{width:'calc(100% - 64px)'}}
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>
                  <LensIcon sx={{color:color.hex}}/>
                  <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
                    {color.code}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>
                  {color.tags.map((tag) => (
                    <Chip label={tag} variant="outlined" />
                  ))}
                </Stack>
              </Stack>
              <IconButton
                onClick={handleDeleteBrandColor(color)}
                sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
                <DeleteForeverTwoToneIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        )}
        </Box>

        </Grid>
      </Grid>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{width:'100%'}}
          spacing={2}>
          <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">등록 취소</Button>
          <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
          <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">등록하기</Button>
        </Stack>
      </Box>
      <BrandAliasDialog
        open={brandAliasDialogOpen}
        onReceive={handleBrandAliasDialogData}
        onClose={handleBrandAliasDialogClose}/>
      <BrandColorDialog
        open={brandColorDialogOpen}
        onReceive={handleBrandColorDialogData}
        onClose={handleBrandColorDialogClose}/>
    </Box>
  );
}

export default BrandNewpage;
