import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

export default function CardBasketView(props) {

  const [priceObject ,setPriceObject] = React.useState(Object.values(props.info.prices)[0]);

  const handleDeleteTask = (event) => {
    props.onDelete();
  };

  return (
    <Card
      elevation={0}
      key={`basket-view-product-${props.info.hpid}`}
      sx={{ boxSizing:'border-box', border:"1px solid #eee", width: '250px', position:'relative', height:'200px', display:'inline-block' }}>
      <CardMedia
        component="img"
        height="120"
        image={props.info.images.length > 0 ? props.info.images[0] : "" }
        sx={{objectFit:"contain"}}
        alt={`product-images-${props.info.name}`}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="p" sx={{
          fontSize:"12px",
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          fontSize: '14px',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          }}>
          {props.info.name}
        </Typography>
        <>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#999"}}>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"12px", marginRight:'6px', color:"#999", textDecoration:'line-through'}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.retail.$numberDecimal))}
            </Typography>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.sale.$numberDecimal))}
            </Typography>
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"12px", color:"#444444"}}>
            {props.info.sku}
          </Typography>
        </>
      </CardContent>
      <CardActions>
        <Button size="small">수정하기</Button>
        <Button size="small" sx={{marginLeft:"auto"}}>자세히보기</Button>
      </CardActions>
      <Stack direction="row" sx={{
        position:'absolute',
        top:'12px',
        right:'12px'}}>
        <IconButton
          size="small"
          onClick={handleDeleteTask}>
          <CloseTwoToneIcon fontSize="small"/>
        </IconButton>
      </Stack>
    </Card>
  );
}
