import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';

import Skeleton from '@mui/material/Skeleton';

// controller
import DOProductController from '../../apis/dailyorder/DOProductController';

//heartit chart
import MatchPriceChart from '../../components/charts/MatchPriceChart';
import MatchStorageChart from '../../components/charts/MatchStorageChart';

//heartit dialog
import SimpleDialog from '../../components/dialogs/SimpleDialog';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';


const _product = {
  brand : { name : "" }
}

function DOProductInfoPage(props){

  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const [product, setProduct] = useState(_product);
  const [productImage, setProductImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [activeStep, setActiveStep] = React.useState(0);
  const [maxSteps, setMaxSteps] = React.useState(0);

  const [priceObject ,setPriceObject] = React.useState({});

  const [chartLoading, setChartLoading] = useState(true);
  const [chartDateLabel, setChartDateLabel] = useState([]);
  const [chartPriceData, setChartPriceData] = useState([]);
  const [chartStorageData, setChartStorageData] = useState([]);

  const [platformLinks, setPlatformLinks] = useState([]);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [isDeleteLoadling, setIsDeleteLoading] = useState(false);

  const getProductSuccess = (response) => {

    if(response.data.code === 200){

      setProduct(response.data.product);
      setProductImage(response.data.product.images);
      setMaxSteps(response.data.product.images.length);
      setPriceObject(response.data.product.prices.target);
      setChartDateLabel([]);
      setChartPriceData([]);
      setChartStorageData([]);
      DOProductController.getProductMatch({key : params.id, min : 0.9}, getProductMatchSuccess, getProductMatchError);
    }
    setIsLoading(false);
  };

  const getProductMatchSuccess = (response) => {
    setChartLoading(false);
    let chartDate = [];
    let chartData = {};
    let chartStorageData = {};

    if(response.data.code === 200){
      const matched = response.data.matched;
      matched.forEach((match) => {
        const date = moment(match.date).format('L');

        if( !chartDate.includes(date) ){
          chartDate.push(date);
          chartData[date] = {
            min : match.price.value,
            max : match.price.value
          };
          if(!isNaN(match.storage.total)){
            chartStorageData[date] = {
              storage : match.storage.total
            };
          }else{
            chartStorageData[date] = {
              storage : 0
            };
          }
        }else{
          const new_value = match.price.value;
          if(chartData[date].min > new_value){
            chartData[date].min = new_value;
          }

          if(chartData[date].max < new_value){
            chartData[date].max = new_value;
          }
          if(!isNaN(match.storage.total)){
            chartData[date].storage += match.storage.total;
          }

        }
      });
    }

    setChartPriceData(chartData);
    setChartStorageData(chartStorageData);
    setChartDateLabel(chartDate);
  }

  const getProductMatchError = (error) => {
    setChartLoading(false);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleImageLoad = () => {
    //setIsLoading(false);
  };

  const getProductError = (error) => {
    setIsLoading(false);
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }

  const handleChangeProduct = () => {
    navigate(`/do/product/info/${params.id}/change`);
  }

  const handleRemoveProduct = () => {
    setDeleteDialog(true);
  }

  const handleDeleteDialog = () => {
    setDeleteDialog(false);
  }

  const deleteProductSuccess = (response) => {
    setIsDeleteLoading(false);
    if(response.data.code === 200){
      handleSnackBar('성공적으로 상품이 삭제되었습니다', 'success');
      navigate(-1);
    }else{
      handleSnackBar('삭제 과정에서 문제가 발생했습니다', 'error');
    }
  }

  const deleteProductError = (error) => {
    setIsDeleteLoading(false);
    handleSnackBar('삭제 과정에서 문제가 발생했습니다', 'error');
  }

  const handleDeleteProductDialog = () => {
    setIsDeleteLoading(true);
    DOProductController.deleteProduct({product_key : params.id}, deleteProductSuccess, deleteProductError);
  }

  const storageChip = (status) => {
    switch(status){
      case 0 : return (
        <Chip
          color='error'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
      );
      case 1 : return (
        <Chip
          color='success'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
      );
      case 2 : return (
        <Chip
          color='warning'
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'부분재고'} />
      );
      default : return (<></>);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setActiveStep(0);
    DOProductController.getProductInfo({key : params.id}, getProductSuccess, getProductError);
  }, []);

  return (
    <Box sx={{width:'100%', height:'100%', position:'relative'}}>
      <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Box sx={{width:'100%', textAlign:'left', mb:'8px'}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{mb:'8px'}}
            spacing={1}>
            <Tooltip title={product.brand.name}>
              <Chip label={product.brand.name} size="small" sx={{marginBottom:'4px', fontSize:'14px'}} />
            </Tooltip>
          </Stack>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"12px", color:"#444444"}}>
            {product.code}
          </Typography>
        </Box>
        <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'4px'}}>
          <a style={{textDecoration:'none', color:'#222222'}} target="_blank" href={product.url}>
          {product.name}
          </a>
        </Typography>
        {/*(product.godo_goods_no && product.godo_goods_no.length >= 6) &&
          <Box sx={{width:'100%', textAlign:'left'}}>
            <Chip
              label="등록된 상품"
              color="success"
              size="small"
              icon={<CheckIcon size="small"/>}
              />
          </Box>*/}
        <Box sx={{position:'relative', width:'100%', textAlign:'left', mt:'12px', mb:'8px', maxWidth:'500px'}}>
          <Box
              component="img"
              sx={{
                height: 450,
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                objectFit:'contain'
              }}
              onLoad={handleImageLoad}
              src={productImage.length == 0 ? "/heartit_loading_image.png" : `/api/daily/image/${productImage[activeStep]}`}
            />
            <Button
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{position:'absolute', width:'32px', height:'100%', top:'0px', left:'0px', bottom:'0px'}}>
              <KeyboardArrowLeft />
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{position:'absolute', width:'32px', height:'100%', top:'0px', right:'0px', bottom:'0px'}}>
              <KeyboardArrowRight />
            </Button>
            <Box sx={{position:'absolute', bottom:'12px', left:'50%', transform:'translateX(-50%)'}}>
              <Chip sx={{backgroundColor:'#dddddd'}} icon={<ImageTwoToneIcon size="small" />} size="small" label={`${activeStep+1} / ${maxSteps}`} />
            </Box>
        </Box>
        {isLoading ? <></> : <Box sx={{position:'relative', width:'100%', textAlign:'left', mb:'8px'}}>
          <Typography variant="p" component="p" sx={{fontSize:"14px", color:"#444", textAlign:"left", mb:"0px"}}>
           목표가
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"16px", color:"#222", mb:'6px', fontWeight:'700'}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.value))}
          </Typography>

          <Typography variant="p" component="p" sx={{fontSize:"14px", color:"#444", textAlign:"left", mb:"4px"}}>
            등록일 : {lastTimestamp(product.created_at)}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            }}>
            {product.description}
          </Typography>
          <Box sx={{width:'100%', position:'relative', maxWidth:'500px', mt:'24px'}}>

            <Typography variant="p" component="p"
              sx={{
                fontSize:"16px",
                color:"#222222",
                fontWeight:900,
                textAlign:"left",
                borderRadius:'24px',
                padding:'8px 16px',
                maxWidth:'500px',
                border:'1px solid #888888',
                mb:"4px"}}>
              옵션 종류 ( 옵션 : {Object.keys(product.options).length} 종 )
            </Typography>
            {<List sx={{width:'100%'}}>
              {product.options.map((option) => (
                <ListItem
                  key={`option-key-${option.key}`}
                  sx={{paddingLeft:'0px', borderBottom:'1px solid #eeeeee'}}>
                  <ListItemText
                    primary={
                      Object.keys(option.option).map((value, index) => (
                        <Typography component='p' key={`option-text-${index}`} sx={{fontSize:'12px', color:'#222222'}}>
                        {value} : {option.option[value]}
                        </Typography>
                      ))
                    }
                  />
                </ListItem>
              ))}
            </List>}
          </Box>
        </Box>}
      </Box>
      <Box
        sx={{
          position:'absolute',
          top:'0px',
          right:'0px',
          height:'100%',
          width:'450px'
        }}>
        <Box sx={{
          width:'100%',
          padding:'72px 24px 24px'
          }}>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"16px", color:"#000", mb:'6px', fontWeight:'700'}}>
            매칭 이력
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#666", mb:'6px', fontWeight:'300'}}>
            가격 변동
          </Typography>
          <Box sx={{
            position : 'relative',
            width:'100%'
          }}>
            <Box sx={{
              position:'relative',
              width:'100%',
              mt:'24px',
              mb:'24px'
            }}>
            {chartLoading ? (
              <Skeleton variant="rectangular" sx={{
                width:'400px',
                height:'300px'
              }}>
              </Skeleton>
            ) :
            (
              <MatchPriceChart
                reference={priceObject.value}
                label={chartDateLabel}
                data={chartPriceData}/>
            )
            }
            </Box>
          </Box>

          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#666", mb:'6px', fontWeight:'300'}}>
            재고 변동
          </Typography>
          <Box sx={{
            position : 'relative',
            width:'100%'
          }}>
            <Box sx={{
              position:'relative',
              width:'100%',
              mt:'24px',
              mb:'24px'
            }}>
            {chartLoading ? (
              <Skeleton variant="rectangular" sx={{
                width:'400px',
                height:'300px'
              }}>
              </Skeleton>
            ) :
            (
              <MatchStorageChart
                label={chartDateLabel}
                data={chartStorageData}/>
            )
            }
            </Box>
          </Box>

        </Box>
      </Box>
      <Box
        sx={{
          position:'absolute',
          top:'16px',
          right:'16px',
          boxSizing:'border-box',
        }}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleChangeProduct}>수정하기</Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleRemoveProduct}>삭제하기</Button>
        </Stack>
      </Box>
      <SimpleDialog
        title={"삭제 확인"}
        content={"정말 해당 타겟 상품을 제외하시겠습니까?"}
        positive={"삭제하기"}
        open={deleteDialog}
        onClose={handleDeleteDialog}
        onPositive={handleDeleteProductDialog}
        />
    </Box>
  );
}

export default DOProductInfoPage;
