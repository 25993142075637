import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOReviewController{
  static addReview(query, _success, _error){
    const token = getToken();

    axios({
      method: "GET",
      url:`/api/review/product/${query.file}/${query.id}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static deleteReview(query, _success, _error){
    const token = getToken();

    axios({
      method: "DELETE",
      url:`/api/review/product/${query.file}/${query.id}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static getReviewFiles(_success, _error){
    const token = getToken();

    axios({
      method: "GET",
      url : "/api/review/files",
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getReviewFileProduct(file_key, _success, _error){
    const token = getToken();

    axios({
      method: "GET",
      url : `/api/review/file/${file_key}`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getReviews(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/review/product/",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static deactivateReview(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/products",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static activateReview(data, _success, _error){
    const token = getToken();

    axios({
      method: "POST",
      url : "/api/daily/products",
      data : data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
