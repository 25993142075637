import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Avatar from '@mui/material/Avatar';

// heartit components
import UserEmailDialog from '../../components/dialogs/UserEmailDialog';
import UserNameDialog from '../../components/dialogs/UserNameDialog';

// icon
import Person3TwoToneIcon from '@mui/icons-material/Person3TwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

// apis
import UserController from '../../apis/users/UserController';

// color
import { green, pink, teal } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

import axios from "axios";

function MyInfoPage(props){
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loginLog, setLoginLog] = useState("-");
  const [email, setEmail] = useState([]);

  const [dialogEmailOpen, setDialogEmailOpen] = useState(false);
  const [dialogNameOpen, setDialogNameOpen] = useState(false);

  const handleOpenEmailAdd = () => {
    setDialogEmailOpen(true);
  }

  const handleOpenNameEdit = () => {
    setDialogNameOpen(true);
  }

  const getUserDataSuccess = (response) => {
    if(response.data.code === 200){
      const data = response.data
      setFirstName(data.first_name);
      setLastName(data.last_name);
      if(data.log.length > 0)
        setLoginLog(moment(data.log[0].date).fromNow());
      setEmail(data.email);
    }
  };

  const getUserDataError = (error) => {

  };

  const handleCloseDialogEmail = () => {
    setDialogEmailOpen(false);
    UserController.getUserInfo(getUserDataSuccess, getUserDataError);
  }

  const handleCloseDialogName = () => {
    setDialogNameOpen(false);
    UserController.getUserInfo(getUserDataSuccess, getUserDataError);
  }

  useEffect(() => {
    UserController.getUserInfo(getUserDataSuccess, getUserDataError);
  }, []);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative' }}>
      <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
        <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px'}}>
          나의 프로필 관리
        </Typography>
        <Box sx={{width:'100%', mb:'24px'}}>
          <Avatar
            sx={{width:'108px', height:'108px'}}>
            <Person3TwoToneIcon sx={{fontSize:'48px'}}/>
          </Avatar>
        </Box>
        <Box
          sx={{width:'100%', mb:'24px', maxWidth:'500px', boxSizing:'border-box', borderRadius:'16px', border:'1px solid #dddddd', padding:'24px'}}>
          <Typography variant="h6" element="p" sx={{textAlign:"left", fontSize:'17px', color:'#222222', mb:'12px'}}>
            기본 정보
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{position:'relative', width:'100%', boxSizing:'border-box', borderBottom:'1px solid #dddddd', padding:'12px 0px 12px'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{textAlign:"left", width:'102px', fontSize:'14px', color:'#888888'}}>
              이름
            </Typography>
            <Typography variant="h6" element="p" sx={{textAlign:"left", fontSize:'14px', color:'#222222'}}>
              {`${lastName} ${firstName}`}
            </Typography>
            <IconButton
              onClick={handleOpenNameEdit}
              sx={{position:'absolute', right:'0px'}} aria-label="edit_button">
              <EditTwoToneIcon />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{width:'100%', boxSizing:'border-box', padding:'12px 0px 0px'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{textAlign:"left", width:'102px', fontSize:'14px', color:'#888888'}}>
              마지막 로그인
            </Typography>
            <Typography variant="h6" element="p" sx={{textAlign:"left", fontSize:'14px', color:'#222222'}}>
              {loginLog}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{position:'relative', width:'100%', mb:'12px', maxWidth:'500px', boxSizing:'border-box', borderRadius:'16px', border:'1px solid #dddddd', padding:'24px'}}>
          <Typography variant="h6" element="p" sx={{textAlign:"left", fontSize:'17px', color:'#222222', mb:'12px'}}>
            연락처 정보
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{width:'100%', boxSizing:'border-box', padding:'12px 0px 12px'}}
            spacing={2}>
            <Typography variant="h6" element="p" sx={{textAlign:"left", width:'102px', fontSize:'14px', color:'#888888'}}>
              이메일
            </Typography>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
              {email.map((em) => (
                <Typography key={em.email} variant="h6" element="p" sx={{textAlign:"left", fontSize:'14px', color:'#222222'}}>
                  {em.email}
                </Typography>
              ))}
            </Stack>
          </Stack>
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleOpenEmailAdd}
            sx={{position:'absolute',top:'20px',right:'32px'}}>
            연락처 추가하기
          </Button>
        </Box>
      </Box>
      <UserEmailDialog
        open={dialogEmailOpen}
        onClose={handleCloseDialogEmail}/>
      <UserNameDialog
        initialData={{first_name : firstName, last_name : lastName}}
        open={dialogNameOpen}
        onClose={handleCloseDialogName}/>
    </Box>
  );
}

export default MyInfoPage;
