// reducers/index.js
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import count from "./count";
import versionControl from "./version";
import statisticsControl from "./statistics";
import dailyOrderControl from "./do_file_list";

//import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: "root",
  // localStorage에 저장합니다.
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  //whitelist: ["count"]
  // blacklist -> 그것만 제외합니다
};

export const rootReducer = combineReducers({
  counterReducer : persistReducer(persistConfig, count),
  versionReducer : persistReducer(persistConfig, versionControl),
  statisticsReducer : persistReducer(persistConfig, statisticsControl),
  dailyOrderFileListReducer : persistReducer(persistConfig, dailyOrderControl),
  //board,
  //studio
});

export default persistReducer(persistConfig, rootReducer);
