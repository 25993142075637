import axios from 'axios';
import getToken from '../../utils/getToken'

export default class LikeController{
  static removeLike(id, _success, _error){
    const token = getToken();
    axios({
      method: "DELETE",
      url : `/api/product/${id}/like`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    }).then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static createLike(id, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url:`/api/product/${id}/like`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
}
