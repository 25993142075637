import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider, connect } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/store'



import { combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//import store from './store/store'
import SplashLayout from './containers/layout/SplashLayout';

import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { versionChange } from "./reducers/version";
import { statisticsChange } from "./reducers/statistics";
import { dailyOrderChange } from "./reducers/do_file_list";
//const store = configureStore({count : 1});
//const persistor = persistStore(store);


const store = configureStore();
const persistor = persistStore(store);


const root = ReactDOM.createRoot(document.getElementById('root'));

const mapStateToProps = state => ({
  count: state.counterReducer.count,
  __version: state.versionReducer.version,
  __statistics: {
    start_date : state.statisticsReducer.start_date,
    end_date : state.statisticsReducer.end_date,
    parameter : state.statisticsReducer.parameter,
  },
  __dailyOrderFileList: {
    fl_start_date : state.dailyOrderFileListReducer.fl_start_date,
    fl_end_date : state.dailyOrderFileListReducer.fl_end_date
  },
});

const mapDispatchToProps = dispatch => ({
  __versionChange: (version) => dispatch(versionChange(version)),
  __statisticsChange: (statistics) => dispatch(statisticsChange(statistics)),
  __dailyOrderFileListChange: (dailyOrder) => dispatch(dailyOrderChange(dailyOrder))
});

const ConnectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

root.render(
  <Provider store={store}>
    <PersistGate loading={<SplashLayout/>} persistor={persistor}>
      <ConnectedApp />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
