import React, {useState, useEffect} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function DOOutLinkRowView(props){
  return (
    <Box sx={{position:'relative', width:'100%', padding:'16px 0px', borderBottom:'1px solid #dddddd'}}>
      <Typography variant="p" component="p" sx={{fontSize:'16px', color:"#222222", mb:"2px", fontWeight:'700'}}>
        {props.platform}
      </Typography>
      <Typography variant="p" component="p" sx={{fontSize:'12px', color:"#666666"}}>
        {props.platform_link}
      </Typography>
      <IconButton
        onClick={props.onDelete}
        size="small"
        sx={{position:'absolute', right:'0px', top:'8px'}}>
        <CloseIcon fontSize="small"/>
      </IconButton>
    </Box>
  )
}
