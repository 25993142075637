import axios from 'axios';
import getToken from '../../utils/getToken'

export default class ToolController{
  static uploadStaticImage(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    data.images.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:"/api/tool/image/static",
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }

  static generateHanstyle(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    data.images.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:"/api/tool/image/generate",
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }

  static generateThumbnail(data, _success, _error){
    const token = getToken();
    let formData = new FormData();

    data.images.forEach((image) => {
        formData.append('images', image.file);
    });

    axios({
      method: "POST",
      url:"/api/tool/image/thumbnail",
      data:formData,
      headers: {
        Authorization: 'Bearer ' + token,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log(error.response.status);
        console.log(error.response.headers);
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
}
