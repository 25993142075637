import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// heartit controller
import DOMailController from '../../apis/dailyorder/DOMailController';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function FileSelectorInput(props){

  const [open, setOpen] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const loading = open && fileList.length === 0;

  const getFilesSuccess = (response) => {
    if(response.data.code === 200){
      setFileList(response.data.file);
    }
  }

  const getFilesError = (error) => {

  }

  React.useEffect(() => {
     let active = true;

     if (!loading) {
       return undefined;
     }

     (async () => {

       if (active) {
         DOMailController.getTodayFileList(getFilesSuccess, getFilesError);
       }
     })();

     return () => {
       active = false;
     };
   }, [loading]);

   React.useEffect(() => {
     if (!open) {
       setFileList([]);
     }
   }, [open]);
  return (
    <Autocomplete
        multiple={true}
        id="tags-outlined"
        open={open}
        size="small"
        sx={props.sx}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        options={fileList}
        loading={loading}
        value={props.value}
        onChange={props.onChange}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
             {...params}
             label="타겟 파일 리스트"
             InputProps={{
               ...params.InputProps,
               endAdornment: (
                 <React.Fragment>
                   {loading ? <CircularProgress color="inherit" size={20} /> : null}
                   {params.InputProps.endAdornment}
                 </React.Fragment>
               ),
             }}
           />
        )}
      />
  );
}
