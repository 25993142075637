import axios from 'axios';
import getToken from '../../utils/getToken'

export default class LogController{
  static getLogs(_success, _error){
    const token = getToken();
    axios({
      method : "GET",
      url : `/api/logs`,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
