import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoadingButton from '@mui/lab/LoadingButton';
import { visuallyHidden } from '@mui/utils';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

import DOReviewController from '../../apis/dailyorder/DOReviewController';
import css_table from '../../css/table.module.css';

import { useSnackbar } from 'notistack';

import moment from 'moment';
import 'moment/locale/ko';

import { red, green, orange } from '@mui/material/colors';

import {
  MIN_HIGH_TRANSACTION, MIN_MIDDLE_TRANSACTION,
  MIN_HIGH_ROR, MIN_MIDDLE_ROR
} from '../../assets/Constants';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: '예상 이미지',
    minWidth: 120,
  },
  {
    id: 'brand',
    numeric: false,
    disablePadding: false,
    label: '브랜드 명',
    minWidth: 100,
  },
  {
    id: 'model',
    numeric: false,
    disablePadding: false,
    label: '모델명',
    minWidth: 140,
  },
  {
    id: 'like',
    numeric: false,
    disablePadding: false,
    label: '관심 수',
    minWidth: 64,
  },
  {
    id: 'row',
    numeric: false,
    disablePadding: false,
    label: '열 번호',
    minWidth: 64,
  },
  {
    id: 'price',
    numeric: false,
    disablePadding: false,
    label: '매입 가격',
    minWidth: 72,
  },
  {
    id: 'expected_ror',
    numeric: false,
    disablePadding: false,
    label: '예상 수익률',
    minWidth: 72,
  },
  {
    id: 'transaction',
    numeric: false,
    disablePadding: false,
    label: '채결 강도',
    minWidth: 72,
  },
  {
    id: 'review',
    numeric: false,
    disablePadding: false,
    label: '리뷰 추가',
    minWidth: 72,
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>*/}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },

      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{
            flex: '1 1 100%',
            textAlign: 'left',
            fontSize:'14px',
            color:'#222222',
            fontWeight:'900'
          }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}개의 브랜드 선택
        </Typography>
      ) : (
        <Box
          sx={{
            flex: '1 1 100%',
            textAlign:'left'
          }}
          component="div">
          <TextField
            id="input-with-icon-textfield"
            label="검색어"
            value={props.keyword}
            onChange={props.handleKeyword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        </Box>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DOFileProductTable(props) {
  const [rows, setRows] = React.useState(props.rows);
  const [keyword, setKeyword] = React.useState("");
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('row');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [reviewLoading, setReviewLoading] = React.useState({
    loading : false,
    id : -1
  });

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    props.handleClick(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReviewClick = (row) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    if(!row.reviewed){
      DOReviewController.addReview({id:row._id, file:props.file}, addReviewSuccess(row), addReviewError);
    }else{
      DOReviewController.deleteReview({id:row._id, file:props.file}, deleteReviewSuccess(row), addReviewError);
    }

  };

  const addReviewSuccess = (row) => (response) => {
    if(response.data.code === 200){
      handleSnackBar("해당 상품이 리뷰에 추가되었습니다", "success");
      props.handleReview(row.id, true);
    }else{
      handleSnackBar("이미 추가되었거나, 등록할 수 없습니다", "error");
    }
  };

  const deleteReviewSuccess = (row) => (response) => {
    if(response.data.code === 200){
      handleSnackBar("해당 상품이 리뷰에서 제외되었습니다", "success");
      props.handleReview(row.id, false);
    }else{
      handleSnackBar("이미 제외된 상품입니다.", "error");
    }
  };

  const addReviewError = (error) => {
    handleSnackBar("서버에 오류가 있습니다.", "error");
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getTextColor = (type, value) => {
    if(type === "ROR"){
      if( value >= MIN_HIGH_ROR ){
        return green[500];
      }else if(value >= MIN_MIDDLE_ROR){
        return orange[500];
      }else{
        return red[500];
      }
    }else if(type === "TRANSACTION"){
      if( value >= MIN_HIGH_TRANSACTION ){
        return green[500];
      }else if(value >= MIN_MIDDLE_TRANSACTION){
        return orange[500];
      }else{
        return red[500];
      }
    }
    return "#ffffff";
  }

  useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  useEffect(() => {
    const new_rows = [...props.rows].filter(el => (el.brand.toUpperCase().indexOf(keyword.toUpperCase()) >= 0 || el.code.toUpperCase().indexOf(keyword.toUpperCase()) >= 0 ) );
    setRows(prev => new_rows);
  }, [keyword]);

  return (
    <Box sx={{ position:'relative', width: '100%', height:'100%' }}>
      <Paper elevation={4} sx={{ position:'relative',  overflow: 'hidden', borderRadius:'16px', width: '100%', mb: 2, height:'100%' }}>
        <EnhancedTableToolbar
          keyword={keyword}
          handleKeyword={handleKeywordChange}
          numSelected={selected.length} />
        <TableContainer sx={{ maxHeight: 'calc(100vh - 400px)' }} className={css_table.hi_table__container}>
          <Table
            stickyHeader aria-label="sticky table" className={css_table.hi_table__container}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody className={css_table.hi_table__container}>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isfeatureSelected = ( row.expected_ror > MIN_HIGH_ROR ) && ( row.transaction > MIN_HIGH_TRANSACTION );
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      sx={{backgroundColor:isfeatureSelected?'#fff200':'inherit'}}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`brand-row-${row.id}`}
                      selected={isItemSelected}
                    >
                      <TableCell>
                        { row.match.length > 0 &&
                          row.match[0].type === "KREAM" ?
                          <img
                          referrerPolicy="no-referrer"
                          style={{width:'96px', height:'96px', objectFit:'cover'}}
                          src={row.match[0].image[0].length > 0 ? row.match[0].image[0] : ""}/>
                          :
                          <img
                            referrerPolicy="no-referrer"
                            style={{width:'96px', height:'96px', objectFit:'cover'}}
                            src={row.match[0].image[0].length > 0 ? `/api/daily/image/${row.match[0].image[0]}` : "/heartit_loading_image.png"}/>
                        }
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row">
                        {`${row.brand}`}
                      </TableCell>
                      <TableCell align="left">
                        {`${row.code}`}
                      </TableCell>
                      <TableCell align="center">
                        {row.match[0].type === "KREAM" ? `${row.like}` : "하트잇"}
                      </TableCell>
                      <TableCell align="center">
                        {`${row.row+1}`}
                      </TableCell>
                      <TableCell align="center">
                        {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.price.value))}
                      </TableCell>
                      <TableCell align="center" sx={{color:getTextColor("ROR", row.expected_ror)}}>
                        {
                          `${row.expected_ror.toFixed(2)}%`
                        }
                      </TableCell>
                      <TableCell align="center"sx={{color:getTextColor("TRANSACTION", row.transaction)}}>
                        {
                          row.transaction.toFixed(2)
                        }
                      </TableCell>
                      <TableCell align="center">
                        <LoadingButton
                          size="small"
                          onClick={handleReviewClick(row)}
                          disableElevation
                          loading={(reviewLoading.id === row.id) ? reviewLoading.loading : false}
                          variant={row.reviewed ? "contained" : "outlined"}>
                          <span>
                          {row.reviewed ? "제외하기" : "추가하기"}
                          </span>
                        </LoadingButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={'한 번에 보여지는 상품 수'}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
