import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import DOReviewController from '../../apis/dailyorder/DOReviewController';
import DOProductOfferView from './DOProductOfferView';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';

import { pink, blue, red, blueGrey, green } from '@mui/material/colors';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

import moment from 'moment';
import 'moment/locale/ko';

export default function DORecommandOrderView(props){

  const navigate = useNavigate();
  //const [priceObject ,setPriceObject] = React.useState(props.info.prices.target);
  const [loading, setLoading] = React.useState(false);
  const [product, setProduct] = React.useState(props.product);
  const [matched, setMatched] = React.useState(props.product.indicator.color !== "undefined" ? props.product.indicator.color : props.product.indicator.indicator);

  const addReviewSuccess = (response) => {

  }

  const addReviewError = (error) => {
    
  }

  const handleReviewClick = (event) => {
    setLoading(true);
    DOReviewController.addReview({
      id : props.product.id
    })
  }

  const productWeight = (weight) => {

  }

  const matchText = (matched_parameter) => {

    if(Object.keys(matched_parameter).includes("color")){

      if(matched_parameter.color > 0.99){
        return (<Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'10px', mb:'4px', fontWeight:900, color:blue[600]}}>
          제일 정확
        </Typography>)
      }else{
        return (<></>)
      }
    }else{

      if(matched_parameter.indicator > 0.99){
        return (<Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'10px', mb:'4px', fontWeight:900, color:blue[600]}}>
          제일 정확
        </Typography>)
      }else{
        return (<></>)
      }
    }
  }

  const profitText = (target, price) => {
    if(target > price.value){
      return (
        <>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, mb:'4px', color:green[600]}}>
            {(new Intl.NumberFormat(price.country, { style: 'currency', currency: price.currency }).format(target-price.value))}
             ( { ((target * 0.96 - 4000 - price.value)/price.value*100).toFixed(2) }% )
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, mb:'4px' , color:green[600]}}>
            재고 : {product.storage.total}
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, color:green[600]}}>
            총액 :
            {(new Intl.NumberFormat(price.country, { style: 'currency', currency: price.currency }).format( (target-price.value)*product.storage.total ))}
          </Typography>
        </>
      );
    }else{
      return (
        <>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, mb:'4px', color:red[600]}}>
            {(new Intl.NumberFormat(price.country, { style: 'currency', currency: price.currency }).format(target-price.value))}
             ( { ((target * 0.96 - 4000 - price.value)/price.value*100).toFixed(2) }% )
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, mb:'4px', color:red[600]}}>
            재고 : {product.storage.total}
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:'12px', fontWeight:300, color:red[600]}}>
            총액 :
            {(new Intl.NumberFormat(price.country, { style: 'currency', currency: price.currency }).format( (target-price.value)*product.storage.total ))}
          </Typography>
        </>
      );
    }
  }

  useEffect(() => {

  }, []);

  return (
    <Paper sx={{width:'100%', position:'relative', boxSizing:'border-box', padding:'12px', mb:'8px', border:(matched>0.99) && `1px solid ${blue[300]}` }}>
      <Box sx={{width:'100%'}}>
        {matchText(product.indicator)}
        <Typography variant="p" component="div" sx={{
          textAlign:"left",
          mb:'4px',
          fontSize:'11px',
          fontWeight:300,
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          color:'#000000'}}>
          <Chip size="small" label={product.file.vendor} sx={{mr:'4px', fontSize:'10px', height:'16px'}}/>
          <b>{product.file.name}</b>
        </Typography>
        <Typography variant="p" component="p" sx={{textAlign:"left", mb:'4px', fontSize:'12px', fontWeight:300, color:'#555555'}}>
          <span>{product.code}</span>
          <b>({product.row+1}번째 줄)</b>
        </Typography>
        <Typography variant="p" component="p" sx={{textAlign:"left", mb:'4px', fontSize:'14px', fontWeight:900}}>
          {(new Intl.NumberFormat(product.price.country, { style: 'currency', currency: product.price.currency }).format(product.price.value))}
        </Typography>
        {profitText(product.target_price, product.price)}
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{mt:'8px', width:'100%'}}
          spacing={2}>
          <LoadingButton
            onClick={handleReviewClick}
            loading={loading}
            size="small"
            loadingPosition="start"
            startIcon={<GradeTwoToneIcon fontSize="16px"/>}
            variant="outlined">
            <span>추가하기</span>
          </LoadingButton>
        </Stack>
      </Box>
    </Paper>
  );
}
