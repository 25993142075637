import React, {useEffect, useState, useRef} from 'react';

import { styled } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import IconButton from '@mui/material/IconButton';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';

// icon list
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import axios from "axios";

// color set
import { blue } from '@mui/material/colors';

import Chart from "react-apexcharts";

// heartit components
import BrandInput from '../../components/inputs/BrandInput';
import HostInput from '../../components/inputs/HostInput';
import SoldoutCheckBox from '../../components/inputs/SoldoutCheckBox';
import ViewNumberSelector from '../../components/inputs/ViewNumberSelector';
import ViewTypeSelector from '../../components/inputs/ViewTypeSelector';

import CardBasketView from '../../components/products/CardBasketView';
import CardLoadingView from '../../components/products/CardLoadingView';
import CardView from '../../components/products/CardView';
import ListView from '../../components/products/ListView';

// dialog
import ProductUploadDialog from '../../components/dialogs/ProductUploadDialog';
import ProductImageDialog from '../../components/dialogs/ProductImageDialog';
import ProductStorageDialog from '../../components/dialogs/ProductStorageDialog';

// heartit controller
import ProductController from '../../apis/products/ProductController';
import LogController from '../../apis/logs/LogController';

// alert
import { SnackbarProvider, useSnackbar } from 'notistack';

import debounce from 'lodash/debounce';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW
} from '../../assets/Constants';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// delivery = 0 : 국내배송 ; 1 : 해외배송
// storage.status = 0 : 품절, 1 : 재고 있음, 2 : 부분 재고

function AccessLogsPage(props){

  const { enqueueSnackbar } = useSnackbar();

  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });
  const refList = {
    actionBar : useRef(null),
    filterTop : useRef(null),
    product_list: useRef(null)
  };
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isStorageOpen, setIsStorageOpen] = useState(false);

  const [isProductLoading, setIsProductLoading] = useState(true);

  const [panelExpanded,  setPanelExpanded] = useState(0);
  const [viewType, setViewType] = useState(PRODUCT_CARD_VIEW);
  const [onlyOwned, setOnlyOwned] = useState(false);
  const [onlyHearted, setOnlyHeartedOwned] = useState(false);
  const [onlyUploaded, setOnlyUploadedOwned] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [soldout, setSoldout] = useState([true, true]);

  const [types, setTypes] = useState([]);
  const [numbers, setNumbers] = useState([]);

  const [chartData, setChartData] = useState({
      options: {
        chart: {
          id: "basic-bar"
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [
        {
          name: "클릭수",
          data: [30, 40, 45, 50, 49, 60, 70, 91]
        }
      ]
    });

  const getLogSuccess = (response) => {
    if(response.data.code === 200){
      const _logs = response.data.logs;
      let _types = [];
      let _numbers = [];

      _logs.map((el) => {
        const forward = el.forward.split("/")[1];
        if(_types.includes(forward)){
          _numbers[_types.indexOf(forward)] += 1;
        }else{
          _types.push(forward);
          _numbers.push(1);
        }
      });

      setChartData((prev) => ({
        options : { xaxis : { categories : _types }},
        series : [
          {
            name: "클릭수",
            data: _numbers
          }
        ]
      }));
    }
    setIsProductLoading(false);
  };

  const getLogError = (error) => {
    console.log(error);
    setIsProductLoading(false);
  };

  useEffect(() => {
    if(isProductLoading){
      LogController.getLogs(getLogSuccess, getLogError);
    }
  }, [isProductLoading]);

  return (
    <Box sx={{ flexGrow: 1, width: "100%", height:"100%", position:'relative' }}>
      <Grid container spacing={3} sx={{height:"100%", position:'relative'}}>
        <Grid key="product-list-top-search-bar" item sx={{width:{xs : "100%", sm : "240px"} }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{width:"100%"}}
            spacing={1}>

          </Stack>
        </Grid>
        <Grid key="product-list-body" item container xs={12} sm md lg={8}
          sx={{height:'100%', position:'relative'}}>
          <Grid
            container
            ref={refList.filterTop}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{mb:"8px"}}
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}>

          </Grid>
          <Grid container ref={refList.product_list} item sx={{width:"100%", height:`calc(100% - ${componentHeight.filterTop-8}px)`, overflow:"scroll"}}>
            {isProductLoading ? (
              [1].map((el) => (
                <Grid item key={`product-loading-${el}`} xs={12} md={4} xl={3} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
                  <CardLoadingView />
                </Grid>
              ))
            ) :
            (<Box>
              <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              width="1000"/>
            </Box>)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccessLogsPage;
