import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

// heartit controllers
import HostController from '../../apis/hosts/HostController';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export default function HostChangeDialog(props){

  const [dialogState, setDialogState] = useState({
    data : {
      name : props.host.name,
      hostname : props.host.hostname
    },
    error : {
      name : false,
      domain : false
    }
  });

  const handleNameInput = (event) => {
    //setError({ ...error, name : false });
    //setName(event.target.value);
    setDialogState({
      data : {
        ...dialogState.data,
        name : event.target.value
      },
      error : {
        ...dialogState.error,
        name : false
      }
    });
  };

  const handleDomainInput = (event) => {
    //setError({ ...error, domain : false });
    //setDomain(event.target.value);
    setDialogState({
      data : {
        ...dialogState.data,
        hostname : event.target.value
      },
      error : {
        ...dialogState.error,
        domain : false
      }
    });
  };

  useEffect(() => {
    setDialogState({
      ...dialogState,
      data : {
        name : props.host.name,
        hostname : props.host.hostname
      }
    })
  }, [props.host]);

  const handleUpdateSuccess = (response) => {
    if(response.data.code === 200){
      props.onClose();
    }
  }

  const handleUpdateError = (error) => {

  }

  const handleUpdate = () => {
    let isValid = true;
    let _error = { name : false, domain : false };
    if(dialogState.data.name.length === 0){
      _error.name = true;
      isValid = false;
    }
    if(!urlPattern.test(dialogState.data.hostname)){
      _error.domain = true;
      isValid = false;
    }
    if(!isValid){
      setDialogState({...dialogState, error:_error});
      return;
    }
    HostController.updateHost({
      id : props.host.id,
      name : dialogState.data.name,
      hostname : dialogState.data.hostname
    }, handleUpdateSuccess, handleUpdateError);
  };

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         호스트 수정
       </DialogTitle>
       <DialogContent>
         <Stack
           direction="column"
           justifyContent="center"
           alignItems="flex-start"
           sx={{pt:"8px"}}
           spacing={2}>
             <Box sx={{width:'100%', height:'48px', mb:"8px"}}>
              <TextField
                id="host-add-dialog-name-input"
                error={dialogState.error.name}
                value={dialogState.data.name}
                sx={{minWidth:'80%'}}
                onChange={handleNameInput}
                label="호스트 명"
                size="small"
                type="search"
                helperText={dialogState.error.name ? "호스트 명은 두 글자 이상이어야 합니다" : ""}
                InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DomainTwoToneIcon size="small" />
                  </InputAdornment>
                ),
                }} />
             </Box>
             <Box sx={{width:'100%', height:'48px'}}>
              <TextField
                id="host-add-dialog-domain-input"
                error={dialogState.error.domain}
                sx={{minWidth:'80%'}}
                value={dialogState.data.hostname}
                onChange={handleDomainInput}
                label="호스트 도메인"
                placeholder="도메인 형식 (http://* or https://*)"
                helperText={dialogState.error.domain ? "도메인 형식이 잘못되었습니다" : "" }
                size="small"
                type="search"
                InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkTwoToneIcon size="small" />
                  </InputAdornment>
                ),
                }} />
             </Box>
          </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' onClick={handleUpdate}>수정하기</Button>
       </DialogActions>
     </Dialog>
  )
};
