import axios from 'axios';
import getToken from '../../utils/getToken'

export default class CrawlerController{
  static removeCrawler(id, _success, _error){
    const token = getToken();
  }
  static createCrawler(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/crawler/targetUrl/create",
      data:{
        host : data.host.id,
        name : data.name,
        time : data.time,
        url : data.url
      },
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
  static updateCrawler(data, _success, _error){
    const token = getToken();
  }
  static getCrawler(query, _success, _error){
    const token = getToken();
  }
  static getCrawlers(query, _success, _error){
    const token = getToken();
    axios({
      method: "GET",
      url:"/api/crawler/targetUrl",
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
      }
      if(error.response.status === 401){
          error.handleGlobally && error.handleGlobally();
      }
      _error(error);
    });
  }
}
