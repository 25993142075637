import * as React from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import AssistantPhotoTwoToneIcon from '@mui/icons-material/AssistantPhotoTwoTone';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

export default function DOTypeSelectorView(props) {
  //const [type, setType] = React.useState(DAILY_PRODUCT_TYPE_TEST);

  const handleChange = (event) => {
    const value = event.target.value;
    //setType(value);
    props.onChange(value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={props.type}
        onChange={handleChange}
      >
        <FormControlLabel value={DAILY_PRODUCT_TYPE_MUST} control={<Radio />} label="Must 상품" />
        <FormControlLabel value={DAILY_PRODUCT_TYPE_OK} control={<Radio />} label="OK 상품" />
        <FormControlLabel value={DAILY_PRODUCT_TYPE_TEST} control={<Radio />} label="Test 상품" />
      </RadioGroup>
    </FormControl>
  );
}
