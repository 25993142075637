import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// color set
import { blue } from '@mui/material/colors';

export default function HostElementView(props){

  const [isHovering, setIsHovering] = useState(false);

  const handleRemove = () => {
    props.onRemove(props.host.id);
  };

  const handleChange = () => {
    props.onChange(props.host.id);
  };

  return (
    <Box sx={{
      boxSizing:'border-box',
      borderRadius:'4px',
      width:'100%',
      padding : '16px',
      border:(isHovering ? `1px solid ${blue[500]}` : '1px solid #eee')}}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}>
      <Typography
        variant="h6"
        element="p"
        sx={{
          textAlign:'left',
          fontSize:"16px",
          fontWeight:'900'
        }}>
        {props.host.name}
      </Typography>
      <Typography
        variant="h6"
        element="p"
        sx={{
          textAlign:'left',
          fontSize:"12px",
          color:'#444444'
        }}>
        {props.host.hostname}
      </Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{width:'100%', height:'48px'}}
        spacing={1}>
        {isHovering && <Button variant="text" onClick={handleRemove} size="small">삭제하기</Button>}
        {isHovering && <Button variant="outlined" onClick={handleChange} size="small">수정하기</Button>}
      </Stack>
    </Box>
  );
}
