import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import Popover from '@mui/material/Popover';
import { ChromePicker } from 'react-color';
// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import { useSnackbar } from 'notistack';

const example_tags = [
  "Anthracite",
  "Black","블랙","검정",
  "Latte","라떼",
  "White","화이트",
  "Anthracite Melange",
  "Navy","네이비",
  "Beige Melange","베이지",
  "Beige","Grey Melange","Grey","그레이",
  "Parakeet","Orange","오랜지",
  "Purple","퍼플","보라",
  "Almond","Wisteria","Bordeaux","Ribbon","Porridge",
  "Milk Shake","Gray","Midnight Blue","Acid Kiwi"
];

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function BrandColorDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading]  = useState(false);
  const [isColor, setIsColor]  = useState(false);
  const [code, setCode] = useState("");
  const [hex, setHex] = useState("#ffffff");
  const [tags, setTags] = useState([]);
  const [error, setError] = useState({
    code : false,
    hex : false,
    tags : false
  })

  const handleCodeInput = (event) => {
    setError({ ...error, code : false });
    setCode(event.target.value);
  }

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleSubmit = (event) => {
    setIsLoading(true);
    let isValid = true;
    let _error = { code : false, tags : false };

    if(code.length < 2){
      _error.code = true;
      isValid = false;
    }
    if(tags.length < 1){
      _error.tags = true;
      isValid = false;
    }
    if(!isValid){
      setError(_error);
      setIsLoading(false);
      return;
    }
    createBrand();
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };


  const createBrand = () => {
    props.onReceive({
      code : code,
      hex : hex,
      tags : tags
    });

    setCode("");
    setHex("#ffffff");
    setTags(prev => ([]));
    setIsLoading(false);
    setError({ ...error, code : false, hex : false, tags : false });
    props.onClose();
  }

  const handleChangeComplete = (color) => {
    setHex( color.hex );
  };

  const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
   setAnchorEl(null);
 };

 const open = Boolean(anchorEl);
 const id = open ? 'color-popover' : undefined;

  useEffect(() => {
    setError({ ...error, code : false, hex : false, tags : false });
  }, [props.open]);

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         컬러 코드 추가
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
            <Box sx={{width:'100%', height:'48px'}}>
             <TextField
               id="user-add-dialog-email-input"
               disabled={isLoading}
               error={error.brand}
               sx={{minWidth:'80%'}}
               value={code}
               onKeyDown={handleKeyPress}
               onChange={handleCodeInput}
               label="컬러 코드"
               placeholder="컬러 코드 (ex. P195)"
               helperText={error.brand ? "컬러코드 명은 2글자 이상이어야 합니다" : "" }
               size="small"
               type="text"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
            <Box sx={{width:'100%', height:'48px'}}>
              <Button variant="outlined" sx={{
                minWidth:'80%',
                backgroundColor:hex,
                textAlign:'left',
                border:'1px solid #eeeeee',
                color:'#222222',
                justifyContent: "flex-start"
              }} onClick={handleClick}>색상 선택</Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ChromePicker
                  color={ hex }
                  onChangeComplete={ handleChangeComplete }/>
              </Popover>
            </Box>

            <Autocomplete
              multiple
              id="tags-filled"
              options={example_tags.map((option) => option)}
              freeSolo
              sx={{minWidth:'80%'}}
              value={tags}
              onChange={(event, newValue) => {
                setTags(newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="연관 단어 목록"
                  placeholder="연관 단어"
                />
              )}
            />
         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>추가하기</Button>
       </DialogActions>
     </Dialog>
  )
};
