import moment from 'moment';
import 'moment/locale/ko';

export const STATISTICS_OPTION = "DO_STATISTICS_OPTION";

export const statisticsChange = (statistics) => ({ type: STATISTICS_OPTION, ...statistics });

const initalState = {
  start_date : moment(),
  end_date : moment(),
  parameter : 80
};

export default function statisticsControl (state = initalState, action) {
  switch (action.type) {
    case STATISTICS_OPTION:
      return {
        ...state,
        start_date: action.start_date,
        end_date: action.end_date,
        parameter: action.parameter
      };

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};
