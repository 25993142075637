import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';

import Drawer from '@mui/material/Drawer';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

// controller
import DOMailController from '../../apis/dailyorder/DOMailController';
import Chart from "react-apexcharts";

// heartit component
import DOFileProductTable from '../../components/tables/DOFileProductTable';

import css_table from '../../css/table.module.css';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const drawerWidth = 360;

const transaction_fee = 4000;
const transaction_inter = 5;
const transaction_inter_ratio = (100 - transaction_inter) / 100;

const Main = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  }),
);

function DOFilePage(props){

  const { file_key } = useParams();
  const navigate = useNavigate();

  const [ params, setParams ] = useState({
    total : 0,
    matched : 0,
    title : "",
    date : new Date()
  });

  const [ chart, setChart ] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedReference, setSelectedReference] = useState({});

  const [ products, setProducts ] = useState([]);

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleReviewItem = (id, reviewed) => {

    const newProducts = products.map(obj => {
      if(obj.id === id){
        return { ...obj, reviewed : reviewed};
      }
      return obj;
    });
    setProducts((prev) => newProducts);
  };

  const handleClick = (id) => {
    setOpen(true);
    const found = products.find(el => el.id === id);
    if(found){
      console.log(found);
      setSelectedReference((prev) => (found));
      setSelectedProducts((prev) => found.match.map((mat) => ({
        ...mat
      })) );
    }
  };

  const handleClickCopy = (code) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(code);
    handleSnackBar("해당 코드가 클립보드에 복사되었습니다", "success");
  }

  const getFilesSuccess = (response) => {

    if(response.data.code === 200){
      const data = response.data;
      console.log(data);
      const match = data.products.filter((el) => (el.match.length > 0) );
      setParams((prev) => ({ ...prev,
        total : data.products.length,
        matched : match.length,
        title : data.name,
        date : data.email.date
      }));

      setProducts( (prev) => data.products.map((product, index) => {
        product.match.sort(function(a,b){
          return b.indicator.color - a.indicator.color;
        });
        let per = -100;
        let likes = 0;
        product.match.forEach((el) => {
          if (Object.keys(el.indicator).includes("color") ? el.indicator.color > 0.99 : el.indicator.indicator > 0.99){

            if (el.type === "KREAM"){

              try{
                const _like_str = String(el.description.like);
                if(_like_str.indexOf("만") > 0){
                  const k_arr = _like_str.split('만');
                  if(k_arr.length > 0){
                    likes = parseInt(k_arr[0]) * 10000;
                    if(k_arr.length > 1){
                      const _k_arr_temp = parseInt(k_arr[1]);
                      if(!isNaN(_k_arr_temp)){
                        likes += _k_arr_temp;
                      }
                    }
                  }
                }else{
                  const _like = parseInt(el.description.like.split(',').join(''));
                  if(_like > likes){
                    likes = _like
                  }
                }

              }catch(e){
                console.log(e);
                const _like_str = String(el.description.like);

                if(_like_str.indexOf("만") > 0){
                  const k_arr = _like_str.split('만');
                  if(k_arr.length > 0){
                    likes = parseInt(k_arr[0]) * 10000;
                    if(k_arr.length > 1){
                      likes += parseInt(k_arr[1]);
                    }
                  }
                }
              }
            }
            console.log(index,likes);

            try{
              const sell =  product.price.value;
              const buy =  el.price.target;

              const _per = (buy*transaction_inter_ratio - sell - transaction_fee)/sell*100;
              if(_per > per){
                per = _per;

              }
            }catch(e){
              console.log(e);
            }
          }
        });
        return ({
        ...product,
        id : index,
        _id : product._id,
        match_num : product.match.length,
        like : likes,
        reviewed : product.reviewed ? product.reviewed : false,
        expected_ror : per
      });
    }));
    }
  };
  const getFileError = (error) => {

  };

  const handleReview = (event) => {
    navigate(`/do/review/${file_key}`);
  }

  useEffect(() => {
    DOMailController.getFileData(file_key, getFilesSuccess, getFileError);
  }, []);

  useEffect(() => {
    const chart_option = selectedProducts.map((selectedProduct, index) => {
      let _chart = {}
      if(selectedProduct.price.target_size && Object.keys(selectedProduct.price.target_size).length > 0){
        const category = Object.keys(selectedProduct.price.target_size).map((el) => el);
        const data = Object.values(selectedProduct.price.target_size).map((el) => el.number);

        _chart.options = {
          chart : {
            id : `transaction_chart_${index}`
          },
          xaxis:{
            categories: category
          }
        };
        _chart.series = [{
          name : 'series-1',
          data : data
        }];
      }
      return _chart;
    });
    console.log(chart_option);
    setChart((prev) => chart_option);

  }, [selectedProducts]);

  return (
    <>
      <Main open={open} sx={{width:{md : 'calc(100% - 340px)', sm : '100%'}, boxSizing:'border-box', overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", position:'relative',
        padding:'16px 36px', boxSizing:'border-box'}}>
        <Button onClick={handleReview} variant="outlined" sx={{position:'absolute', top:'24px', right:'36px'}}>리뷰하기</Button>
        <Box sx={{width:'100%'}}>
          <Box sx={{width:'100%', textAlign:'left', mb:'8px'}}>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize:'18px',
                fontWeight:'500',
                color:'#222222',
                mb:'12px'
              }}>
              {params.title}
            </Typography>
            <Typography
              variant="p"
              component="p"
              sx={{
                fontSize:'13px',
                fontWeight:'300',
                color:'#666666',
                mb:'12px'
              }}>
              {moment(params.date).fromNow()}
            </Typography>
            <Stack
              direction='row'
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              sx={{mb:"8px"}}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
                <Typography variant="p" component="p"
                  sx={{
                    fontSize:"15px",
                    textAlign:"left",
                    width:'100%'}}>
                    총 상품 수
                </Typography>
                <Typography variant="p" component="p"
                  sx={{
                    fontSize:"18px",
                    fontWeight:"900",
                    textAlign:"left",
                    width:'100%'}}>
                  {params.total}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
                <Typography variant="p" component="p"
                  sx={{
                    fontSize:"15px",
                    textAlign:"left",
                    width:'100%'}}>
                    매입 상품 수
                </Typography>
                <Typography variant="p" component="p"
                  sx={{
                    fontSize:"18px",
                    fontWeight:"900",
                    textAlign:"left",
                    width:'100%'}}>
                  {params.matched}
                </Typography>
              </Stack>
            </Stack>
          </Box>
          <Box sx={{width:'100%', textAlign:'left', mb:'8px'}}>
            <DOFileProductTable
              file={file_key}
              handleReview={handleReviewItem}
              handleClick={handleClick}
              rows={products} />
          </Box>
        </Box>
      </Main>
      <Drawer
          sx={{
            width: drawerWidth,
            top:'64px',
            height:'calc(100% - 64px)',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              top:'64px',
              height:'calc(100% - 64px)',
              width: drawerWidth,
            },
          }}
          className={css_table.hi_table__container}
          variant="persistent"
          anchor="right"
          open={open}
        >
        {(selectedProducts.length) > 0 &&
          selectedProducts.map((selectedProduct, index) =>
        (
        <Box sx={{width:'100%', padding:'24px', position:'relative', boxSizing:'border-box'}} className={css_table.hi_table__container}>
          <Box sx={{width:'100%', paddingBottom:'100%', position:'relative', marginBottom:'12px'}}>
          {selectedProduct.type === "KREAM" ? (
            <img
              referrerPolicy="no-referrer"
              style={{width:'100%', height:'100%', objectFit:'cover', position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', borderRadius:'6px'}}
              src={selectedProduct.image.length > 0 ? selectedProduct.image[0] : "/heartit_loading_image.png"}/>
          ) : (
            <img
              referrerPolicy="no-referrer"
              style={{width:'100%', height:'100%', objectFit:'contain', position:'absolute', top:'0px', left:'0px', right:'0px', bottom:'0px', borderRadius:'6px'}}
              src={selectedProduct.image.length > 0 ? `/api/daily/image/${selectedProduct.image[0]}` : "/heartit_loading_image.png"}/>
          )}
          </Box>
          <>
            <Typography
              sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textAlign: 'left',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  marginBottom:'8px',
                  fontSize:'12px',
                  fontWeight:'500',
                  color:'#555555'
              }}
              variant="p"
              component="p">
              {selectedProduct.code}
              <IconButton aria-label="copy_text" onClick={handleClickCopy(selectedProduct.code)} size="small">
                <ContentCopyTwoToneIcon sx={{fontSize:'12px'}} />
              </IconButton>
            </Typography>
            <Typography
              sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textAlign: 'left',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  marginBottom:'2px',
                  fontSize:'14px',
                  fontWeight:'900',
                  color:'#222222'
              }}
              variant="p"
              component="p">
              <Link href={`https://kream.co.kr/products/${selectedProduct.queue}`} underline="none" target="_blank">
              {selectedProduct.name.name}
              </Link>
            </Typography>
            <Typography
              sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textAlign: 'left',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  marginBottom:'18px',
                  fontSize:'12px',
                  color:'#555555'
              }}
              variant="p"
              component="p">
              {selectedProduct.name.subname}
            </Typography>
          </>
          {selectedProduct.type === "HEARTIT" && <Box
            sx={{width:'100%', position:'relative'}}>
            <Typography
              sx={{
                  textAlign: 'left',
                  fontSize:'14px',
                  fontWeight:'900',
                  color:'#222222',
                  marginBottom:'12px',
                  position:'relative'
              }}
              variant="p"
              component="p">
              목표 가격
            </Typography>
            <Typography
              sx={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  textAlign: 'left',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  marginBottom:'18px',
                  fontSize:'12px',
                  color:'#555555'
              }}
              variant="p"
              component="p">
              {(Math.round(selectedProduct.price.target/1000)*1000).toLocaleString()} 원
            </Typography>
          </Box>}
          <Box
            sx={{width:'100%', position:'relative'}}>
            <Typography
              sx={{
                  textAlign: 'left',
                  fontSize:'14px',
                  fontWeight:'900',
                  color:'#222222',
                  marginBottom:'12px',
                  position:'relative'
              }}
              variant="p"
              component="p">
              사이즈 별 통계
            </Typography>
          </Box>
          {chart[index] && Object.keys(chart[index]).length > 0 && <Box
            sx={{width:'100%', position:'relative'}}>
            <Chart
              options={chart[index].options}
              series={chart[index].series}
              type="bar"
              width={drawerWidth-48}
            />
          </Box>}
          <Box
            sx={{width:'100%', position:'relative', mb:'12px', whiteSpace:'no-wrap', overflow:'scroll'}} className={css_table.hi_table__container}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              divider={<Divider orientation="vertical" flexItem />}
              alignItems="center"
              spacing={0.5}>
              {selectedProduct.price.target_size && Object.keys(selectedProduct.price.target_size).length > 0 && Object.keys(selectedProduct.price.target_size).map((el) => (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}>
                  <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize:'12px',
                        fontWeight:'900',
                        color:'#222222',
                    }}
                    variant="p"
                    component="p">
                    {el}
                  </Typography>
                  <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize:'12px',
                        fontWeight:'500',
                        color:'#222222',
                    }}
                    variant="p"
                    component="p">
                    {selectedProduct.price.target_size[el].number}
                  </Typography>
                  <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize:'12px',
                        fontWeight:'500',
                        color:'#222222',
                    }}
                    variant="p"
                    component="p">
                    {(Math.round(selectedProduct.price.target_size[el].price/1000)*1000).toLocaleString()}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
          {selectedReference.storage && selectedReference.storage.total && selectedReference.storage.total > 0 && (
            <Box
              sx={{width:'100%', position:'relative', mb:'18px'}}>
              <Typography
                sx={{
                    textAlign: 'left',
                    fontSize:'14px',
                    fontWeight:'900',
                    color:'#222222',
                    marginBottom:'12px',
                    position:'relative'
                }}
                variant="p"
                component="p">
                구입 가능 수량
              </Typography>
              <Typography
                sx={{
                    textAlign: 'left',
                    fontSize:'14px',
                    fontWeight:'900',
                    color:'#222222',
                    marginBottom:'12px',
                    position:'relative'
                }}
                variant="p"
                component="p">
                총 {selectedReference.storage.total} 개
              </Typography>

              <Stack
                direction="row"
                justifyContent="flex-start"
                divider={<Divider orientation="vertical" flexItem />}
                alignItems="center"
                spacing={0.5}>
                {selectedReference.storage.element &&  selectedReference.storage.element.map((el) => (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{paddingLeft:'12px', paddingRight:'12px'}}
                    spacing={1}>
                    <Typography
                      sx={{
                          textAlign: 'center',
                          fontSize:'12px',
                          fontWeight:'900',
                          color:'#222222',
                      }}
                      variant="p"
                      component="p">
                      {el.size}
                    </Typography>
                    <Typography
                      sx={{
                          textAlign: 'center',
                          fontSize:'12px',
                          fontWeight:'500',
                          color:'#222222',
                      }}
                      variant="p"
                      component="p">
                      {el.number}
                    </Typography>
                  </Stack>
                ))}
              </Stack>

            </Box>
          )}
          {selectedProduct.transaction.length > 0 && <>
            <Typography
              sx={{
                  textAlign: 'left',
                  fontSize:'14px',
                  fontWeight:'900',
                  color:'#222222',
                  marginBottom:'12px',
                  position:'relative'
              }}
              variant="p"
              component="p">
              거래 기록 (최근 {selectedProduct.transaction.length}건)
              <Button variant="text" size="small" sx={{position:'absolute', right:'0px', height:'20px', top:'0px'}}>더보기 ❭</Button>
            </Typography>
            <Box
              sx={{width:'100%', position:'relative'}}>
              {selectedProduct.transaction.slice(0, Math.min(selectedProduct.transaction.length, 5)).map((el, index) => (<Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                key={`${index}-transaction-list`}
                sx={{mb:'12px', width:'100%'}}
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}>
                <Typography
                  sx={{
                      textAlign: 'left',
                      fontSize:'12px',
                      fontWeight:'500',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {el.date}
                </Typography>
                <Typography
                  sx={{
                      textAlign: 'left',
                      fontSize:'12px',
                      fontWeight:'500',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {el.price}
                </Typography>
                <Typography
                  sx={{
                      textAlign: 'left',
                      fontSize:'12px',
                      fontWeight:'500',
                      color:'#222222',
                  }}
                  variant="p"
                  component="p">
                  {el.size}
                </Typography>
              </Stack>))}
            </Box>
          </>}
        </Box>
      ))}
      </Drawer>
    </>
  );
}

export default DOFilePage;
