import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import DOProductOfferView from './DOProductOfferView';

import { pink, blue, red, blueGrey } from '@mui/material/colors';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

import moment from 'moment';
import 'moment/locale/ko';

export default function DOProductView(props){

  const navigate = useNavigate();
  const [priceObject ,setPriceObject] = React.useState(props.info.prices.target);

  const productWeight = (weight) => {

  }

  const handleShowProductInfo = () => {
    navigate(`/do/product/info/${props.info.key}`);
  }

  const typeView = (type) => {

    switch(type){
      case DAILY_PRODUCT_TYPE_MUST:
        return (
          <Chip
            sx={{position:'absolute', top:'8px', left:'8px', color:'#ffffff', backgroundColor:blue[500]}}
            label="머스트 상품" size="small" />
        );
      case DAILY_PRODUCT_TYPE_OK:
        return (
          <Chip
            sx={{position:'absolute', top:'8px', left:'8px', color:'#ffffff', backgroundColor:pink[500]}}
            label="OK 상품" size="small" />
        );
      case DAILY_PRODUCT_TYPE_TEST:
        return (
          <Chip
            sx={{position:'absolute', top:'8px', left:'8px', backgroundColor:blueGrey[100]}}
            label="기본 상품" size="small" />
        );
      default :
          return (
            <></>
          )
    }
  }

  return (
    <Card
      elevation={0}
      sx={{ position:'relative', width:"100%" }}>
      <Box sx={{position:'relative', width:'100%', pb:'100%', mb:'12px'}}>
        <CardMedia
          component="img"
          image={props.info.images.length > 0
            ? `/api/daily/image/${props.info.images[0]}` : "/heartit_loading_image.png" }
          sx={{
            position:'absolute',
            top:'0px',
            left:'0px',
            right:'0px',
            bottom:'0px',
            width:'100%',
            height:'100%',
            objectFit:"cover",
            borderRadius:'8px' }}
          alt={`product-images-${props.info.name}`}
        />
        {(props.info.weight.length === 0) ? typeView(DAILY_PRODUCT_TYPE_TEST) : typeView(props.info.weight[0].weight)}
        <Chip
          sx={{position:'absolute', bottom:'8px', right:'8px', backgroundColor:'rgba(255,255,255,.8)'}}
          label={`옵션 : ${props.info.options.length}`} size="small" variant="outlined" color="primary" />
      </Box>
      <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
        <Tooltip title={props.info.brand.name}>
          <Chip label={props.info.brand.name} size="small" sx={{mb:'8px', fontSize:'12px', padding:'0px 8px'}} />
        </Tooltip>
      </Box>
      <Box onClick={handleShowProductInfo} sx={{textAlign:'left', width:'100%', cursor:'pointer', display:'block', position:'relative'}}>
        <Typography variant="p" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          fontSize: '14px',
          mb:'4px',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          }}>
          {props.info.name}
        </Typography>
        <Typography variant="p" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          fontSize: '12px',
          mb:'4px',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          }}>
          {props.info.code}
        </Typography>
        <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"11px", color:"#888888", fontWeight:300}}>
         목표 가격
        </Typography>
        <Typography variant="p" component="p" sx={{mb:'8px', textAlign:"left", fontSize:"14px", color:"#222", fontWeight:700}}>
        {
          (new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.value))
        }
        </Typography>
      </Box>
    </Card>
  );
}
