import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

// heartit controller
import HostController from '../../apis/hosts/HostController';

import { useSnackbar } from 'notistack';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export default function HostAddDialog(props){

  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading]  = useState(false);
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [error, setError] = useState({
    name : false,
    domain : false
  })

  const handleNameInput = (event) => {
    setError({ ...error, name : false });
    setName(event.target.value);
  }

  const handleDomainInput = (event) => {
    setError({ ...error, domain : false });
    setDomain(event.target.value);
  }

  const handleKeyPress = (e) => {
      if(e.keyCode === 13){
         // enter키 누르면 동작
         handleSubmit();
      }
   }

  const handleSubmit = (event) => {
    setIsLoading(true);
    let isValid = true;
    let _error = { name : false, domain : false };
    if(name.length === 0){
      _error.name = true;
      isValid = false;
    }
    if(!urlPattern.test(domain)){
      _error.domain = true;
      isValid = false;
    }
    if(!isValid){
      setError(_error);
      setIsLoading(false);
      return;
    }
    createHost();
  }

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const createSuccess = (success) => {
    if(success.data.code === 200){
      handleSnackBar(`[${name}]이 추가되었습니다`,'success');
      setName("");
      setDomain("");
      setIsLoading(false);
      props.onClose();
    }else{
      setIsLoading(false);
      handleSnackBar(`[${name}] 등록에 실패하였습니다`,'error');
    }
  }

  const createFailed = (error) => {
    setIsLoading(false);
    handleSnackBar(`[${name}] 등록에 실패하였습니다`,'error');
  }


  const createHost = () => {
    HostController.createHost(
      {name : name, hostname : domain},
      createSuccess, createFailed);
  }

  useEffect(() => {

  });

  return (
    <Dialog
       open={props.open}
       onClose={props.onClose}
       fullWidth={true}
       maxWidth={'sm'}
       PaperComponent={PaperComponent}
       aria-labelledby="draggable-dialog-title">
       <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
         호스트 추가
       </DialogTitle>
       <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{pt:"8px"}}
          spacing={2}>
            <Box sx={{width:'100%', height:'48px', mb:"8px"}}>
             <TextField
               id="host-add-dialog-name-input"
               disabled={isLoading}
               error={error.name}
               value={name}
               sx={{minWidth:'80%'}}
               onKeyDown={handleKeyPress}
               onChange={handleNameInput}
               label="호스트 명"
               size="small"
               type="search"
               helperText={error.name ? "호스트 명은 두 글자 이상이어야 합니다" : ""}
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <DomainTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
            <Box sx={{width:'100%', height:'48px'}}>
             <TextField
               id="host-add-dialog-domain-input"
               disabled={isLoading}
               error={error.domain}
               sx={{minWidth:'80%'}}
               value={domain}
               onKeyDown={handleKeyPress}
               onChange={handleDomainInput}
               label="호스트 도메인"
               placeholder="도메인 형식 (http://* or https://*)"
               helperText={error.domain ? "도메인 형식이 잘못되었습니다" : "" }
               size="small"
               type="search"
               InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <LinkTwoToneIcon size="small" />
                 </InputAdornment>
               ),
               }} />
            </Box>
         </Stack>
       </DialogContent>
       <DialogActions>
         <Button onClick={props.onClose}>
           취소하기
         </Button>
         <Button autoFocus variant='outlined' disabled={isLoading} onClick={handleSubmit}>추가하기</Button>
       </DialogActions>
     </Dialog>
  )
};
