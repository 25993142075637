import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// icons
import InputAdornment from '@mui/material/InputAdornment';
import DomainTwoToneIcon from '@mui/icons-material/DomainTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

import { useSnackbar } from 'notistack';

import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// heartit components
import HostSelector from '../inputs/HostSelector';

// heartit controllers
import HostController from '../../apis/hosts/HostController';
import CrawlerController from '../../apis/crawlers/CrawlerController';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const defaultState = {
  data : {
    url : ""
  },
  error : {
    url : false,
    host : false
  }
};
const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()\[\]@:%_\+.~#?&\/=]*)$/;

export default function CrawlerAddDialog(props) {

  const { enqueueSnackbar } = useSnackbar();

  const [dialogState, setDialogState] = useState(defaultState);

  const [loading, setLoading] = useState(false);

  const [selectedHost, setSelectedHost] = useState(null);

  const [value, setValue] = React.useState(moment());
  const [checkNow, setCheckNow] = useState(true);

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleSelectedHost = (event, newValue) => {
    setSelectedHost(newValue);
  }

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleCheckNow = () => {
    setCheckNow(!checkNow);
  }

  const handleUrlInput = (event) => {
    //setError({ ...error, domain : false });
    //setDomain(event.target.value);
    setDialogState({
      data : {
        ...dialogState.data,
        url : event.target.value
      },
      error : {
        ...dialogState.error,
        url : false
      }
    });
  };

  const addCrawlerSuccess = (response) => {
    setLoading(false);
    if(response.data.code === 200){
      handleSnackBar(`크롤러가 추가되었습니다`,'success');
      // 초기화
      setDialogState(defaultState);
      setSelectedHost(null);
      setValue(moment());
      setCheckNow(true);

      props.onClose();
    }else{
      handleSnackBar(`에러가 발생했습니다`,'error');
      setLoading(false);
    }
  };

  const addCrawlerError = (error) => {
    handleSnackBar(`에러가 발생했습니다`,'error');
    setLoading(false);
  };

  const handleAddTask = () => {
    setLoading(true);
    let isValid = true;
    let _error = { url : false, host : false };
    if(!urlPattern.test(dialogState.data.url)){
      _error.url = true;
      isValid = false;
    }
    if(selectedHost === null){
      isValid = false;
      _error.host = true;
    }

    if(!isValid){
      setDialogState({...dialogState, error:_error});
      setLoading(false);
      return;
    }
    CrawlerController.createCrawler({
      url : dialogState.data.url,
      host : selectedHost,
      name : "",
      time : {
        now : checkNow,
        value : {
          minute : value.minute(),
          hour : value.hour()
        }
      }
    }, addCrawlerSuccess, addCrawlerError);
  }

  useEffect(() => {
    if(checkNow){
      setValue(moment());
    }
  }, [checkNow]);

  useEffect(() => {
    if(props.open){
      setLoading(false);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'sm'}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        크롤러 추가하기
      </DialogTitle>
      <DialogContent>
        <HostSelector
          disabled={loading}
          sx={{marginTop:"12px", marginBottom:"24px"}}
          value={selectedHost}
          onChange={handleSelectedHost}/>
        <Box sx={{width:'100%', height:'48px', marginBottom:"24px"}}>
         <TextField
           id="host-add-dialog-domain-input"
           disabled={loading}
           error={dialogState.error.url}
           sx={{minWidth:'100%'}}
           value={dialogState.data.url}
           onChange={handleUrlInput}
           label="크롤링 url"
           placeholder="URL 형식 (http://* or https://*)"
           helperText={dialogState.error.url ? "URL 형식이 잘못되었습니다" : "" }
           size="small"
           type="search"
           InputProps={{
           startAdornment: (
             <InputAdornment position="start">
               <LinkTwoToneIcon size="small" />
             </InputAdornment>
           ),
           }} />
        </Box>
        <Box sx={{width:'100%', height:'48px', marginBottom:"24px"}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}>
            <FormGroup>
              <FormControlLabel control={<Checkbox disabled={loading} checked={checkNow} onChange={handleCheckNow} />} label="즉시" />
            </FormGroup>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Time"
                disableMaskedInput
                disabled={checkNow || loading}
                value={value}
                onChange={handleChange}
                renderInput={(params) => <TextField size="small" {...params} />}/>
            </LocalizationProvider>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          취소하기
        </Button>
        <Button autoFocus disabled={loading} onClick={handleAddTask}>추가하기</Button>
      </DialogActions>
    </Dialog>
  );
}
