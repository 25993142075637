import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOBrandController{
  static getBrand(id, _success, _error){

    const token = getToken();

    axios({
      method : "GET",
      url : `/api/brand/${id}`,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    })

  }
  static createBrand(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/brand/add",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }
      _error(error);
    });
  }
  static updateBrand(data, _success, _error){
    const token = getToken();

    axios({
      method : "POST",
      url : `/api/brand/${data.id}`,
      headers : {
        Authorization: 'Bearer ' + token,
      },
      data : data
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    })
  }
  static deleteBrand(data, _success, _error){
    const token = getToken();

    axios({
      method : "DELETE",
      url : `/api/brand/${data.id}`,
      headers : {
        Authorization: 'Bearer ' + token,
      }
    })
    .then((response) => {
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    })
  }
}
