import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

import Typography from '@mui/material/Typography';

import CategoryInput from '../inputs/CategoryInput';
import CardBasketDialogView from '../products/CardBasketDialogView';

// heartit dialog
import CategorySelectDialog from './CategorySelectDialog';

// heartit controller
import CategoryController from '../../apis/products/CategoryController';
import ProductController from '../../apis/products/ProductController';

import { useSnackbar } from 'notistack';

export default function ProductUploadDialog(props) {

  const [category, setCategory] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [items, setItems] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleCategory = (event) => {
    setCategory(event.target.checked);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleSelectCategoryClose = () => {
    setSelectCategory(false);
  };

  const handleDetailPanel = () => {
    setSelectCategory(true);
  }

  const uploadProductSuccess = (response) => {
    if(response.data.code === 200){
      setSelectedCategory([]);
      handleSnackBar("상품이 등록되었습니다", 'success');
      props.onClose();
    }
  }

  const uploadProductFailed = (error) => {

  }
  const handleCategorySelect = () => {
    if(selectedCategory.length == 0){
      handleSnackBar("카테고리를 선택해주세요", 'error');
      return;
    }
    const newCategory = [...selectedCategory];
    const newItems = [...items];
    const newItem = newItems.map((item) => ({...item, category : newCategory}));
    setItems(newItem);
  }

  const getCategoryList = (categories) => {
    let category = [];
    let repCat = null;
    categories.forEach((cat) => {
      let code = cat.code.split("-");
      category = [...category, ...code];
      if(cat.rep){
        repCat = cat.code;
      }
    });
    category = [...new Set(category)];

    return {rep : repCat, category : category};
  }
  const handleUpload = () => {

    setIsUploading(true);

    const items = props.items.map((el) => {
      const catList = getCategoryList(el.category);
      if(catList.rep === null){
        return {
          id : el.hpid,
          category : catList.category.map(_el => ({code : _el}))
        };
      }else{
        return {
          id : el.hpid,
          category : catList.category.map(_el => ({code : _el})),
          rep : catList.rep
        };
      }

    });
    ProductController.uploadGodoProduct(items, uploadProductSuccess, uploadProductFailed);
    props.onUpload();
  }

  const getCategorySuccess = (response) => {
    if(response.data.code === 200){
      let _category = [];
      const cat = response.data.category;
      cat.forEach((_cat) => {
        let name = "";
        let code = _cat.cateCd;
        if(!_cat.cateNm.startsWith('<img')){
          name = _cat.cateNm;
        }
        if(_cat.hasOwnProperty('children')){
          _cat.children.forEach((_children_cat) => {
            if(!_cat.cateNm.startsWith('<img')){
              name = _cat.cateNm;
            }else{
              name = "";
            }
            if(name.length === 0){
              name = _children_cat.cateNm;
            }else{
              name += ` > ${_children_cat.cateNm}`;
            }
            _category.push({ code : `${code}-${_children_cat.cateCd}`, name : name});
          })
        }else{
          _category.push({ code : code, name : name});
        }
      });
      setCategoryList(_category);
    }
  }

  const getCategoryError = (error) => {

  }

  const handleSelectedCategoryChange = (event, _newValue) => {
    const newValue = _newValue.map(val => {
      if(!val.hasOwnProperty('rep')){
        val.rep = false;
      }
      return val;
    });
    setSelectedCategory(newValue);
  };

  const handleRepCategoryChange = (code) => {
    const _selectedCategory = [...selectedCategory];
    const newValue = _selectedCategory.map(val => {
      if(val.code === code){
        if(!val.rep)
          val.rep = true;
        else val.rep = false;
      }else{
        val.rep = false;
      }
      return val;
    });
    setSelectedCategory(origin => [...newValue]);
  };

  const handleItemCategoryRep = (id, code) => {

    let copiedItems = JSON.parse(JSON.stringify(items));

    const _productIndex = copiedItems.findIndex(element => (element.hpid === id) );
    if(_productIndex === -1){
      return;
    }
    const _category = [...copiedItems[_productIndex].category];

    const category = _category.map(val => {
      if(val.code === code){
        if(!val.rep)
          val.rep = true;
        else val.rep = false;
      }else{
        val.rep = false;
      }
      return val;
    });
    copiedItems[_productIndex] = {...copiedItems[_productIndex], category : category};

    setItems(origin => [...copiedItems]);
  };

  const handleItemCategoryRemove = (id, code) => {
    let copiedItems = JSON.parse(JSON.stringify(items));

    const _productIndex = copiedItems.findIndex(element => (element.hpid === id) );
    if(_productIndex === -1){
      return;
    }
    const _category = [...copiedItems[_productIndex].category];
    const category = _category.filter((item) => (item.code !== code ));

    copiedItems[_productIndex] = {...copiedItems[_productIndex], category : category};
    setItems(origin => [...copiedItems]);
  };

  useEffect(() => {
    CategoryController.getCategoryList(getCategorySuccess, getCategoryError);
  }, []);

  useEffect(() => {
    if(props.open){
      setItems(props.items);
    }
  }, [props.open])

  useEffect(() => {

  }, [items])



  return (
    <>
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      scroll={"paper"}
      open={props.open}
      onClose={handleClose}>
      <DialogTitle>
        업로드 하기
        <Typography component="span" variant="p"
          sx={{fontSize:"14px", color:"#555555", marginLeft:"12px"}}>
          ( {props.items.length}개의 상품 )
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{width:"100%", height:"60px"}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{height:"60px"}}>
            <FormControlLabel
              control={<Checkbox disabled={isUploading} checked={category} onChange={handleCategory}/>}
              label="동일한 카테고리 설정" />
            {category && (<CategoryInput
              value={selectedCategory}
              disabled={isUploading}
              onChange={handleSelectedCategoryChange}
              onRepresent={handleRepCategoryChange}
              onSelect={handleCategorySelect}
              category={categoryList}
              onDetail={handleDetailPanel}/>)}
          </Stack>
        </Box>
        <Grid container spacing={1} sx={{width:"100%"}}>
          {items.map( (item) => (
            <Grid key={`product-upload-item-${item.hpid}`} item xs={12} md={6} xl={6}>
              <CardBasketDialogView
                onCategoryRep={handleItemCategoryRep}
                onCategoryDelete={handleItemCategoryRemove}
                item={item}/>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>취소하기</Button>
        <Button disabled={isUploading} onClick={handleUpload} variant="outlined">업로드하기</Button>
      </DialogActions>
    </Dialog>
    <CategorySelectDialog
      open={selectCategory}
      onClose={handleSelectCategoryClose}/>
    </>
  );
}
