import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function ProductStorageDialog(props) {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(!props.open){
      //setIsLoading(true);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth={'sm'}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
      {isLoading ?
        <Skeleton
            animation="wave"
            height={28}
            width="80%"
            style={{ marginBottom: 6 }}/>
        : props.name}
      </DialogTitle>
      <DialogContent>
        <List sx={{width:'100%'}}>
          {Object.keys(props.options).map((key) => (
            <ListItem
              key={`option-key-${key}`}
              secondaryAction={
                props.options[key].is_soldout ?
                <Chip
                  color='error'
                  sx={{paddingLeft:"4px", paddingRight:'4px'}}
                  icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
                  :
                <Chip
                  color='success'
                  sx={{paddingLeft:"4px", paddingRight:'4px'}}
                  icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
              }>
              <ListItemText
                primary={
                  props.options[key].type.map((value, index) => (
                    <Typography component='p' sx={{fontSize:'12px', color:'#222222'}}>
                    {value} : {props.options[key].name[index]}
                    </Typography>
                  ))
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
