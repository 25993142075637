import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

//  heartit view
import DOOutLinkRowView from '../../components/daily/DOOutLinkRowView';
// controller
import ProductController from '../../apis/products/ProductController';
import DOProductController from '../../apis/dailyorder/DOProductController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import BrandInput from '../../components/inputs/BrandInput';
import DOTypeSelectorView from '../../components/inputs/DOTypeSelectorView';

// heartit dialog
import ProductOptionDialog from '../../components/dialogs/ProductOptionDialog';
import ProductLinkDialog from '../../components/dialogs/ProductLinkDialog';

import {
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST
} from '../../assets/Constants';

import { NumericFormat } from 'react-number-format';
import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator=','
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function DOProductChangePage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [memo, setMemo] = useState("");
  const [images, setImages] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [previousPreviews, setPreviousPreviews] = useState([]);
  const [price, setPrice] = React.useState('');
  const [brandList, setBrandList] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [sizes , setSizes] = useState([]);
  const [colors , setColors] = useState([]);
  const [options , setOptions] = useState([]);
  const [productType, setProductType] = useState(DAILY_PRODUCT_TYPE_TEST);
  const [error, setError] = useState({
    name : false,
    code : false,
    price : false,
    memo : false,
    brand : false
  });

  const [platformLinks, setPlatformLinks] = useState([]);

  const [productOptionDialogOpen, setProductOptionDialogOpen] = useState(false);
  const [productLinkDialogOpen, setProductLinkDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleNameInput = (event) => {
    setName(event.target.value);
  }

  const handleCodeInput = (event) => {
    setCode(event.target.value);
  }

  const handlePriceInput = (event) => {
    setPrice(event.target.value);
  }

  const handleMemoInput = (event) => {
    setMemo(event.target.value);
  }

  const handleBrandChange = (event, newValue) => {
    setBrandList(newValue);
  };

  const handleOpenOptionDialog = (event) =>  {
    setProductOptionDialogOpen(true);
  }

  const handleProductOptionDialogClose = () => {
    setProductOptionDialogOpen(false);
  }

  const handleOpenLinkDialog = (event) =>  {
    setProductLinkDialogOpen(true);
  }

  const handleProductLinkDialogClose = () => {
    setProductLinkDialogOpen(false);
  }

  const handleImageChange = (files) => {
    if(files.length === 0) return;
    if(files.length + previews.length > 6){
      handleSnackBar("이미지의 수량은 6장 까지 입니다", "error");
      return;
    }
    let tempImage = [];
    let attempt = 0;
    Array.from(files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        attempt++;
        tempImage.push({
          url : reader.result,
          title : file.name,
          file : file,
          key : `${Math.random().toString(16).slice(2)}-image-file`
        });
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.onerror = () => {
        attempt++;
        if(attempt === files.length){
            setPreviews([ ...previews, ...tempImage ])
        }
      };
      reader.readAsDataURL(file);
    })
  };

  const handleRegister = (event) => {
    setIsUploading(true);
    if(name.length === 0){
      setIsUploading(false);
      return;
    }
    if(code.length === 0){
      setIsUploading(false);
      return;
    }
    /*if(memo.length === 0){
      setIsUploading(false);
      return;
    }*/
    if(isNaN(parseFloat(price))){
      setIsUploading(false);
      return;
    }
    if(brandList === null){
      setIsUploading(false);
      return;
    }
    const data = {
      key : params.id,
      name : name,
      code : code,
      description : memo,
      images : {
        origin : previousPreviews,
        update : previews.map((preview) => ({
          name : preview.title,
          file : preview.file
        }))
      },
      price : price,
      brand : brandList,
      options : options,
      platform : platformLinks,
      type : productType
    };
    console.log("data",data);
    DOProductController.updateProduct(data, uploadProductSuccess, uploadProductError);
  }

  const handleProductOptionDialogData = (colorString, sizeString) => {
    let new_color = [...colors];
    const _colors = colorString.split(",");
    _colors.forEach((color) => {
      if(color.trim().length > 0){
        if (new_color.indexOf(color.trim()) === -1){
          handleSnackBar(`색상 [${color}]가 추가되었습니다`,'success');
          new_color.push(color.trim());
          //setColors([...colors, color.trim()]);
        }else{
          handleSnackBar(`색상 [${color}]가 이미 존재합니다.`,'error');
        }
      }
    });
    //setColors(new_color);

    let new_size = [...sizes];
    const _sizes = sizeString.split(",");
    _sizes.forEach((size) => {
      if(size.trim().length > 0){
        if (new_size.indexOf(size.trim()) === -1){
          handleSnackBar(`사이즈 [${size}]가 추가되었습니다`,'success');
          new_size.push(size.trim());
        }else{
          handleSnackBar(`사이즈 [${size}]가 이미 존재합니다.`,'error');
        }
      }
    });
    //setSizes(new_size);
    if(new_color.length === 0){
      new_color = ['U']
    }
    if(new_size.length === 0){
      new_size = ['U']
    }
    updateOption(new_color, new_size);
    setProductOptionDialogOpen(false);
  }

  const handleProductLinkDialogData = (platform, platformLink) => {
    setPlatformLinks((prev) => [...prev, { id : (Math.random() + 1).toString(36).substring(2), name : platform, url : platformLink, added : true}]);
  }

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleProductType = (value) => {
    setProductType(value);
  }

  const handleIntialize = (event) => {
    setMemo("");
    setColors([]);
    setSizes([]);
    setName("");
    setBrandList(null);
    setCode("");
    setPrice('');
  }

  const handleDeleteColor = (color) => (event) => {
    let _colors = [...colors];
    _colors.splice(_colors.indexOf(color.trim()), 1);
    setColors(_colors);
  };

  const handleDeleteOption = (option) => (event) => {
    const _options = [...options];
    const filtered = _options.filter((item) => item.key !== option.key);
    setOptions((prev) => filtered);
  };

  const handleDeleteSize = (size) => (event) => {
    let _sizes = [...sizes];
    _sizes.splice(_sizes.indexOf(size.trim()), 1);
    setSizes(_sizes);
  };

  const uploadProductSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      handleSnackBar("성공적으로 상품이 수정되었습니다", "success");
      navigate(-1);
    }
    setIsUploading(false);
  }

  const uploadProductError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  const getProductSuccess = (response) => {
    if(response.data.code === 200){
      const data = response.data.product;
      console.log(data);
      setName(data.name);
      setBrandList(data.brand);
      setCode(data.code);
      setPrice(data.prices.target.value);
      setPreviousPreviews(data.images);
      const _option = data.options.map((item) => ({
        ...item,
        added : false
      }))
      setOptions(_option);
      setMemo(data.description);
    }
  };

  const handleDeletePlatform = (item) => (event) => {
    const _platform = [ ...platformLinks ];
    const _newPlatform = _platform.filter((_item) => _item.id !== item.id );
    setPlatformLinks(_prev => _newPlatform);
  };

  const getProductError = (error) => {

  };

  const handleDeleteImage = (prev, item) => {

      if(prev){
        const _prev = [...previousPreviews];
        const _previousPreviews = _prev.filter((_item) => _item !== item);
        setPreviousPreviews((pre) => _previousPreviews);
      }else{
        const _prev = [...previews];
        const _previews = _prev.filter((_item) => _item.key !== item.key);
        setPreviews((pre) => _previews);
      }
  };

  const optionToString = (obj) => {
    return `${obj.size}-${obj.color}`;
  }

  useEffect(() => {
    DOProductController.getProductInfo({key : params.id}, getProductSuccess, getProductError);
  }, []);

  useEffect(() => {

  }, [previews]);
  const updateOption = (color, size) => {
    if(size.length > 0 || color.length > 0){
      let newOptions = [];
      size.forEach((size, _i1) => {
        color.forEach((color, _i2) => {
          newOptions.push({
            key : (Math.random() + 1).toString(36).substring(2),
            added : true,
            option : {
              size: size,
              color: color
            }
          });
        })
      });

      let uniqueObjArray = [...new Map([...options, ...newOptions].map((item) => [optionToString(item["option"]), item])).values()];

      setOptions(prev => uniqueObjArray);
    }
  }
  useEffect(() => {
    /*if(sizes.length > 0 || colors.length > 0){
      let newOptions = [];
      sizes.forEach((size, _i1) => {
        colors.forEach((color, _i2) => {
          newOptions.push({
            key : `${_i1}-${_i2}`,
            new : true,
            options : {
            size: size,
            color: color
            }
          });
        })
      });
      console.log(options, newOptions);
      setOptions([...options, ...newOptions]);
    }*/
  }, [sizes, colors]);

  return (
    <Box sx={{width:{md : '100%', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Grid container sx={{position:'relative', width:'100%'}} spacing={2}>
        <Grid item md={6}>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              상품 명
            </Badge>
          </Typography>
          <TextField
            id="do-product-name"
            hiddenLabel
            value={name}
            onChange={handleNameInput}
            placeholder="상품명"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            대표 이미지
          </Typography>
          <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
            <ImageUploader
              value={images}
              onChange={handleImageChange}
              sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
              {(previews.length || previousPreviews.length) > 0 && (
                <>
                  <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                    업로드 이미지 수 : {previews.length + previousPreviews.length}
                  </Typography>
                  <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                    {previousPreviews.map((item) => (
                      <ImageListItem key={item}>
                        <img
                          src={`/api/daily/image/${item}`}
                          srcSet={`/api/daily/image/${item}`}
                          alt={"previous-image"}
                          loading="lazy"
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(true, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                    {previews.map((item) => (
                      <ImageListItem key={item.key}>
                        <img
                          src={item.url}
                          srcSet={item.url}
                          alt={item.title}
                          loading="lazy"
                        />
                        <IconButton
                          onClick={() => { handleDeleteImage(false, item); }}
                          sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </>
              )}
          </Box>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              상품 브랜드
            </Badge>
          </Typography>
          <BrandInput
            sx={{width:'100%', maxWidth:'400px',mb:'24px'}}
            value={brandList} onChange={handleBrandChange}
            multiple={false}/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              상품 코드
            </Badge>
          </Typography>
          <TextField
            id="do-product-code"
            hiddenLabel
            value={code}
            disabled={isUploading}
            onChange={handleCodeInput}
            placeholder="상품코드"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              목표 가격
            </Badge>
          </Typography>
          <TextField
            id="do-product-code"
            hiddenLabel
            placeholder="가격"
            disabled={isUploading}
            name="numberformat"
            value={price}
            onChange={handlePriceInput}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">원</InputAdornment>,
              inputComponent: NumberFormatCustom,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'12px'}}
            size="small"
            variant="outlined"/>
          <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
            <Typography
              variant="h6"
              component="p"
              sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
              <Badge color="error" variant="dot">
                옵션 관리
              </Badge>
            </Typography>
            <IconButton
              onClick={handleOpenOptionDialog}
              disabled={productOptionDialogOpen}
              sx={{position:'absolute', right:'8px', top:'0px'}}>
              <AddIcon />
            </IconButton>
          </Box>
          <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
          {options.length === 0 ? (
            <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
              단일 색상 / 단일 사이즈
            </Typography>
          ):(
            options.map((option) => (
              <Box key={`color-list-${option.key}`} sx={{position:'relative', width:'100%', borderBottom:'1px solid #dddddd', paddingTop:'8px'}}>
                <Typography variant="h6" component="div" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
                  {
                    Object.keys(option.option).map((value, index) => (
                      <Typography component='p' key={`option-text-${index}`} sx={{fontSize:'12px', color:'#222222'}}>
                      {value} : {option.option[value]}
                      </Typography>
                    ))
                  }
                </Typography>
                <IconButton
                  onClick={handleDeleteOption(option)}
                  sx={{position:'absolute', right:'12px', top:'18px'}} aria-label="delete" size="small">
                  <DeleteForeverTwoToneIcon fontSize="small" />
                </IconButton>
              </Box>
            ))
          )}
          </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
              <Typography
                variant="h6"
                component="p"
                sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700}}>
                <Badge color="error" variant="dot">
                  상품 종류
                </Badge>
              </Typography>
              <DOTypeSelectorView
                type={productType}
                onChange={handleProductType}/>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
              <Typography
                variant="h6"
                component="p"
                sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700}}>
                <Badge color="error" variant="dot">
                  외부 링크
                </Badge>
              </Typography>
              <IconButton
                onClick={handleOpenLinkDialog}
                disabled={productLinkDialogOpen}
                sx={{position:'absolute', right:'8px', top:'0px'}}>
                <AddIcon />
              </IconButton>
            </Box>
            <Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
            {platformLinks.map( (platform, index) => (
                <DOOutLinkRowView
                  key={`platform-row-${index}`}
                  platform={platform.name}
                  platform_link={platform.url}
                  onDelete={handleDeletePlatform(platform)}/>
            ))}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
              추가 사항
            </Typography>
            <TextField
                id="standard-multiline-static"
                hiddenLabel
                disabled={isUploading}
                sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
                placeholder="기타 기입할 사항을 입력해주세요"
                multiline
                value={memo}
                onChange={handleMemoInput}
                InputLabelProps={{
                  shrink: true,
                }}
                rows={4}
                variant="outlined"
              />
          </Grid>
          <Grid item sm={12} md={12}>
            <Box sx={{width:'100%', mb:'32px'}}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{width:'100%'}}
                spacing={2}>
                <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">수정 취소</Button>
                <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
                <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">수정하기</Button>
              </Stack>
            </Box>
          </Grid>
      </Grid>
      <ProductOptionDialog
        open={productOptionDialogOpen}
        onReceive={handleProductOptionDialogData}
        onClose={handleProductOptionDialogClose}/>
      <ProductLinkDialog
        open={productLinkDialogOpen}
        onReceive={handleProductLinkDialogData}
        onClose={handleProductLinkDialogClose}/>
    </Box>
  );
}

export default DOProductChangePage;
