import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';


import {
  LoginPage,
  FindPasswordPage,
} from '../containers'

export default function NonSignedRoute(props){
  return (
    <Routes>
      <Route path='/' element={<LoginPage setToken={props.setToken} />} />
      <Route path='/auth/find' element={<FindPasswordPage setToken={props.setToken} />} />
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  );
}
