import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

//icon list
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import PlaylistAddTwoToneIcon from '@mui/icons-material/PlaylistAddTwoTone';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import CheckIcon from '@mui/icons-material/Check';


import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';

// heartit controller
import LikeController from '../../apis/products/LikeController';

import { pink, blue, red } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CardView(props) {

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const [expanded, setExpanded] = React.useState(false);
  const [selected, setSelected] = React.useState(props.isSelected);
  const [liked, setLiked] = React.useState(false || props.info.is_hearted);
  const [isLikeLoading, setLikeLoading] = React.useState(false);

  const [priceObject ,setPriceObject] = React.useState(Object.values(props.info.prices)[0]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const removeLikeSuccess = (response) => {
    if(response.data.code === 200){
      setLiked(false);
      handleSnackBar("관심상품에서 제외되었습니다", "success");
    }else{
      handleSnackBar("관심상품에서 제외하지 못했습니다", "error");
    }
    setLikeLoading(false);
  };

  const removeLikeError = (response) => {
    handleSnackBar("관심상품에서 제외하지 못했습니다", "error");
    setLikeLoading(false);
  };

  const createLikeSuccess = (response) => {
    if(response.data.code === 200){
      setLiked(true);
      handleSnackBar("관심상품에 추가되었습니다", "success");
    }else{
      handleSnackBar("관심상품에 추가하지 못했습니다", "error");
    }
    setLikeLoading(false);
  };

  const createLikeError = (response) => {
    handleSnackBar("관심상품에 추가하지 못했습니다", "error");
    setLikeLoading(false);
  };

  const handleLiked = () => {
    if(!isLikeLoading){
      setLikeLoading(true);
      const product_id = props.info.hpid;
      if(liked){
        LikeController.removeLike(product_id,removeLikeSuccess,removeLikeError);
      }else{
        LikeController.createLike(product_id,createLikeSuccess,createLikeError);
      }
    }
  };
  const handleShowDetail = (event) => {
    navigate(`/product/item/${props.info.hpid}`);
  }

  const handleSelected = () => {
    if(props.info.godo_goods_no && props.info.godo_goods_no.length >= 6){
      handleSnackBar("이미 등록된 상품입니다", "error");
    }else{
      props.onSelect(!props.isSelected);
    }

    //setSelected(!selected);
  };

  const getTimestamp = (id) => {
    let timestamp = id.toString().substring(0,8);
    let date = new Date( parseInt( timestamp, 16 ) * 1000 );
    return moment(date).fromNow();
  }

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }
  const storageChip = (status) => {
    switch(status){
      case 0 : return (
        <Chip
          color='error'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<RemoveShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'품절'} />
      );
      case 1 : return (
        <Chip
          color='success'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'재고있음'} />
      );
      case 2 : return (
        <Chip
          color='warning'
          clickable
          onClick={props.onStorage}
          sx={{paddingLeft:"4px", paddingRight:'4px'}}
          icon={<ShoppingCartTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={'부분재고'} />
      );
      default : return (<></>);
    }
  }

  /*useEffect(() => {
    props.onSelect(props.isSelected);
  },[props.isSelected]);*/

  return (
    <Card
      elevation={0}
      sx={{ position:'relative', width:"100%", border:(props.isSelected ? `solid 1px ${blue[500]}`:"solid 1px #eee") }}>
      <CardHeader
        title={
          <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
          <Tooltip title={props.info.brand}>
            <Chip label={props.info.brand} size="small" sx={{marginBottom:'4px', fontSize:'12px'}} />
          </Tooltip>
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '11px',
            color:'#666666',
            marginBottom:'4px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            }}>
            {props.info.host}
          </Typography>
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '14px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            }}>
            <a style={{textDecoration:'none', color:'#222222'}} target="_blank" href={props.info.url}>{props.info.name}</a>
          </Typography>
          </Box>
        }
        subheader={
          <>
            <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#999"}}>
              <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"12px", marginRight:'6px', color:"#999", textDecoration:'line-through'}}>
              {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.retail.$numberDecimal))}
              </Typography>
              <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
              {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.sale.$numberDecimal))}
              </Typography>
            </Typography>
            <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"12px", color:"#444444"}}>
              {props.info.sku}
            </Typography>
          </>
        }
      />
      <Box sx={{position:'relative', width:'100%', height:'194px'}} onClick={props.onImage}>
        <CardMedia
          component="img"
          height="194"
          image={props.info.images.length > 0 ? props.info.images[0] : "" }
          sx={{objectFit:"contain"}}
          alt={`product-images-${props.info.name}`}
        />
        <Box sx={{position:'absolute', bottom:'12px', right:'12px'}}>
          <Chip icon={<ImageTwoToneIcon size="small" />} size="small" label={props.info.images.length} />
        </Box>
      </Box>
      <CardContent>
        <Typography variant="p" component="p" sx={{fontSize:"12px", color:"#444", textAlign:"left", mb:"2px"}}>
          등록일 : {getTimestamp(props.info._id.$oid)}
        </Typography>
        <Typography variant="p" component="p" sx={{fontSize:"12px", color:"#444", textAlign:"left", mb:"8px"}}>
          마지막 업데이트 : {lastTimestamp(props.info.crawled_at.$date)}
        </Typography>
        <Typography variant="body2" color="text.secondary" component="p" sx={{
          textAlign:"left",
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          }}>
          {props.info.description}
        </Typography>
        <Stack
          sx={{marginTop:'8px'}}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>
          {props.info.delivery && <Chip
            color={(props.info.delivery === 0) ? "primary" : "secondary"}
            sx={{paddingLeft:"4px", paddingRight:'4px'}}
            icon={<LocalShippingTwoToneIcon size="small" sx={{marginRight:'4px !important'}} />} size="small" label={
            (props.info.delivery === 0) ? "국내배송" : "해외배송"
          } />}
          {storageChip(props.info.is_soldout ? 0 :
          Object.values(props.info.options).filter((item) => item.is_soldout).length == 0 ?
          1 : 2)
          }
        </Stack>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          onClick={handleLiked}
          aria-label="add to favorites">
          <FavoriteIcon sx={{color:(liked ? pink[500] : "rgba(0, 0, 0, 0.54)")}}/>
        </IconButton>
        <IconButton aria-label="share" onClick={handleSelected}>
          {(props.isSelected ? <PlaylistRemoveIcon /> : <PlaylistAddTwoToneIcon />)}
        </IconButton>
        <Button onClick={handleShowDetail} variant="text" sx={{marginLeft:'auto'}}>
         자세히 보기
         <ArrowRightTwoToneIcon />
        </Button>
      </CardActions>
      { (props.info.godo_goods_no && props.info.godo_goods_no.length >= 6) &&
        <IconButton aria-label="fingerprint" color="secondary" sx={{
          position:'absolute',
          top:'8px',
          right:'8px'
          }}>
          <CheckIcon size="small" sx={{color:blue[700]}} />
        </IconButton>}
    </Card>
  );
}
