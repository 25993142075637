import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

// color set
import { red } from '@mui/material/colors';

export default function CardBasketDialogView(props) {
  const theme = useTheme();

  const [priceObject ,setPriceObject] = React.useState(Object.values(props.item.prices)[0]);

  const handleChipClick = (code) => (event) => {
    props.onCategoryRep(props.item.hpid, code);
  }

  const handleDelete = (code) => (event) => {
    props.onCategoryDelete(props.item.hpid, code);
  }

  return (
    <Card
      elevation={0}
      sx={{ display: 'flex', width:"100%",
        border:(props.item.category.length > 0 ? "1px solid #eeeeee" : `1px solid ${red[500]}`)}}>
      <CardMedia
        component="img"
        image={props.item.images.length > 0 ? props.item.images[0] : "" }
        sx={{objectFit:"contain", width: {md : 84, lg : 151} }}
        alt={`product-images-${props.item.name}`}/>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5"
            sx={{fontSize:"16px"}}>
            {props.item.name}
          </Typography>
          <Typography variant="p" component="p" sx={{textAlign:"left", fontSize:"14px", color:"#999", mb:"8px"}}>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"12px", marginRight:'6px', color:"#999", textDecoration:'line-through'}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.retail.$numberDecimal))}
            </Typography>
            <Typography variant="p" component="span" sx={{textAlign:"left", fontSize:"14px", color:"#444", fontWeight:900}}>
            {(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.sale.$numberDecimal))}
            </Typography>
          </Typography>
          {(props.item.category.length === 0) ? (
            <Typography variant="p"
              component="p"
              sx={{fontSize:"12px", color:red[500] }}>
              카테고리가 지정되지 않았습니다
            </Typography>
          ) : (
            <Box sx={{width:'100%'}}>
            {props.item.category.map((cat) => (
              <Chip
                clickable
                key={`category-chip-${cat.code}`}
                onClick={handleChipClick(cat.code)}
                onDelete={handleDelete(cat.code)}
                sx={{mr:'4px', mb:'4px'}}
                variant={cat.rep ? "filled" : "outlined"}
                color="primary"
                label={cat.name}
                size="small"/>
            ))}
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}
