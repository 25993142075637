import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import PivotTableChartTwoToneIcon from '@mui/icons-material/PivotTableChartTwoTone';

// heartit view
import DOProductView from '../../components/daily/DOProductView';
import DOViewTypeSelector from '../../components/inputs/DOViewTypeSelector';
import DOKeywordInput from '../../components/inputs/DOKeywordInput';

import { debounce } from 'lodash';

import {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW,
  DAILY_ORDER_PRODUCT_VIEW,
  DAILY_ORDER_TIME_VIEW,
  DAILY_ORDER_AGENT_VIEW
} from '../../assets/Constants';
// controller
import ProductController from '../../apis/products/ProductController';
import DOProductController from '../../apis/dailyorder/DOProductController';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

function DOProductListPage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(0);
  const [viewType, setViewType] = useState(PRODUCT_CARD_VIEW);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [searchQuery, setSearchQuery] = useState({
    keyword : ""
  })

  const [componentHeight, setComponentHeight] = useState({
    actionBar : 0,
    filterTop : 0
  });
  const refList = {
    actionBar : useRef(null),
    filterTop : useRef(null),
    product_list: useRef(null)
  };

  const handleAddProduct = (event) => {
    navigate("/do/product/add");
  };

  const handleFileProduct = (event) => {
    navigate("/do/product/add/file");
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleKeywordKeydown = (event) => {
    if(event.keyCode === 13){
      setPage(0);
      setIsLoading(true);
      setIsEnd(false);
      setSearchQuery({...searchQuery, keyword : keyword});
      scrollToTop();
      DOProductController.getProduct({page : 0, keyword : keyword}, getProductFirstSuccess, getProductError);
    }
  };

  const scrollToTop = () => {
      refList.product_list.current.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
  }


  const getProductSuccess = (response) => {
    setIsLoading(false);
    if(response.data.code === 200){
      //setProducts();
      setPage(page+1);
      if(response.data.page === 0){
        setCount(response.data.count);
      }
      if(response.data.products.length < 24){
        setIsEnd(true);
      }
      setProducts(prev => [...prev, ...response.data.products]);
    }
  };

  const getProductFirstSuccess = (response) => {
    setIsLoading(false);
    if(response.data.code === 200){
      //setProducts();
      setPage(page+1);
      if(response.data.page === 0){
        setCount(response.data.count);
      }
      if(response.data.products.length < 24){
        setIsEnd(true);
      }
      setProducts(response.data.products);
    }
  };

  const getProductError = (error) => {
    setIsLoading(false);
  };

  const handleViewTypeChange = (type) => {

  };

  const _onScroll = () => {
    if (refList.product_list.current) {
      const { scrollTop, scrollHeight, clientHeight } = refList.product_list.current;
      if (scrollTop + clientHeight >= scrollHeight - 20) {

        if(!isEnd && !isLoading){
          setIsLoading(true);
          DOProductController.getProduct({...searchQuery, page : page+1}, getProductSuccess, getProductError);
        }

      }
    }
  };

  const onScroll = useCallback(
		debounce(_onScroll , 200),
		[page, isEnd, isLoading],
	);

  useEffect(() => {
    /*setComponentHeight({...componentHeight,
      filterTop :  refList.filterTop.current.clientHeight
    });*/
  });

  useEffect(() => {
    setIsLoading(true);
    DOProductController.getProduct({page : page}, getProductSuccess, getProductError);
  }, []);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{position:{md : 'absolute', sm:'relative'}, zIndex:1, top:'24px', right:'32px',mb:'12px'}}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Button variant="outlined" color="primary" onClick={handleAddProduct} sx={{backgroundColor:"#ffffff"}}>
            한상품 추가하기
          </Button>
          <Button variant="outlined" color="primary" onClick={handleFileProduct} sx={{backgroundColor:"#ffffff"}}>
            파일로 추가하기
          </Button>
        </Stack>
      </Box>

      <Box
        ref={refList.product_list}
        sx={{ position:'relative', left:{xs : '0px', md:'240px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}
        onScroll={onScroll}>
        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <Stack
            direction='row'
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{mb:"8px"}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                등록 상품수
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {count}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                시즌 상품수
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {count}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                주문 상품수
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                0
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Grid container item sx={{width:"100%", overflow:"scroll"}} spacing={2}>
          {products.length > 0 ? (
          products.map((el) => (
            ( viewType === PRODUCT_CARD_VIEW ) ?
            <Grid item key={`product-${el.id}`} xs={12} md={4} lg={3} xl={2} sx={{paddingRight:"4px", paddingBottom:"4px"}}>
              <DOProductView
                info={el}/>
            </Grid> :
            <Grid item key={`product-list-${el.id}`} xs={12} sx={{ paddingBottom:"4px"}}>

            </Grid>
          ))
          ) : (<></>)}
        </Grid>
      </Box>
      <Box sx={{position:'fixed', width:'240px', left:'0px', height:'100%', backgroundColor:'#ffffff', top:'0px', display:{xs:'none', md:'block'}}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', padding:'24px', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"16px",
              fontWeight:"500",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            상품 뷰 기준
          </Typography>
          <DOViewTypeSelector
            onChange={handleViewTypeChange}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"16px",
              fontWeight:"500",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색어
          </Typography>
          <DOKeywordInput
            value={keyword}
            onChange={handleKeywordChange}
            onKeyPress={handleKeywordKeydown}/>
        </Box>
      </Box>
    </Box>
  );
}

export default DOProductListPage;
