import React, {useEffect, useState, useRef} from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

export default function DOKeywordInput(props){
  return (
    <TextField
      id="outlined-basic"
      label="검색어"
      size="small"
      variant="outlined"
      value={props.value}
      onChange={props.onChange}
      onKeyDown={props.onKeyPress}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchTwoToneIcon />
          </InputAdornment>
        ),
      }} />
  );
};
