import moment from 'moment';
import 'moment/locale/ko';

export const DAILY_ORDER_FILE_LIST_OPTION = "DAILY_ORDER_FILE_LIST_OPTION";

export const dailyOrderChange = (dailyOrder) => ({ type: DAILY_ORDER_FILE_LIST_OPTION, ...dailyOrder });

const initalState = {
  fl_start_date : moment().subtract({days:3}),
  fl_end_date : moment()
};

export default function dailyOrderFileListControl (state = initalState, action) {
  switch (action.type) {
    case DAILY_ORDER_FILE_LIST_OPTION:
      return {
        ...state,
        fl_start_date: action.start_date,
        fl_end_date: action.end_date
      };

    // default를 쓰지 않으면 맨처음 state에 count값이 undefined가 나옵니다 꼭! default문을 넣으세요
    default:
      return state;
  }
};
