import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SoldoutCheckBox(props) {
  const [checked, setChecked] = React.useState(props.value);

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  useEffect(() => {
    props.onChange(checked);
  },[checked]);


  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'row', ml: 0 }}>
      <FormControlLabel
        label="판매 중"
        control={<Checkbox checked={checked[0]} size="small" onChange={handleChange2} />}
      />
      <FormControlLabel
        label="품절"
        control={<Checkbox checked={checked[1]} size="small" onChange={handleChange3} />}
      />
    </Box>
  );

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{width:"100%"
      }}>
      <FormControlLabel
        label="전체"
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}
    </Stack>
  );
}
