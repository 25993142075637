import React, {useState, useEffect} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';

// color set
import { red, teal } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko'

import TextsmsTwoToneIcon from '@mui/icons-material/TextsmsTwoTone';

moment.locale('ko');

export default function CrawlerListView(props){

  const activeText = (active) => {
    switch(active){
      case 0 : return (
        <Typography variant='p' component='span'
          sx={{textAlign:'left', fontSize:'14px', color:red[500]}}>
          비활성화
        </Typography>
      );
      case 1 : return (
        <Typography variant='p' component='span'
          sx={{textAlign:'left', fontSize:'14px', color:teal[500]}}>
          활성화
        </Typography>
      );
      default : return (
        <>
        </>
      )
    }
  };

  const handleMemoOpen = () => {
    props.onMemo();
  };

  const handleCrawlerChange = () => {
    props.onChange();
  };

  return (
    <Box
      sx={{
        width:'100%',
        p:2,
        borderRadius:'4px',
        boxSizing:'border-box',
        borderBottom:'1px solid #dddddd'}}>
        <Grid
          container
          spacing={1}
          sx={{width:'100%', boxSizing:'border-box'}}>
          <Grid
             item sx={12} md={2} lg={2} xl={2}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{mb:'12px', width:'100%'}}>
              <Chip label={props.host.name} size="small" component="a" href="#basic-chip" clickable />
            </Stack>
          </Grid>
          {/*<Grid
             item sx={12} md={3} lg={3} xl={3}>
            <Typography variant='p' component='p'
              sx={{textAlign:'left', fontSize:'16px'}}>
              {props.name}
            </Typography>
          </Grid>*/}
          <Grid
            item sx={12} md={3} lg={4} xl={4}>
            <a href={props.url} target="_blank" style={{textDecoration:"none"}}>
              <Typography variant='p' component='p'
                sx={{
                  textAlign:'left',
                  fontSize:'12px',
                  color:'#555555',
                  mb:'12px',
                  whiteSpace:'nowrap',
                  textOverflow:'ellipsis',
                  overflow:'hidden'
                }}>
                {props.url}
              </Typography>
            </a>
          </Grid>
          <Grid
            item sx={12} md={3} lg={3} xl={3}>
            <Typography variant='p' component='p'
              sx={{textAlign:'left', fontSize:'13px', mb:'4px'}}>
              {props.active ? moment(props.lastTime).fromNow() : '-'}
            </Typography>
          </Grid>
          <Typography variant='p' component='p'
            sx={{textAlign:'left', fontSize:'13px', mb:'4px'}}>
            크롤링 주기 : 매일 {props.active && props.cron ? `${props.cron.hour}:${props.cron.minute}` : '-'}
          </Typography>
          {props.memo.length > 0 && <Box sx={{width:'100%', position:'relative'}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{mb:'12px'}}
              spacing={1}>
              <Typography variant='p' component='p'
                sx={{textAlign:'left', fontSize:'13px'}}>
                마지막 메모 :
              </Typography>
              <Typography variant='p' component='p'
                sx={{textAlign:'left', fontSize:'13px', whiteSpace: 'pre-line'}}>
                { props.memo[0].memo }
              </Typography>
            </Stack>
          </Box>}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{width:'100%'}}>
            {activeText(props.active ? 1 : 0)}
            <IconButton
              sx={{marginLeft:'auto !important'}}
              onClick={handleMemoOpen}
              aria-label="memo-button">
              <Badge color="primary" badgeContent={props.memo.length}>
                <TextsmsTwoToneIcon size='small'/>
              </Badge>
            </IconButton>
            <Button
              onClick={handleCrawlerChange}
              variant='outlined'
              size='small'>
              수정하기
            </Button>
          </Stack>
      </Grid>
    </Box>
  )
}
