import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(date, total, total_over10, ok, must, test, history) {
  return {
    date,
    total,
    total_over10,
    ok,
    must,
    test,
    history: history,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.date}
        </TableCell>
        <TableCell align="right">{(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.total))}</TableCell>
        <TableCell align="right">{(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.total_over10))}</TableCell>
        <TableCell align="right">{(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.must))}</TableCell>
        <TableCell align="right">{(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.ok))}</TableCell>
        <TableCell align="right">{(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(row.test))}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                브랜드별 정보
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>브랜드 명</TableCell>
                    <TableCell>바잉 가능</TableCell>
                    <TableCell>+10% 이내 바잉 가능</TableCell>
                    <TableCell>Must</TableCell>
                    <TableCell>OK</TableCell>
                    <TableCell>Test</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.length > 0 && row.history.map((historyRow) => (
                    <TableRow key={historyRow.brand.id}>
                      <TableCell component="th" scope="row">
                        {historyRow.brand.brand}
                      </TableCell>
                      <TableCell>
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(historyRow.total))}
                      </TableCell>
                      <TableCell>
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(historyRow.total_over10))}
                      </TableCell>
                      <TableCell>
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(historyRow.must))}
                      </TableCell>
                      <TableCell>
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(historyRow.ok))}
                      </TableCell>
                      <TableCell>
                      {(new Intl.NumberFormat('KR', { style: 'currency', currency: 'KRW' }).format(historyRow.test))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    total: PropTypes.number.isRequired,
    total_over10: PropTypes.number.isRequired,
    ok: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.shape({
          brand : PropTypes.string.isRequired,
          id : PropTypes.number.isRequired
        }),
        total: PropTypes.number.isRequired,
        total_over10: PropTypes.number.isRequired,
        must: PropTypes.number.isRequired,
        test: PropTypes.number.isRequired,
        ok: PropTypes.number.isRequired,
      }),
    ).isRequired,
    date: PropTypes.string.isRequired,
    must: PropTypes.number.isRequired,
    test: PropTypes.number.isRequired,
  }).isRequired,
};


export default function DOStatisticsTable(props) {

  const [rows, setRows] = useState(props.data);

  useEffect(() => {
    if(props.data && props.data.length > 0){
      
      const newRows = props.data.map((data) => createData(data.date, data.total, data.over10, data.ok, data.must, data.test, Object.values(data.history) ));
      setRows(newRows);
    }
  }, [props.data]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>일자</TableCell>
            <TableCell align="right">바잉 가능</TableCell>
            <TableCell align="right">+10% 이내 바잉가능</TableCell>
            <TableCell align="right">MUST</TableCell>
            <TableCell align="right">OK</TableCell>
            <TableCell align="right">TEST</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.date} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
