import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import OfflinePinTwoToneIcon from '@mui/icons-material/OfflinePinTwoTone';
import UnpublishedTwoToneIcon from '@mui/icons-material/UnpublishedTwoTone';
import PlaylistAddCheckCircleTwoToneIcon from '@mui/icons-material/PlaylistAddCheckCircleTwoTone';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

import css_table from '../../css/table.module.css';

import { useSnackbar } from 'notistack';

import { red, green, orange } from '@mui/material/colors';

import {
  MIN_HIGH_TRANSACTION, MIN_MIDDLE_TRANSACTION,
  MIN_HIGH_ROR, MIN_MIDDLE_ROR
} from '../../assets/Constants';

const columns = [
  { id: 'image', label: '이미지', minWidth: 102,
    print: (value) => (
      <img
        referrerPolicy="no-referrer"
        style={{width:'96px', height:'96px', objectFit:'covered'}}
        src={value.length > 0 ? value[0] : ""}/>
    )
  },
  { id: 'name', label: '상품 명', minWidth: 170,
    print: (value) => (
      <Link href={`https://kream.co.kr/products/${value.queue}`} underline="none" target="_blank">
      <Typography
        sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            fontSize:'14px',
            fontWeight:'900',
            color:'#222222'
        }}
        variant="p"
        component="p">
        {value.name}
      </Typography>
      <Typography
        sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            fontSize:'12px',
            color:'#555555'
        }}
        variant="p"
        component="p">
        {value.subname}
      </Typography>
      </Link>
    )
  },
  { id: 'code', label: '코드', minWidth: 100, isCanCopy : true },
  { id: 'brand', label: '브랜드', minWidth: 100 },
  {
    id: 'sell_price',
    label: '판매 가격',
    minWidth: 120,
    align: 'center',
    print: (value) => (
      <>
      <Typography
        sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            fontSize:'14px',
            fontWeight:'900',
            color:'#222222'
        }}
        variant="p"
        component="p">
        { (Math.round(value.target/1000)*1000).toLocaleString()}
      </Typography>
      </>
    ),
  },
  {
    id: 'platform',
    label: '판매 플랫폼\n(관심 수)',
    minWidth: 100,
    align: 'center',
    print: (value) => (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <Typography variant="p" component="p" sx={{color:'#222222'}}>
          {value.name}
        </Typography>
        <Typography variant="p" component="p" sx={{color:'#222222'}}>
          {value.populate}
        </Typography>
      </Stack>
    )
  },
  {
    id: 'volume_power',
    label: '거래 강도',
    minWidth: 120,
    align: 'center',
    print: (value) => (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <Typography variant="p" component="p" sx={{color: ((value > 3) ? green[500] : (value > 1) ? orange[500] : red[500] ) }}>
          {value.toFixed(2)}
        </Typography>
        <Typography variant="p" component="p" sx={{color: ((value > 3) ? green[500] : (value > 1) ? orange[500] : red[500] ) }}>
          {value > 3 ? "높음" : value > 1 ? "중간" : "낮음"}
        </Typography>
      </Stack>
    )
  },
  {
    id: 'in_stock',
    label: '구입 가능',
    minWidth: 120,
    align: 'center',
    print: (value) => (value === 1) ? (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{width:'100%'}}
        spacing={1}>
        <OfflinePinTwoToneIcon
          sx={{ color: green[500] }}
          fontSize="small"/>
        <Typography variant="p" component="p" sx={{color:green[500]}}>
          구입 가능
        </Typography>
      </Stack>
    )
    : (value === -1) ? (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <UnpublishedTwoToneIcon
          sx={{ color: red[500] }}
          fontSize="small"/>
        <Typography variant="p" component="p" sx={{color:red[500]}}>
          구입 불가
        </Typography>
      </Stack>
    )
    : (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}>
        <PlaylistAddCheckCircleTwoToneIcon
          sx={{ color: orange[500] }}
          fontSize="small"/>
        <Typography variant="p" component="p" sx={{color:orange[500]}}>
          비슷한 상품
        </Typography>
      </Stack>
    ),
  },
  {
    id: 'expected_ror',
    label: '예상 수익률',
    minWidth: 170,
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];


export default function DORecommendTable(props) {
  const [rows, setRows] = useState(props.hiData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if(rows.length <= newPage * rowsPerPage){
      props.handleUpdate();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (code) => (event) => {
    props.handleClick(code);
  }

  const handleClickCopy = (code) => (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(code);
    handleSnackBar("해당 코드가 클립보드에 복사되었습니다.", "success");
  }

  useEffect(() => {
    setRows(props.hiData);
    console.log(props.hiData.filter(el => el.expected_ror >= 10 && el.in_stock).length);
  }, [props.hiData]);

  return (
    <Paper elevation={4} sx={{ width: '100%', overflow: 'hidden', borderRadius:'16px' }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 282px)' }} className={css_table.hi_table__container}>
        <Table stickyHeader aria-label="sticky table" className={css_table.hi_table__container}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, whiteSpace:'pre-line' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={css_table.hi_table__container}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`${row.code}-${index + page * rowsPerPage}`} onClick={handleRowClick(row.id)}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : column.print ?
                            column.print(value)
                            : value
                          }
                          {column.isCanCopy && (
                            <IconButton aria-label="copy_text" onClick={handleClickCopy(value)} size="small">
                              <ContentCopyTwoToneIcon sx={{fontSize:'12px'}} />
                            </IconButton>)
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
