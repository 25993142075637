import React, {useEffect, useState} from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function CategoryInput(props){

  const [category, setCategory] = useState(props.category);

  const handleChipClick = (code) => (event) => {
    props.onRepresent(code);
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}>
      <Autocomplete
          multiple
          id="tags-outlined-category-input"
          size="small"
          options={category}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          value={props.value}
          onChange={props.onChange}
          filterSelectedOptions
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                clickable
                onClick={handleChipClick(option.code)}
                variant={option.rep ? "filled" : "outlined"}
                color="primary"
                label={option.name}
                size="small"
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{minWidth:{xs:"250px", md:"400px",lg:"400px"}, maxWidth:'450px'}}
              label="카테고리"
              placeholder="카테고리를 입력해주세요"
            />
          )}/>
        <Button onClick={props.onSelect} variant="outlined">적용하기</Button>
      {/*<Button onClick={props.onDetail} variant="outlined">직접 선택</Button>*/}
    </Stack>
  );
}
