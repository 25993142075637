import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
// dialog
import HostAddDialog from '../../components/dialogs/HostAddDialog';
import HostChangeDialog from '../../components/dialogs/HostChangeDialog';
import SimpleDialog from '../../components/dialogs/SimpleDialog';

import { useSnackbar } from 'notistack';
// heartit element
import HostElementView from '../../components/hosts/HostElementView';

// heartit controller
import HostController from '../../apis/hosts/HostController';

import axios from 'axios';

const nullHost = {
  id : -1,
  name : "",
  hostname : "",
  user : -1
};

export default function HostListPage(props){

  const { enqueueSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState('');
  const [hosts, setHosts] = useState([]);
  const [updatedHost, setUpdatedHost] = useState(nullHost);
  const [removeHost, setRemoveHost] = useState(nullHost);

  const [open, setOpen] = useState({
    add : false,
    change : false,
    delete:false
  });

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const getHosts = (response) => {
    if(response.data.code === 200){
      setHosts(response.data.hosts);
    }
  }

  const handleErrorHosts = (error) => {

  }

  const afterRemoveHost = (response) => {
    if(response.data.code === 200){
      handleSnackBar(`[${removeHost.name}]이 삭제되었습니다`,'success');
      setRemoveHost(nullHost);
    }
  }

  const handleErrorRemoveHost = (error) => {

  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleHostChange = (id) => {
    setUpdatedHost( hosts.find(element => (element.id === id) ) );
  };

  const handleDeleteTask = () => {
    setOpen({ ...open, delete : false});
    HostController.removeHost(removeHost.id, afterRemoveHost, handleErrorRemoveHost);
  };

  useEffect(() => {
    if(updatedHost.id != -1){
      setOpen({ ...open, change : true });
    }
  }, [updatedHost]);

  const handleHostRemove = (id) => {
    setRemoveHost( hosts.find(element => (element.id === id) ) );
    setOpen({ ...open, delete : true});
  };

  const handleAddHost = (event) => {
    setOpen({ ...open, add : true });
  };

  const handleDialogClose = (attr) => (event) => {
    setOpen({ ...open, [attr] : false});
  };

  useEffect(() => {
    HostController.getHosts({}, getHosts, handleErrorHosts)
  }, [open]);

  return (
    <Box sx={{width:'100%', bgColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px'}}>
        호스트 목록
      </Typography>
      <Stack
        direction='row'
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{width:'100%', mb:'24px'}}>
        <TextField
          id="outlined-search"
          value={keyword}
          onChange={handleKeywordChange}
          label="키워드"
          size="small"
          type="search"
          InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoToneIcon size="small" />
            </InputAdornment>
          ),
          }} />
          <Button
            variant="outlined"
            onClick={handleAddHost}
            size="small"
            sx={{height:'40px'}}>호스트 추가하기</Button>
      </Stack>
      <Grid
        container
        spacing={1}>
        {hosts.map( (host) => (
          <Grid item md={6} lg={6} key={`host-list-${host.id}`}>
            <HostElementView
              host={host}
              onChange={handleHostChange}
              onRemove={handleHostRemove}/>
          </Grid>
        ))}
      </Grid>
      <HostChangeDialog
        open={open.change}
        onClose={handleDialogClose('change')}
        host={updatedHost}/>
      <HostAddDialog
        open={open.add}
        onClose={handleDialogClose('add')}/>
      <SimpleDialog
        open={open.delete}
        onClose={handleDialogClose('delete')}
        onPositive={handleDeleteTask}
        title={'호스트 삭제'}
        content={`[${removeHost.name}]을 정말 삭제하시겠습니까?`}
        positive='삭제하기'/>
    </Box>
  );
}
