const PRODUCT_CARD_VIEW = 100;
const PRODUCT_LIST_VIEW = 200;

const DAILY_ORDER_PRODUCT_VIEW = 150;
const DAILY_ORDER_TIME_VIEW = 250;
const DAILY_ORDER_AGENT_VIEW = 350;

const CRAWLER_VIEW_TYPE_LIST = 10;
const CRAWLER_VIEW_TYPE_CARD = 20;

const DAILY_PRODUCT_TYPE_MUST = 100;
const DAILY_PRODUCT_TYPE_OK = 200;
const DAILY_PRODUCT_TYPE_TEST = 300;

const MIN_HIGH_TRANSACTION = 10;
const MIN_MIDDLE_TRANSACTION = 4;

const MIN_HIGH_ROR = 15;
const MIN_MIDDLE_ROR = 7;

export {
  PRODUCT_CARD_VIEW,
  PRODUCT_LIST_VIEW,
  DAILY_ORDER_PRODUCT_VIEW,
  DAILY_ORDER_TIME_VIEW,
  DAILY_ORDER_AGENT_VIEW,
  CRAWLER_VIEW_TYPE_LIST,
  CRAWLER_VIEW_TYPE_CARD,
  DAILY_PRODUCT_TYPE_MUST,
  DAILY_PRODUCT_TYPE_OK,
  DAILY_PRODUCT_TYPE_TEST,
  MIN_HIGH_TRANSACTION,
  MIN_MIDDLE_TRANSACTION,
  MIN_HIGH_ROR,
  MIN_MIDDLE_ROR
};
