import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import DateInput from '../../components/inputs/DateInput';
import AdjustProductTable from '../../components/tables/AdjustProductTable';
import DOParameterInput from '../../components/inputs/DOParameterInput';

// controller
import DOStatisticsController from '../../apis/dailyorder/DOStatisticsController';

import moment from 'moment';
import 'moment/locale/ko';

const ADJUSTMENT_LIST = [
  {
    key : "I-SN3848837-1",
    type : "inven-95",
    model : "A01FW702-WHITE",
    brand : "Maison Mihara Yashiro",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MDhfMjA1/MDAxNjU5OTQ4MjkyNTU2.Z5ALHf6kR4BxNthWacHgpSJ0iKRgHU8ToCC1KzRVUYwg.KkeO0SCfny5nOCQRfW1leZd0A4ZfGKoRI2itkBN6Q2Ug.PNG/a_bfd6450b185f4b438d94847627230509.png?type=l_webp",
    size : "EU 37",
    storage : 1,
    target_storage : 1,
    original_price : 279174,
    sell_price : 339000,
    least_price : 342000,
    now_price : 291000,
  },
  {
    key : "I-AC3871948-1",
    type : "inven-95",
    model : "X08396PR818-T8013",
    brand : "Diesel",
    image : "https://kream-phinf.pstatic.net/MjAyMjExMDFfMTQz/MDAxNjY3Mjc0MzE2MTkz.h5rmhhvqvvNupiSy6c25q23lMIL-EYbRPl45u3B7clUg.PjJtaLrcyP0k4JQMPTr0BpFM22tihZMbLBhRumnz7hQg.JPEG/a_bfa0574f934d49cf95fdd65b7d7c238d.jpg?type=l_webp",
    storage : 1,
    target_storage : 1,
    size : "U",
    original_price : 350790,
    sell_price : 425000,
    least_price : 422000,
    now_price : 430000,
  },
  {
    key : "A-SW33950891",
    type : "normal",
    model : "GU00154KJ0010 P199",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    storage : 2,
    target_storage : 1,
    size : "XL",
    brand : "Maison Kitsune",
    original_price : 78086,
    sell_price : 90000,
    least_price : 82000,
    now_price : 61000,
  },
  {
    key : "A-SW35109349",
    type : "normal",
    model : "GU00154KJ0010 P199",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    storage : 1,
    target_storage : 1,
    size : "L",
    brand : "Maison Kitsune",
    original_price : 78086,
    sell_price : 99000,
    least_price : 93000,
    now_price : 70000,
  },
  {
    key : "A-SW35130227",
    type : "normal",
    model : "GU00154KJ0010 P199",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MTBfMjIz/MDAxNjYwMTE5NzQ2NzI4.LeNdfyhKyq6G05z9cAZLUp25zS1PalQOxc7Digdx_PUg.c6LX13ygeK2xkuWoNZSGUIItfvkZaNOItKyF6RfHHWkg.JPEG/a_e7c94f9b918c47d4b89b77b205965ed5.jpg?type=m",
    storage : 1,
    target_storage : 1,
    size : "XXL",
    brand : "Maison Kitsune",
    original_price : 78086,
    sell_price : 105000,
    least_price : 101000,
    now_price : 0,
  },
  {
    key : "A-AC34672113",
    type : "normal",
    model : "223AC001-5001-110",
    image : "https://kream-phinf.pstatic.net/MjAyMzAxMDlfMjI2/MDAxNjczMjQ3NzM4Njcy.DYEjlsiyATrcJSAsO-0M5YZfpgjYnCjZVhiK4RFXGrgg.b3RV65LScxtTI25T1LqSDJgvrSfOHk3Vu3jSVHh84tMg.JPEG/a_f3c3c2f08fd341bea54f2e03bebe21ba.jpg?type=m",
    storage : 1,
    target_storage : 0,
    brand : "Jacquemus",
    size : "58",
    original_price : 90250,
    sell_price : 101000,
    least_price : 89000,
    now_price : 68000,
  },
  {
    key : "A-CL0001",
    type : "normal",
    model : "A062680AFAA 100",
    image : "https://kream-phinf.pstatic.net/MjAyMzA0MDRfMjM4/MDAxNjgwNTk1ODcyNjk2.Bx9eMa7C8VbAHwtl6BHvaf6e7tZyTFsYMDdIffrUrtkg._jgR9sS9IE-G--U6SJd4VrOaFjPATxMgaCMq2xAf0Jkg.JPEG/a_4e4a2f7e837348fab54b26fefcb78178.jpg?type=l_webp",
    storage : 1,
    brand : "Diesel",
    target_storage : 1,
    size : "XS",
    original_price : 72480,
    sell_price : 117000,
    least_price : 117000,
    now_price : 88000,
  },
  {
    key : "A-CL0002",
    type : "normal",
    model : "A062680AFAA 100",
    image : "https://kream-phinf.pstatic.net/MjAyMzA0MDRfMjM4/MDAxNjgwNTk1ODcyNjk2.Bx9eMa7C8VbAHwtl6BHvaf6e7tZyTFsYMDdIffrUrtkg._jgR9sS9IE-G--U6SJd4VrOaFjPATxMgaCMq2xAf0Jkg.JPEG/a_4e4a2f7e837348fab54b26fefcb78178.jpg?type=l_webp",
    storage : 3,
    brand : "Diesel",
    target_storage : 2,
    size : "S",
    original_price : 72480,
    sell_price : 131000,
    least_price : 130000,
    now_price : 115000,
  },
  {
    key : "A-CL0003",
    type : "normal",
    model : "A062680AFAA 100",
    image : "https://kream-phinf.pstatic.net/MjAyMzA0MDRfMjM4/MDAxNjgwNTk1ODcyNjk2.Bx9eMa7C8VbAHwtl6BHvaf6e7tZyTFsYMDdIffrUrtkg._jgR9sS9IE-G--U6SJd4VrOaFjPATxMgaCMq2xAf0Jkg.JPEG/a_4e4a2f7e837348fab54b26fefcb78178.jpg?type=l_webp",
    storage : 2,
    brand : "Diesel",
    target_storage : 1,
    size : "M",
    original_price : 72480,
    sell_price : 139000,
    least_price : 137000,
    now_price : 115000,
  },
  {
    key : "A-CL0004",
    type : "normal",
    model : "A062680AFAA 9XX",
    image : "https://kream-phinf.pstatic.net/MjAyMjEwMzFfMjI2/MDAxNjY3MjEwMjA1MTI5.WxwY61uv4DFLAkpRB0a3aVxIb-b-8e5JKDoTrItKAZ8g.PvEY2UyFInwLKPA0agn72cMJGgnMNcpjA7v16cRNu6Ag.JPEG/a_c18b27d6e06d4cbf9f4f3c11e92defb3.jpg?type=l_webp",
    storage : 2,
    brand : "Diesel",
    target_storage : 1,
    size : "XS",
    original_price : 72480,
    sell_price : 159000,
    least_price : 157000,
    now_price : 133000,
  },
  {
    key : "A-CL0005",
    type : "normal",
    model : "A062680AFAA 9XX",
    image : "https://kream-phinf.pstatic.net/MjAyMjEwMzFfMjI2/MDAxNjY3MjEwMjA1MTI5.WxwY61uv4DFLAkpRB0a3aVxIb-b-8e5JKDoTrItKAZ8g.PvEY2UyFInwLKPA0agn72cMJGgnMNcpjA7v16cRNu6Ag.JPEG/a_c18b27d6e06d4cbf9f4f3c11e92defb3.jpg?type=l_webp",
    storage : 6,
    brand : "Diesel",
    target_storage : 2,
    size : "S",
    original_price : 72480,
    sell_price : 145000,
    least_price : 143000,
    now_price : 135000,
  },
  {
    key : "A-CL0006",
    type : "normal",
    model : "A062680AFAA 9XX",
    image : "https://kream-phinf.pstatic.net/MjAyMjEwMzFfMjI2/MDAxNjY3MjEwMjA1MTI5.WxwY61uv4DFLAkpRB0a3aVxIb-b-8e5JKDoTrItKAZ8g.PvEY2UyFInwLKPA0agn72cMJGgnMNcpjA7v16cRNu6Ag.JPEG/a_c18b27d6e06d4cbf9f4f3c11e92defb3.jpg?type=l_webp",
    storage : 5,
    brand : "Diesel",
    target_storage : 2,
    size : "M",
    original_price : 72480,
    sell_price : 135000,
    least_price : 132000,
    now_price : 125000,
  },
  {
    key : "A-CL0007",
    type : "normal",
    model : "A062680AFAA 9XX",
    image : "https://kream-phinf.pstatic.net/MjAyMjEwMzFfMjI2/MDAxNjY3MjEwMjA1MTI5.WxwY61uv4DFLAkpRB0a3aVxIb-b-8e5JKDoTrItKAZ8g.PvEY2UyFInwLKPA0agn72cMJGgnMNcpjA7v16cRNu6Ag.JPEG/a_c18b27d6e06d4cbf9f4f3c11e92defb3.jpg?type=l_webp",
    storage : 1,
    brand : "Diesel",
    target_storage : 1,
    size : "L",
    original_price : 72480,
    sell_price : 128000,
    least_price : 125000,
    now_price : 0,
  },
  {
    key : "A-CL0009",
    type : "normal",
    model : "1BZ677-RV44-F0002",
    image : "https://kream-phinf.pstatic.net/MjAyMjA3MDRfNTMg/MDAxNjU2OTAwNzUzMzcw.cC72W9wb-B1Zo5YEcvf6-nz8r2j4GszmyfZA2LBXQQsg.uC1b-mSmt3P0GmfFX0ak_u10OQx-iuHSGdHWJ0Eg_gog.JPEG/a_6eb2d465aef54c52b3c25e00482670b4.jpg?type=l_webp",
    storage : 2,
    brand : "Prada",
    target_storage : 1,
    size : "U",
    original_price : 1933040,
    sell_price : 2350000,
    least_price : 2348000,
    now_price : 2200000,
  },
  {
    key : "A-CL0010",
    type : "normal",
    model : "223BA0443072 811",
    image : "https://kream-phinf.pstatic.net/MjAyMzA3MTJfMjMy/MDAxNjg5MTUzNTY3NTk4.CWjW0wmaMwDFIIK7Gluen5GEA8NWDxPslYgiAiVPiocg.Xh2Oe8T0ntM9rkf3yEyUbkNL_AQyeOLSky8G8-r1Gbgg.JPEG/a_81520356f50842d49bb439f32a74db90.jpg?type=l_webp",
    storage : 5,
    brand : "Jacquemus",
    target_storage : 0,
    size : "U",
    original_price : 342340,
    sell_price : 389000,
    least_price : 388000,
    now_price : 0,
  },
  {
    key : "I-SN3848837-1",
    type : "inven",
    model : "A01FW702-WHITE",
    brand : "Maison Mihara Yashiro",
    image : "https://kream-phinf.pstatic.net/MjAyMjA4MDhfMjA1/MDAxNjU5OTQ4MjkyNTU2.Z5ALHf6kR4BxNthWacHgpSJ0iKRgHU8ToCC1KzRVUYwg.KkeO0SCfny5nOCQRfW1leZd0A4ZfGKoRI2itkBN6Q2Ug.PNG/a_bfd6450b185f4b438d94847627230509.png?type=l_webp",
    storage : 1,
    target_storage : 1,
    size : "EU 37",
    original_price : 279174,
    sell_price : 359000,
    least_price : 350000,
    now_price : 299000
  },
];

function AdjustmentProductPage(props){

  const {
    __statistics,
    __statisticsChange
  } = props;

  const [startDate, setStartDate] = useState(moment(__statistics.start_date));
  const [endDate, setEndDate] = useState(moment(__statistics.end_date));
  const [isWorking, setIsWorking] = React.useState(false);
  const [parameter, setParameter] = React.useState([__statistics.parameter, 100]);

  const [totalValue, setTotalValue] = React.useState({
    normal : {
      number : 0,
      original : 0,
      sell : 0,
      adjust : 0,
    },
    inven : {
      number : 0,
      original : 0,
      sell : 0,
      adjust : 0,
    }
  });

  const [adjustList, setAdjustList] = React.useState([]);

  const [viewType, setViewType] = React.useState('date');

  const handleViewTypeChange = (event, type) => {
    setViewType(type);
  };

  const handleStartDateInputChange = (value) => {
    setStartDate(value);
  }

  const handleEndDateInputChange = (value) => {
    setEndDate(value);
  }

  const handleParameterChange = (event, newValue) => {
    setParameter(newValue);
  };

  const handleSearchClick = (event) => {
    setTotalValue({
      normal : {
        number : 0,
        original : 0,
        sell : 0,
        adjust : 0,
      },
      inven : {
        number : 0,
        original : 0,
        sell : 0,
        adjust : 0,
      }
    });

    getTotalStatistics();
  }

  const getTotalStatistics = () => {
    let _number=0, _original=0, _sell=0, _adjust=0;
    let _i_number=0, _i_original=0, _i_sell=0, _i_adjust=0;
    let r = ADJUSTMENT_LIST.map((el) => {
      console.log(el);
      if(el.target_storage > 0){
        if(el.type === "normal"){
          _number += el.target_storage;
          _original += el.original_price * el.target_storage;
          _sell += el.sell_price * el.target_storage;
          _adjust += (el.sell_price * 0.934 - 3000) * el.target_storage;

        }else if(el.type === "inven" || el.type === "inven-95"){
          _i_number += el.target_storage;
          _i_original += el.original_price * el.target_storage;
          _i_sell += el.sell_price * el.target_storage;
          _i_adjust += (el.sell_price * 0.934 - 3000) * el.target_storage;
        }
        return ({
          image : el.image,
          brand : el.brand,
          key : el.key,
          model : el.model,
          size : el.size,
          storage : el.storage,
          target : el.target_storage,
          original_price : el.original_price,
          sell_price : el.sell_price,
          adjust_price : el.sell_price*0.934 - 3000,
          profit : el.sell_price*0.934 - el.original_price - 3000,
          profit_percent : (el.sell_price*0.934 - el.original_price - 3000) / (el.sell_price*0.934 - 3000) * 100
        });
      }
    });
    r = r.filter(item => item);
    console.log(r);
    setAdjustList(r);

    setTotalValue((prev) => ({
      normal : {
        number : _number,
        original : _original,
        sell : _sell,
        adjust : _adjust,
      },
      inven : {
        number : _i_number,
        original : _i_original,
        sell : _i_sell,
        adjust : _i_adjust,
      }
    }));
  }

  useEffect(() => {

    /*DOStatisticsController.getKreamStatistics({
      parameter : parameter[0],
      range : {
        start : startDate.format('YYYY-MM-DD'),
        end : endDate.format('YYYY-MM-DD')
      }
    }, getStatisticsDataSuccess, getStatisticsDataError);*/
    getTotalStatistics();
  }, []);

  useEffect(() => {
    __statisticsChange({
      start_date : startDate,
      end_date : endDate,
      parameter : parameter[0]
    });
  }, [startDate, endDate, parameter]);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{ position:'relative', left:{xs : '0px', md:'240px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}>

      <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
        <Typography variant="p" component="p"
          sx={{
            fontSize:"16px",
            textAlign:"left",
            mb:'12px',
            fontweight:900,
            width:'100%'}}>
            일반판매 목표 (2월 5주차)
        </Typography>
        <Stack
          direction='row'
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{mb:"12px", border:'1px solid #dddddd', borderRadius:'4px'}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
                수량
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
                {totalValue.normal.number}
              {/*(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.total.toFixed(0)))*/}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              매입가
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.normal.original.toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              정산가
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.normal.adjust.toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              이익
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format((totalValue.normal.adjust - totalValue.normal.original).toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              수익률 (%)
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {((totalValue.normal.adjust - totalValue.normal.original) / totalValue.normal.adjust * 100).toFixed(1)}
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="p" component="p"
          sx={{
            fontSize:"20px",
            textAlign:"left",
            mb : '12px',
            fontweight:900,
            width:'100%'}}>
            보관판매 목표 (2월 5주차)
        </Typography>
        <Stack
          direction='row'
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{mb:"12px", border:'1px solid #dddddd', borderRadius:'4px'}}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
                수량
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
                {totalValue.inven.number}
              {/*(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.total.toFixed(0)))*/}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              매입가
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.inven.original.toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              정산가
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.inven.adjust.toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              이익
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format((totalValue.inven.adjust - totalValue.inven.original).toFixed(0)))}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{p:2}}>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"15px",
                textAlign:"left",
                width:'100%'}}>
              수익률 (%)
            </Typography>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                fontWeight:"900",
                textAlign:"left",
                width:'100%'}}>
              {((totalValue.inven.adjust - totalValue.inven.original) / totalValue.inven.adjust * 100).toFixed(1)}
            </Typography>
          </Stack>
        </Stack>

        {/*<Typography variant="p" component="p" sx={{textAlign:'left', fontSize:'12px', color:'#777777'}}>
        최종 업데이트 : 2분전
        </Typography>
        */}
      </Box>

        <AdjustProductTable
          rows={adjustList}/>
      </Box>
      <Box sx={{position:'fixed', width:'240px', left:'0px', height:'100%', backgroundColor:'#ffffff', top:'0px', display:{xs:'none', md:'block'}}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', padding:'24px', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 시작일
          </Typography>
          <DateInput
            onChange={handleStartDateInputChange}
            value={startDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 종료일
          </Typography>
          <DateInput
            onChange={handleEndDateInputChange}
            value={endDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            상품 리스트
          </Typography>
          <Stack
            sx={{mt:'12px', width:'100%'}}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <ToggleButtonGroup
              color="primary"
              value={viewType}
              exclusive
              sx={{width:'100%'}}
              onChange={handleViewTypeChange}
              aria-label="Platform">
              <ToggleButton value="date">일자별</ToggleButton>
              <ToggleButton value="product">상품별</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack
            sx={{mt:"12px", width:'100%'}}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            <Button disabled={isWorking} onClick={handleSearchClick} variant="outlined">
              검색하기
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default AdjustmentProductPage;
