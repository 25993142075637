import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

// icons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import RemoveShoppingCartTwoToneIcon from '@mui/icons-material/RemoveShoppingCartTwoTone';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import LensIcon from '@mui/icons-material/Lens';
import SaveAltTwoToneIcon from '@mui/icons-material/SaveAltTwoTone';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// controller
import DOBrandController from '../../apis/dailyorder/DOBrandController';
import ToolController from '../../apis/tools/ToolController';

// heartit input
import ImageUploader from '../../components/inputs/ImageUploader';
import BrandInput from '../../components/inputs/BrandInput';
import ImageStaticUploadDialog from '../../components/dialogs/ImageStaticUploadDialog';

import { NumericFormat } from 'react-number-format';
import '../../css/font/pretendardvariable.css';

import axios from "axios";
import moment from 'moment';
import 'moment/locale/ko';

import { useSnackbar } from 'notistack';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      thousandSeparator=','
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const form_line = {
  horizontal : [
    {
      top:'92px'
    },
    {
      top:'137px'
    },
    {
      top:'182px'
    },
    {
      top:'227px'
    },
    {
      top:'272px'
    },
    {
      top:'317px'
    }
  ],
  vertical : [
    {
      top:'204px',
      left:'44px',
      transform: 'rotate(90deg)',
      width: '224px'
    },
    {
      top:'159px',
      left:'428px',
      transform: 'rotate(90deg)',
      width: '44px'
    },
    {
      top:'159px',
      left:'554px',
      transform: 'rotate(90deg)',
      width: '44px'
    },
    {
      top:'249px',
      left:'428px',
      transform: 'rotate(90deg)',
      width: '44px'
    },
    {
      top:'249px',
      left:'554px',
      transform: 'rotate(90deg)',
      width: '44px'
    }
  ],
  box : [
    {
      left: '30px',
      top: '92px'
    },
    {
      left: '30px',
      top: '137px'
    },
    {
      left: '30px',
      top: '182px'
    },
    {
      left: '30px',
      top: '227px'
    },
    {
      left: '30px',
      top: '272px'
    },
    {
      left: '450px',
      top: '137px'
    },
    {
      left: '450px',
      top: '227px'
    }
  ],
  variable:[
    {
      width: '680px',
      height: '19px',
      left: '180px',
      top: '106px',
      key : 'name',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '250px',
      height: '19px',
      left: '180px',
      top: '151px',
      key : 'brand',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '680px',
      height: '19px',
      left: '180px',
      top: '196px',
      key : 'from',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '250px',
      height: '19px',
      left: '180px',
      top: '242px',
      key : 'color',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '680px',
      height: '19px',
      left: '180px',
      top: '286px',
      key : 'size',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '270px',
      height: '19px',
      left: '597px',
      top: '151px',
      key : 'code',
      fontSize: '16px',
      lineHeight: '19px'
    },
    {
      width: '270px',
      height: '19px',
      left: '597px',
      top: '242px',
      key : 'material',
      fontSize: '16px',
      lineHeight: '19px'
    },
  ],
  text:[
    {
      width: '51px',
      height: '22px',
      left: '70px',
      top: '106px',
      value : '상품명',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '51px',
      height: '22px',
      left: '70px',
      top: '150px',
      value : '브랜드',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '51px',
      height: '22px',
      left: '70px',
      top: '196px',
      value : '제조국',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '32px',
      height: '22px',
      left: '79px',
      top: '241px',
      value : '컬러',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '51px',
      height: '22px',
      left: '70px',
      top: '286px',
      value : '사이즈',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '67px',
      height: '22px',
      left: '483px',
      top: '151px',
      value : '상품코드',
      fontSize: '18px',
      lineHeight: '22px'
    },
    {
      width: '32px',
      height: '22px',
      left: '498px',
      top: '241px',
      value : '소재',
      fontSize: '18px',
      lineHeight: '22px'
    }
  ]
}
function ImageGeneratorAddPage(props){

  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name : "",
    brand : "",
    code : "",
    material : "",
    color : "",
    from : "",
    size : ""
  });

  const [staticImg, setStaticImg] = useState([])
  const [staticOpen, setStaticOpen] = useState(false);
  const [result, setResult] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleValueInput = (key) => (event) => {
    setProduct((prev) => ({ ...prev, [key] : event.target.value}));
  }

  const handleStaticClose = (event) => {
    setStaticOpen(false);
  }

  const handleRegister = (event) => {
    setResult("");
    setIsUploading(true);
    if(product.name.length === 0){
      setIsUploading(false);
      return;
    }
    /*if(memo.length === 0){
      setIsUploading(false);
      return;
    }*/


    htmlToImage.toJpeg(document.getElementById('preview_container'), { quality: 1 })
                .then(function (dataUrl) {
                  //console.log(dataUrl);
                  const file = new File([
                    new Blob([dataUrl])
                  ], "test.jpeg");
                  ToolController.generateHanstyle({
                    images : [{
                      name : "test.jpeg",
                      file : file
                    }],
                    statics : ["https://img7.heartit.kr/heartit/image/upload/v1685090321/qnpnrcveykopsqehp9z6.jpg"],
                  }, generateHTMLImageSuccess, generateHTMLImageError);

                });

    setIsUploading(false);

  }

  const generateHTMLImageSuccess = (response) => {
    const data = response.data
    if (data.code === 200){
      setResult(data.html);
    }
  }

  const generateHTMLImageError = (error) => {
    handleSnackBar("에러가 발생했습니다.", "error");
  }

  const handlePreviewDownload = (event) => {
    htmlToImage.toJpeg(document.getElementById('preview_container'), { quality: 1 })
                .then(function (dataUrl) {
                  var link = document.createElement('a');
                  link.download = `preview_product_${product.name}.jpeg`;
                  link.href = dataUrl;
                  link.click();
                });
  };

  const handleCancel = (event) => {
    navigate(-1);
  }

  const handleIntialize = (event) => {

  }

  const uploadBrandSuccess = (response) => {
    console.log(response);
    if(response.data.code === 200){
      handleSnackBar("성공적으로 브랜드가 추가되었습니다", "success");
      navigate("/setting/brands");
    }
    setIsUploading(false);
  }

  const uploadBrandError = (error) => {
    console.log(error);
    setIsUploading(false);
  }

  return (
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', textAlign:'left', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
    <Grid container spacing={2}>
      <Grid item xs={8} md={6}>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          <Badge color="error" variant="dot">
            상품명
          </Badge>
        </Typography>
        <TextField
          id="do-product-name"
          hiddenLabel
          value={product.name}
          onChange={handleValueInput("name")}
          placeholder="상품명"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
          size="small"
          variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              브랜드 명
            </Badge>
          </Typography>
          <TextField
            id="do-brand-name"
            hiddenLabel
            value={product.brand}
            onChange={handleValueInput("brand")}
            placeholder="브랜드 명"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              상품 코드
            </Badge>
          </Typography>
          <TextField
            id="do-product-code"
            hiddenLabel
            value={product.code}
            onChange={handleValueInput("code")}
            placeholder="상품 코드"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              제조자
            </Badge>
          </Typography>
          <TextField
            id="do-product-from"
            hiddenLabel
            value={product.from}
            onChange={handleValueInput("from")}
            placeholder="제조자"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              컬러
            </Badge>
          </Typography>
          <TextField
            id="do-product-color"
            hiddenLabel
            value={product.color}
            onChange={handleValueInput("color")}
            placeholder="색상"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              소재
            </Badge>
          </Typography>
          <TextField
            id="do-product-material"
            hiddenLabel
            value={product.material}
            onChange={handleValueInput("material")}
            placeholder="소재"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
          <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
            <Badge color="error" variant="dot">
              사이즈
            </Badge>
          </Typography>
          <TextField
            id="do-product-size"
            hiddenLabel
            value={product.size}
            onChange={handleValueInput("size")}
            placeholder="사이즈"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            size="small"
            variant="outlined"/>
        {/*<Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          로고 이미지
        </Typography>
        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <ImageUploader
            value={images}
            onChange={handleImageChange}
            sx={{width:'100%', maxWidth:'400px', mb:'8px'}}/>
            {previews.length > 0 && (
              <>
                <Typography variant="h6" component="p" sx={{fontSize:'14px', mb:'4px', color:'#222222', fontWeight:500}}>
                  업로드 이미지 수 : {previews.length}
                </Typography>
                <ImageList sx={{ width: '100%', maxWidth:'400px', height: 220, mt:'0px' }} cols={2} rowHeight={200}>
                  {previews.map((item) => (
                    <ImageListItem key={item.key}>
                      <img
                        src={item.url}
                        srcSet={item.url}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </>
            )}
        </Box>*/}
        {/*<Box sx={{position:'relative', width:'100%', maxWidth:'400px', mb:'18px'}}>
          <Typography
            variant="h6"
            component="p"
            sx={{fontSize:'15px', mb:'24px', color:'#222222', fontWeight:700, mt:'24px', paddingTop:'8px'}}>
            <Badge color="error" variant="dot">
              동일 브랜드 명
            </Badge>
          </Typography>
          <IconButton
            onClick={handleOpenBrandAliasDialog}
            disabled={brandAliasDialogOpen}
            sx={{position:'absolute', right:'8px', top:'0px'}}>
            <AddIcon />
          </IconButton>
        </Box>
        <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        {alias.length === 0 ? (
          <Typography variant="h6" component="p" sx={{fontSize:'13px', mb:'12px', color:'#444444', fontWeight:300}}>
            등록된 동일 브랜드 명이 없습니다
          </Typography>
        ):(
          alias.map((ali) => (
            <Box key={`alias-list-${ali}`} sx={{position:'relative', width:'100%'}}>
              <Typography variant="h6" component="p" sx={{fontSize:'13px', paddingTop:'4px',mb:'12px', color:'#444444', fontWeight:300}}>
                {ali}
              </Typography>
              <IconButton
                onClick={handleDeleteBrand(ali)}
                sx={{position:'absolute', right:'12px', top:'0px'}} aria-label="delete" size="small">
                <DeleteForeverTwoToneIcon fontSize="small" />
              </IconButton>
            </Box>
          ))
        )}
        </Box>
        <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
          추가 사항
        </Typography>
        <TextField
            id="standard-multiline-static"
            hiddenLabel
            disabled={isUploading}
            sx={{width:'100%', maxWidth:'400px', mb:'24px'}}
            placeholder="기타 기입할 사항을 입력해주세요"
            multiline
            value={memo}
            onChange={handleMemoInput}
            InputLabelProps={{
              shrink: true,
            }}
            rows={4}
            variant="outlined"
          />*/}
        </Grid>
        <Grid item xs={8} md={6}>
          <Box sx={{position:'relative', width:'100%', mb:'18px'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
              미리보기
            </Typography>
            <IconButton
              onClick={handlePreviewDownload}
              size="small"
              sx={{
                position:'absolute',
                right:'0px',
                top:'0px'
              }}>
              <SaveAltTwoToneIcon size="small"/>
            </IconButton>
          </Box>
          <Box sx={{position:'relative', width:'100%', overflow:'scroll', height:'450px', border:'1px solid #dddddd',  mb:'18px'}}>
           <Box id="preview_container" sx={{position:'relative', width:'900px', height:'355px', backgroundColor:'#ffffff'}}>
            <Typography variant="h6" component="p" sx={{
              position: 'absolute',
              width: '250px',
              height: '36px',
              left: '30px',
              top: '30px',
              fontFamily: 'Pretendard Variable',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '30px',
              lineHeight: '36px',
              /* identical to box height */

              letterSpacing: '2px',
              color: '#000000'
            }}>
              PRODUCT INFO
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                width: '840px',
                height: '226px',
                left: '30px',
                top: '91px'
              }}>
            </Box>
            {form_line.horizontal.map((sl, i) => (
              <Box
                key={`_horizontal_line_${i}`}
                sx={{
                  position: 'absolute',
                  width: '840px',
                  height: '0px',
                  left: sl.left ? sl.left : '30px',
                  top: sl.top ? sl.top : '0px',
                  border: '1px solid #C7C7C7'
                }}>
              </Box>
            ))}
            {form_line.vertical.map((sl, i) => (
              <Box
                key={`_vertical_line_${i}`}
                sx={{
                  position: 'absolute',
                  width: sl.width ? sl.width : '0px',
                  height: sl.height ? sl.height : '0px',
                  left: sl.left ? sl.left : '30px',
                  top: sl.top ? sl.top : '0px',
                  transform : sl.transform ? sl.transform : 'none',
                  border: '1px solid #C7C7C7'
                }}>
              </Box>
            ))}
            {form_line.box.map((sl, i) => (
              <Box
                key={`_box_${i}`}
                sx={{
                  position: 'absolute',
                  width: '127px',
                  height: '45px',
                  left: sl.left ? sl.left : '30px',
                  top: sl.top ? sl.top : '0px',
                  background: 'rgba(0, 0, 0, 0.05)'
                }}>
              </Box>
            ))}
            {form_line.text.map((sl, i) => (
              <Typography
                key={`_text_${i}`}
                sx={{
                  position: 'absolute',
                  width: sl.width,
                  height: sl.height,
                  left: sl.left,
                  top: sl.top,
                  fontFamily: 'Pretendard Variable',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: sl.fontSize,
                  lineHeight: sl.lineHeight,
                  textAlign:'center',
                  color: '#000000'
                }}>
                {sl.value}
              </Typography>
            ))}
            {form_line.variable.map((sl, i) => (
              <Typography
                key={`_variable_${i}`}
                sx={{
                  position: 'absolute',
                  width: sl.width,
                  height: sl.height,
                  left: sl.left,
                  top: sl.top,
                  fontFamily: 'Pretendard Variable',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: sl.fontSize,
                  lineHeight: sl.lineHeight,
                  textAlign:'left',
                  color: '#000000'
                }}>
                {product[sl.key]}
              </Typography>
            ))}
            </Box>
          </Box>

          <Box sx={{position:'relative', width:'100%', mb:'18px'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', mb:'12px', color:'#222222', fontWeight:700}}>
              결과 텍스트
            </Typography>
          </Box>

          <Box sx={{position:'relative', width:'100%', overflow:'scroll'}}>
            <Typography variant="h6" component="p" sx={{fontSize:'15px', whiteSpace: 'pre-wrap', mb:'12px', color:'#222222', fontWeight:300}}>
              {result}
            </Typography>
          </Box>

        </Grid>
      </Grid>
      <Box sx={{width:'100%', maxWidth:'400px', mb:'32px'}}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{width:'100%'}}
          spacing={2}>
          <Button sx={{mr:'auto'}} onClick={handleCancel} size="medium" variant="outlined">등록 취소</Button>
          <Button size="medium" onClick={handleIntialize} variant="outlined">초기화하기</Button>
          <Button disableElevation onClick={handleRegister} disabled={isUploading} size="medium" variant="contained">생성하기</Button>
        </Stack>
      </Box>
      <ImageStaticUploadDialog
        open={staticOpen}
        onClose={handleStaticClose}
        />
    </Box>
  );
}

export default ImageGeneratorAddPage;
