import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';

import DateInput from '../../components/inputs/DateInput';
import DOStatisticsTable from '../../components/tables/DOStatisticsTable';
import DOParameterInput from '../../components/inputs/DOParameterInput';

import MatchStatisticsChart from '../../components/charts/MatchStatisticsChart';

// controller
import DOStatisticsController from '../../apis/dailyorder/DOStatisticsController';

import moment from 'moment';
import 'moment/locale/ko';

function DOFileStatisticsPage(props){

  const {
    __statistics,
    __statisticsChange
  } = props;

  const [startDate, setStartDate] = useState(moment(__statistics.start_date));
  const [endDate, setEndDate] = useState(moment(__statistics.end_date));
  const [isWorking, setIsWorking] = React.useState(false);
  const [parameter, setParameter] = React.useState([__statistics.parameter, 100]);

  const [totalValue, setTotalValue] = React.useState({
    total : 0,
    over10 : 0,
    must : 0,
    ok: 0,
    test : 0
  });

  const [statisticsList, setStatisticsList] = React.useState([]);

  const handleStartDateInputChange = (value) => {
    setStartDate(value);
  }

  const handleEndDateInputChange = (value) => {
    setEndDate(value);
  }

  const handleParameterChange = (event, newValue) => {
    setParameter(newValue);
  };

  const handleSearchClick = (event) => {
    setTotalValue({
      total : 0,
      over10 : 0,
      must : 0,
      ok: 0,
      test : 0
    });
    DOStatisticsController.getKreamStatisticsFile({
      parameter : parameter[0],
      start_date : startDate.format('YYYY-MM-DD'),
      end_date : endDate.format('YYYY-MM-DD')
    }, getStatisticsDataSuccess, getStatisticsDataError);
  }

  const getStatisticsDataSuccess = (response) => {
      if(response.data.code === 200){
        const data = response.data.result;
        console.log(data);
      }
  }

  const getStatisticsDataError = (error) => {
    console.log(error);
  }

  useEffect(() => {

    DOStatisticsController.getKreamStatisticsFile({
      parameter : parameter[0],
      start_date : startDate.format('YYYY-MM-DD'),
      end_date : endDate.format('YYYY-MM-DD')
    }, getStatisticsDataSuccess, getStatisticsDataError);
  }, []);

  useEffect(() => {
    __statisticsChange({
      start_date : startDate,
      end_date : endDate,
      parameter : parameter[0]
    });
  }, [startDate, endDate, parameter]);

  return (
    <Box sx={{ boxSizing:'border-box', width:'100%', height:'100%',  backgroundColor:"#ffffff"}}>
      <Box sx={{ position:'relative', left:{xs : '0px', md:'240px'}, boxSizing:'border-box', width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%', p: 3, boxSizing:'border-box'}}>

        <Box sx={{width:'100%', textAlign:'left', mb:'24px'}}>
          <Stack
            direction='row'
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{mb:"8px"}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                  바잉 총합
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.total.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                +10% 총합
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.over10.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                Must 총합
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.must.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                OK 총합
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.ok.toFixed(0)))}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"15px",
                  textAlign:"left",
                  width:'100%'}}>
                Test 총합
              </Typography>
              <Typography variant="p" component="p"
                sx={{
                  fontSize:"18px",
                  fontWeight:"900",
                  textAlign:"left",
                  width:'100%'}}>
                {(new Intl.NumberFormat('kr', { style: 'currency', currency: "KRW" }).format(totalValue.test.toFixed(0)))}
              </Typography>
            </Stack>
          </Stack>
          {/*<Typography variant="p" component="p" sx={{textAlign:'left', fontSize:'12px', color:'#777777'}}>
          최종 업데이트 : 2분전
          </Typography>
          */}
        </Box>
        {/*<MatchStatisticsChart
          data={statisticsList}/>*/}
        <DOStatisticsTable
          data={statisticsList}/>
      </Box>
      <Box sx={{position:'fixed', width:'240px', left:'0px', height:'100%', backgroundColor:'#ffffff', top:'0px', display:{xs:'none', md:'block'}}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', padding:'24px', textAlign:'left'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 시작일
          </Typography>
          <DateInput
            onChange={handleStartDateInputChange}
            value={startDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            검색 종료일
          </Typography>
          <DateInput
            onChange={handleEndDateInputChange}
            value={endDate}/>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              fontWeight:"700",
              mt:"18px",
              mb:"18px",
              textAlign:"left",
              width:'100%'}}>
            기준 파라미터
          </Typography>
          <Stack
            sx={{mt:'12px', width:'100%'}}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            <DOParameterInput
              value={parameter}
              onChange={handleParameterChange}/>
          </Stack>
          <Stack
            sx={{mt:"12px", width:'100%'}}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}>
            <Button disabled={isWorking} onClick={handleSearchClick} variant="outlined">
              검색하기
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default DOFileStatisticsPage;
