import axios from 'axios';
import getToken from '../../utils/getToken'

export default class DOStatisticsController{
  static getStatistics(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/daily/statistics",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getKreamStatistics(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/daily/kream_statistics",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
  static getKreamStatisticsFile(data, _success, _error){
    const token = getToken();
    axios({
      method: "POST",
      url:"/api/statistics/kream",
      data:data,
      headers: {
        Authorization: 'Bearer ' + token
      },
    })
    .then((response) => {
      console.log(response);
      _success(response);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)

        if(error.response.status === 401){
            error.handleGlobally && error.handleGlobally();
        }
      }

      _error(error);
    });
  }
}
