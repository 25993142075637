import React from 'react';
import { Routes, Route, Switch, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Redirect from './Redirect';
import Version from './Version';
export default function PreviousRoute(props){
  return (
    <Routes>
      <Route path="*" element={<Redirect url="/v1" />} />
      <Route path="/version/:__version" element={<Version {...props} />} />
    </Routes>
  );
}
