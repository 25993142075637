import React, {useState, useEffect} from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// icons
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

// heartit crawler
import CrawlerView from '../../components/crawlers/CrawlerView';
import CrawlerListView from '../../components/crawlers/CrawlerListView';
import MemoView from '../../components/crawlers/MemoView';

// heartit controllers
import CrawlerController from '../../apis/crawlers/CrawlerController';
import MemoController from '../../apis/crawlers/MemoController';

// heartit dialogs
import CrawlerAddDialog from '../../components/dialogs/CrawlerAddDialog';
import CrawlerChangeDialog from '../../components/dialogs/CrawlerChangeDialog';

import { CRAWLER_VIEW_TYPE_LIST, CRAWLER_VIEW_TYPE_CARD} from '../../assets/Constants';
//alert
import { useSnackbar } from 'notistack';
// for test

function CrawlerListPage(props){

  const { enqueueSnackbar } = useSnackbar();

  const [keyword, setKeyword] = useState("");
  const [crawlers, setCrawlers] = useState([]);

  const [myCheck, setMyCheck] = useState(true);

  const [viewType, setViewType] = useState(CRAWLER_VIEW_TYPE_CARD);

  const [memoOpen, setMemoOpen] = useState(false);
  const [memoText, setMemoText] = useState("");
  const [open, setOpen] = useState({
    add : false,
    change : false,
    remove : false
  })

  const [memoList, setMemoList] = useState([]);

  const [selectedCrawler, setSelectedCrawler] = useState({
    id : -1,
    name : '-'
  });

  const handleSnackBar = (str, variant) => {
    enqueueSnackbar(str, { variant });
  };

  const handleDialogClose = (type) => (event) => {
    setOpen({ ...open, [type] : false})
  }
  const handleDialogOpen = (type) => {
    setOpen({ ...open, [type] : true})
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleAddDialog = () => {
    handleDialogOpen("add");
  };

  const handleMemoOpen = (id) => (event) => {
    setMemoOpen(true);
    setSelectedCrawler(crawlers.find(element => (element.id === id) ));
    MemoController.getMemo(id, getMemoSuccess, errorGetMemo);
  };

  const handleChangeOpen = (id) => (event) => {
    handleDialogOpen("change");
    setSelectedCrawler(crawlers.find(element => (element.id === id) ));
  };

  const getCrawlersSuccess = (response) => {
    if(response.data.code === 200){
      setCrawlers(response.data.crawlers);
    }
  };

  const getCrawlersFailed = (error) => {

  };

  const handleMemoTextChange = (event) => {
    setMemoText(event.target.value);
  };

  const getMemoSuccess = (response) => {
    if(response.data.code === 200){
      setMemoList(response.data.memo);
    }
  }

  const errorGetMemo = (error) => {

  }

  const addMemoSuccess = (response) => {
    if(response.data.code === 200){
      handleSnackBar(`메모가 등록되었습니다`,'success');
      setMemoText("");
    }else{
      handleSnackBar(`메모가 어떤 문제에 의해서 등록되지 않았습니다`,'error');
    }
    MemoController.getMemo(selectedCrawler.id, getMemoSuccess, errorGetMemo);
  }

  const handleAddCrawler = (data) => {
    console.log(data);
  }

  const handleMyCheckBox = (event) => {
    setMyCheck(event.target.checked);
  }

  const handleViewTypeChange = (event, newViewType) => {
    setViewType(newViewType);
  };

  const errorMemoCreate = (error) => {

  }

  const handleMemoUpload = () => {
    // validation check
    if(memoText.length === 0){
      return;
    }
    if(selectedCrawler.id === -1){
      return;
    }
    MemoController.createMemo({
      text : memoText,
      id : selectedCrawler.id
    }, addMemoSuccess, errorMemoCreate);
  };

  useEffect(() => {
    CrawlerController.getCrawlers({},getCrawlersSuccess,getCrawlersFailed);
  }, [open]);
  return (
    <>
    <Box sx={{width:{md : 'calc(100% - 280px)', sm : '100%'}, overflow:'scroll', height:'100%',  backgroundColor:"#ffffff", p: 3, boxSizing:'border-box'}}>
      <Typography variant="h6" element="p" sx={{textAlign:"left", mb:'24px'}}>
        크롤러 리스트
      </Typography>
      <Stack
        direction='row'
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{mb:"24px"}}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              textAlign:"left",
              width:'100%'}}>
            등록된 크롤러
          </Typography>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"18px",
              fontWeight:"900",
              textAlign:"left",
              width:'100%'}}>
            {crawlers.length}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{border:'1px solid #dddddd', p:2, borderRadius:'4px'}}>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"15px",
              textAlign:"left",
              width:'100%'}}>
            활성화된 크롤러
          </Typography>
          <Typography variant="p" component="p"
            sx={{
              fontSize:"18px",
              fontWeight:"900",
              textAlign:"left",
              width:'100%'}}>
            {crawlers.filter((el) => (el.enabled)).length}
          </Typography>
        </Stack>
      </Stack>
      <Grid
        container
        direction='row'
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{width:'100%', mb:'24px', maxWidth:'400px'}}>
        <Grid
          item>
          <TextField
            id="outlined-search"
            value={keyword}
            onChange={handleKeywordChange}
            label="키워드"
            size="small"
            type="search"
            InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchTwoToneIcon size="small" />
              </InputAdornment>
            ),
            }} />
        </Grid>
        <Grid
          item>
          <Button
            variant="outlined"
            onClick={handleAddDialog}
            size="small"
            sx={{height:'40px'}}>크롤러 추가하기</Button>
        </Grid>
        <Grid
          item>
           <FormControlLabel control={
             <Checkbox
               checked={myCheck}
               onChange={handleMyCheckBox}
               defaultChecked />
           } label="내가 등록한 크롤러만 보기" />
        </Grid>
        <Grid
          item>
          <ToggleButtonGroup
             color="primary"
             value={viewType}
             exclusive
             size="small"
             onChange={handleViewTypeChange}
             aria-label="View-type">
             <ToggleButton value={CRAWLER_VIEW_TYPE_LIST}>리스트 형</ToggleButton>
             <ToggleButton value={CRAWLER_VIEW_TYPE_CARD}>카드 형</ToggleButton>
           </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{width:'100%', boxSizing:'border-box'}}>
        {crawlers.map((item) => (
          (viewType === CRAWLER_VIEW_TYPE_CARD) ? (
          <Grid
            item
            sx={{boxSizing:'border-box'}}
            key={`crawler-list-${item.id}`} xs={12} md={12} lg={6} xl={6}>
            <CrawlerView
              onMemo={handleMemoOpen(item.id)}
              onChange={handleChangeOpen(item.id)}
              name={item.name}
              lastTime={item.next_run_time}
              cron={item.cron}
              active={item.enabled}
              host={item.host}
              url={item.url}
              memo={item.memo}/>
          </Grid>) :
          <CrawlerListView
            key={`crawler-list-${item.id}`}
            onMemo={handleMemoOpen(item.id)}
            onChange={handleChangeOpen(item.id)}
            name={item.name}
            lastTime={item.next_run_time}
            cron={item.cron}
            active={item.enabled}
            host={item.host}
            url={item.url}
            memo={item.memo}/>
        ))}
      </Grid>
    </Box>
    <Paper
      elevation={2}
      sx={{
        position:'fixed',
        top:'0px',
        right:'0px',
        height:'100%',
        width:'270px',
        boxSizing:'border-box',
        transition:'transform .3s',
        transform: (memoOpen ? "translateX(0px)" : "translateX(270px)")
      }}>
      <Box sx={{position:'relative', width:'100%',boxSizing:'border-box'}}>
        <Toolbar />
        <Box sx={{position:'relative', width:'100%', p:2}}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            >
            <IconButton onClick={() => setMemoOpen(false)}>
              <CloseTwoToneIcon />
            </IconButton>
            <Typography variant="p" component="p"
              sx={{
                fontSize:"18px",
                textAlign:"left",
                width:'100%'}}>
              메모
            </Typography>
          </Stack>
          <Box sx={{
            position:'relative',
            width:'100%',
            overflow:'scroll',
            height:'calc(100vh - 330px)'}}>
            <MemoView
              memo={memoList}
              crawler={selectedCrawler}/>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position:'absolute',
          bottom:'0px',
          left:'0px',
          right:'0px',
          backgroundColor:'#ffffff',
          width:'100%',
          borderTop:'1px solid #dddddd',
          boxSizing:'border-box',
          textAlign:'left',
          p:3
        }}>
        <TextField
           id="crawler-list-outlined-memo-static"
           label="메모하기"
           multiline
           rows={4}
           sx={{width:'100%', mb:'8px'}}
           value={memoText}
           onChange={handleMemoTextChange}/>
        <Button
          variant="outlined"
          size="small"
          onClick={handleMemoUpload}
          sx={{width:'100%'}}>메모하기</Button>
      </Box>
    </Paper>
    <CrawlerAddDialog
      open={open.add}
      onAdd={handleAddCrawler}
      onClose={handleDialogClose("add")}/>
    <CrawlerChangeDialog
      open={open.change}
      onClose={handleDialogClose("change")}/>
    </>
  );
}

export default CrawlerListPage;
