import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import ArrowForwardIosTwoToneIcon from '@mui/icons-material/ArrowForwardIosTwoTone';

import {green, red, orange} from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

export default function DOFileElementRawView(props){

  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(props.file.handled);

  const handleShowFile = (event) => {
    console.log(props.file.key);
    navigate(`/do/file/${props.file.key}`);
  }

  const statusView = (status) => {
    switch(status){
      case 0:
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}>
            <Typography variant="p" component="span" sx={{fontSize:'11px', mt:'0px !important', color:'#555555'}}>
              분석 전
            </Typography>
          </Stack>
        );
      case 1:
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={0}>
            <Typography variant="p" component="span" sx={{fontSize:'11px', mt:'0px !important', color:green[700]}}>
              분석 완료
            </Typography>
          </Stack>
        );
      case 2:
        return (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={1}>
            <Typography variant="p" component="span" sx={{fontSize:'11px', mt:'0px !important', color:red[700]}}>
              분석 중
            </Typography>
            <CircularProgress size="sm" />
          </Stack>
        );
      default : return (<></>);
    }
  }

  return (
    <Paper
      elevation={0}
      sx={{ ...props.sx,
        boxSizing:'border-box',
        width:'100%',
        padding:'8px 8px 8px 16px',
        position:'relative',
        borderRadius:'6px',
        backgroundColor:(isValid ? '#ffffff' : '#eeeeee'),
        border:'1px solid #dddddd'
      }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{width:'100%'}}
        spacing={1}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          sx={{mr:'auto'}}
          spacing={1}>
          <Typography variant="p" component="div" sx={{fontSize:'11px', mt:'0px !important', color:'#555555', mb:'6px'}}>
            총 상품 수 : {props.file.number}
          </Typography>
          <Typography variant="p" component="p" sx={{fontSize:'12px', mt:'0px !important', color:'#444444'}}>
            {props.file.name}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}>
          {statusView(props.file.analyzed)}
          <Button variant="outlined" size="small" onClick={handleShowFile}>자세히 보기</Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
