import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Skeleton from '@mui/material/Skeleton';

export default function CardLoadingView(props) {

  return (
    <Card
      elevation={0}
      sx={{ width:"100%", border:"solid 1px #eee" }}>
      <CardHeader
        title={
          <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
          <Skeleton animation="wave" variant="rounded" width={48} height={24} sx={{marginBottom:'8px'}} />
          <Typography variant="p" component="p" sx={{
            textAlign:"left",
            display: '-webkit-box',
            overflow: 'hidden',
            fontSize: '14px',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            }}>
            <Skeleton
              animation="wave"
              height={10}
              width="100%"
              sx={{ marginBottom: '6px' }}
            />
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
            />
          </Typography>
          </Box>
        }
        subheader={
          <>
          <Skeleton
            animation="wave"
            height={10}
            width="100%"
            sx={{ marginBottom: '6px' }}
          />
          <Skeleton
            animation="wave"
            height={10}
            width="40%"
          />
          </>
        }
      />
      <Box sx={{position:'relative', width:'100%', height:'194px'}}>
        <Skeleton sx={{ height: 194 }} animation="wave" variant="rectangular" />
      </Box>
      <CardContent>
        <Skeleton
          animation="wave"
          height={10}
          width="60%"
          sx={{ marginBottom: '2px' }}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="60%"
          sx={{ marginBottom: '2px' }}
        />
      </CardContent>
    </Card>
  );
}
