import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

export default function DOPriceInput(props) {

  return (
    <FormControl fullWidth variant="standard">
      <Input
        id="standard-adornment-amount"
        value={props.value}
        onChange={props.handleChange}
        type="number"
        startAdornment={<InputAdornment position="start">₩</InputAdornment>}
      />
    </FormControl>
  );
}
