import React, {useState, useEffect} from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import DOFileElementView from './DOFileElementView';

import { pink, blue, red } from '@mui/material/colors';

import moment from 'moment';
import 'moment/locale/ko';

export default function DOMailView(props){

  const lastTimestamp = (time) => {
    return moment(time).fromNow()
  }

  return (
    <Card
      elevation={0}
      sx={{ position:'relative', width:"100%" }}>
      <Box sx={{textAlign:'left', width:'100%', display:'block'}}>
        <Tooltip title={props.info.vendor.name}>
          <Chip label={props.info.vendor.name} size="small" sx={{mb:'8px', fontSize:'12px', padding:'0px 8px'}} />
        </Tooltip>
      </Box>
      <Typography variant="p" component="p" sx={{
        textAlign:"left",
        display: '-webkit-box',
        overflow: 'hidden',
        fontSize: '14px',
        mb:'4px',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        }}>
        {props.info.name}
      </Typography>
      <Typography variant="p" component="p" sx={{
        textAlign:"left",
        display: '-webkit-box',
        overflow: 'hidden',
        fontSize: '12px',
        mb:'4px',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        }}>
        {lastTimestamp(props.info.mail_date)}
      </Typography>

      <Typography variant="p" component="p" sx={{mb:'8px', textAlign:"left", fontSize:"14px", color:"#222", fontWeight:700}}>
      {
        //(new Intl.NumberFormat(priceObject['country'], { style: 'currency', currency: priceObject['currency'] }).format(priceObject.value))
      }
      </Typography>
      {props.info.file.length > 0 && (
        props.info.file.map(file => (
          <DOFileElementView
            parameter={props.parameter}
            onAddTotal={props.onAddTotal}
            key={`product-file-do-${file.id}`}
            sx={{mb:'8px'}}
            file={file}/>
        ))
      )}
    </Card>
  );
}
